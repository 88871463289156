import { atom, selector } from "recoil";
import apis from "./api/getApi";

const toastOn = atom({
  key: "toast",
  default: false,
});

const toastMessage = atom({
  key: "toastMessage",
  default: "",
});

const toastUpdate = atom({
  key: "toastUpdate",
  default: false,
});

const couponData = atom({
  key: "couponData",
  default: {
    uuid: "",
    title: "",
    code: "0",
    amounts: 0,
    validityStartDate: "",
    validityEndDate: "",
    usageCount: 0,
    comments: "",
    createdAdmin: "",
    updatedAdmin: "",
    usageHosts: [
      {
        hostEmail: "",
      },
    ],
  },
});

const fetchCouponListData = selector({
  key: "couponListItemData",
  get: async ({ get }) => {
    const { data } = await apis.getCouponList();
    return data.coupons;
  },
});

const landingCalenderYear = atom({
  key: "landingCalenderYear",
  default: 0,
});

const landingCalenderMonth = atom({
  key: "landingCalenderMonth",
  default: 0,
});

const confirmKeyList = atom({
  key: "confirmKeyList",
  default: <
    Array<{
      id: number;
      key: string;
    }>
  >[],
});

const category = atom({
  key: "category",
  default: "",
});

export {
  toastOn,
  toastMessage,
  toastUpdate,
  couponData,
  fetchCouponListData,
  landingCalenderYear,
  landingCalenderMonth,
  confirmKeyList,
  category,
};

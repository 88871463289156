import { UseQueryResult, useQuery } from "react-query";
import apis from "../../api/getApi";

export interface PermissionListApiResponse {
  isSuccess: true;
  approvedList: [
    {
      hostEmail: string;
      hostName: string;
      hostGroupName: string;
      hostPhoneNumber: string;
      hostUuid: string;
      hostCreatedAt: string;
    },
  ];
}

interface DataProp {
  nowPage: number;
  pageCount: number;
}

const useGetPermissionList = ({
  nowPage,
  pageCount,
}: DataProp): UseQueryResult<PermissionListApiResponse, unknown> => {
  const fetcher = async () => {
    const response = await apis.getPermissionList({ nowPage, pageCount });
    return response.data;
  };
  return useQuery(["permissionList"], fetcher);
};

export default useGetPermissionList;

import { UseQueryResult, useQuery } from "react-query";
import apis from "../../api/getApi";

interface DataProp {
  uuid: string | undefined;
}

interface ApiResponse {
  host: {
    uuid: string;
    name: string;
    email: string;
    language: string;
    signUpAt: string;

    indPoint: number | null;
    indRefundCash: number | null;
    indRefundPoint: number | null;

    type: "enterprise" | "individual";
    plan: "individualPlan" | "memberPlan" | "managerPlan";
    enterprise: {
      name: string;
      phoneNumber: string;
      point: number;
      credit: number;
      refundCredit: number;
      refundPoint: number;
      refundCash: number;
    } | null; // individual 회원일 경우 null
  };

  expectedTestCount: number;
  inProgressTestCount: number;
  completedTestCount: number;
}

const useGetUserSpecific = ({
  uuid,
}: DataProp): UseQueryResult<ApiResponse, unknown> => {
  const fetcher = async () => {
    const response = await apis.getUserSpecific({ uuid });
    return response.data;
  };
  return useQuery(["userList"], fetcher);
};

export default useGetUserSpecific;

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { styled } from "styled-components";
import CouponInput from "./couponInput";
import apis from "../../../../api/getApi";
import "../../marketingCss.css";

export interface CouponDataType {
  title: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  point: number;
}

const CouponMakeModal: React.FC<{
  onClickPluseIcon: () => void;
  onClickModalOff: () => void;
}> = ({ onClickPluseIcon, onClickModalOff }) => {
  const [createComment, setCreateComment] = useState<string>("");

  const [couponData, setCouponData] = useState<CouponDataType>({
    title: "",
    startDate: new Date().toISOString().substring(0, 10),
    startTime: "",
    endDate: new Date().toISOString().substring(0, 10),
    endTime: "",
    point: 0,
  });
  const [errorText, setErrorText] = useState<string>();

  const couponInputProps = (type: string, dataKey: keyof CouponDataType) => {
    return {
      type,
      dataKey,
      value: couponData,
      setValue: setCouponData,
    };
  };

  const onClickCouponCreate = async () => {
    let missingData = 0;
    for (const key in couponData) {
      if (couponData[key as keyof CouponDataType] === "") {
        missingData++;
      }
    }
    if (missingData > 0) {
      setErrorText("누락된 내용을 확인하세요.");
    } else {
      //생성 에이피아이 호출
      const newData: {
        title: string;
        validityStartDate: string;
        validityEndDate: string;
        amounts: Number;
        comments: string;
      } = {
        title: couponData.title,
        validityStartDate: `${couponData.startDate} ${couponData.startTime}`,
        validityEndDate: `${couponData.endDate} ${couponData.endTime}`,
        amounts: Number(couponData.point),
        comments: createComment,
      };
      try {
        const { data } = await apis.postCreateCoupon(newData);
        onClickModalOff();
      } catch (e: any) {
        setErrorText(e.data.errorMessage);
      }
    }
  };

  const InputTitle: React.FC<{ title: string }> = ({ title }) => {
    const titleArray = title.split("");
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 10px",
          alignItems: "center",
          marginRight: "10px",
        }}
      >
        {titleArray.map((item, idx) => (
          <span key={item + idx}>{item}</span>
        ))}
      </div>
    );
  };

  React.useEffect(() => {
    setErrorText("");
  }, [couponData]);

  return (
    <CreateModalSt>
      <ModalContentSt>
        <CloseButton onClick={onClickModalOff}>닫기</CloseButton>
        <InputContentArea>
          <InputTitle title="제목" />
          <CouponInput {...couponInputProps("text", "title")} />
        </InputContentArea>
        <InputContentArea>
          <InputTitle title="유효  기간" />
          <DateContainer>
            <CouponInput {...couponInputProps("date", "startDate")} />
            <CouponInput {...couponInputProps("time", "startTime")} />
            ~
            <CouponInput {...couponInputProps("date", "endDate")} />
            <CouponInput {...couponInputProps("time", "endTime")} />
          </DateContainer>
        </InputContentArea>
        <InputContentArea>
          <InputTitle title="지급  포인트" />
          <CouponInput {...couponInputProps("number", "point")} />
        </InputContentArea>
        <InputContentArea>
          <InputTitle title="비고" />
          <div className="textArea">
            <textarea
              style={{
                width: "100%",
                height: "100px",
                boxSizing: "border-box",
                resize: "none",
              }}
              value={createComment}
              onChange={(e) => setCreateComment(e.target.value)}
            />
          </div>
        </InputContentArea>

        <Button onClick={onClickCouponCreate}>쿠폰 생성</Button>
        {errorText ? <p className="errorText">{errorText}</p> : null}
      </ModalContentSt>
    </CreateModalSt>
  );
};

export const CreateModalSt = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000025;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContentSt = styled.div`
  padding: 50px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 1px 3px 5px 0px #1220425e;
  width: 900px;
  position: relative;
`;
const InputContentArea = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  padding-bottom: 20px;
`;

export const DateContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 20px 1fr 1fr;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Button = styled.div`
  height: 40px;
  padding: 0 20px;
  border: 1px solid #0c152b;
  border-radius: 3px;
  background-color: #15254e;
  color: #fff;
  box-shadow: 1px 1px 2px #4c50593b;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 50px auto 0; */
  &:hover {
    opacity: 0.6;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: -25px;
  right: 0;
  cursor: pointer;
`;

const InfoText = styled.div`
  text-align: center;
  padding: 20px 0 0;
  color: #c82323;
`;

export default CouponMakeModal;

import React from "react";
import ModalBack from "../atomic/atom/ModalBack";
import { styled } from "styled-components";
import TextLine from "../atomic/atom/TextLine";
import DivButton from "../atomic/atom/DivButton";
import PageHeader from "../atomic/organism/PageHeader";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import customAxios from "../api/handler";
import useGetConsiderUserDetail from "../hook/api/useGetConsiderUserDetail";
import useToast from "../hook/useToast";

const SignupConfirm = () => {
  const uid = useParams().uid;
  const navigate = useNavigate();
  const showToast = useToast();
  const outletData = useOutletContext();

  const approval = () => {
    customAxios
      .post(`/admin/proc/masters/pending-users/approve`, {
        adminUuid: uid,
      })
      .then(() => {
        showToast({
          message: "승인되었습니다.",
        });
        navigate(`/common/master`);
      })
      .catch(() => {
        showToast({
          message: "승인할 수 없습니다. 서버 담당자에게 연락해주세요.",
        });
      });
  };
  const { data } = useGetConsiderUserDetail({ uid });

  return (
    <>
      <ModalBack backgroudcolor="#7270706a" />
      <StConfirm>
        <div className="bodyForm">
          <div
            className="cancelButton"
            onClick={() => navigate("/common/master")}
          >
            닫기
          </div>
          <PageHeader title="회원가입 승인" search={false} />
          <div className="bodyBox">
            <div className="infoLine">
              <TextLine text="이름" />
              <TextLine text={data?.name} />
            </div>
            <div className="infoLine">
              <TextLine text="닉네임" />
              <TextLine text={data?.nickName} />
            </div>
            <div className="infoLine">
              <TextLine text="이메일" />
              <TextLine text={data?.email} />
            </div>
            <div className="infoLine">
              <TextLine text="전화번호" />
              <TextLine text={data?.phoneNumber} />
            </div>
            <div className="infoLine">
              <TextLine text="직무" />
              <TextLine text={data?.department} />
            </div>
            <div className="infoLine">
              <TextLine text="신청일자" />
              <TextLine text={data?.signUpDate} />
            </div>
          </div>
          <div className="buttonBox">
            <DivButton
              text="가입 거절"
              // margin="auto auto 0"
              border="1px solid black"
              borderradius={8}
              width={100}
              height={30}
              onclick={() => navigate(`/common/master `)}
            />
            <DivButton
              text="가입 승인"
              borderradius={8}
              backgroundcolor="#15254e"
              fontcolor="white"
              width={100}
              height={30}
              onclick={() => approval()}
            />
          </div>
        </div>
      </StConfirm>
    </>
  );
};

const StConfirm = styled.div`
  position: fixed;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 540px;
  height: 520px;
  background-color: white;
  .bodyForm {
    position: relative;
    width: 540px;
    height: 520px;
    display: flex;
    flex-direction: column;
    padding: 48px;
    box-sizing: border-box;
  }
  .cancelButton {
    position: absolute;
    right: 0;
    top: -20px;
    font-family: "medium";
    cursor: pointer;
  }
  .infoLine {
    display: flex;
  }
  .bodyBox {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
    .infoLine {
      display: flex;
      :nth-child(1) {
        width: 100px;
      }
    }
  }
  .buttonBox {
    display: grid;
    grid-template-columns: 100px 100px;
    gap: 20px;
    margin: auto auto 20px;
  }
`;

export default SignupConfirm;

import React, { useState } from "react";
import { styled } from "styled-components";

// img
import arrowUp from "../../img/arrowUp.svg";
import arrowDown from "../../img/arrowDown.svg";
import { useNavigate } from "react-router-dom";

interface SideHeaderStyle {
  text?: string;
  fontSize: number;
  clicked: boolean;
  padding: string;
  setOpen?: (value: boolean) => void;
  location?: string;
  locationpath?: string;
  open?: boolean;
  pathname?: string;
}

const SideBarHead: React.FC<SideHeaderStyle> = (props) => {
  const navigate = useNavigate();
  const pathname = window.location.pathname.split("/")[2];

  return (
    <StDevider
      fontSize={props.fontSize}
      padding={props.padding}
      clicked={props.clicked}
      location={props.location}
      locationpath={props.locationpath}
      pathname={pathname}
      onClick={() => {
        props.setOpen && props.setOpen(!props.open);
        props.location && navigate(`${props.location}`);
      }}
    >
      {props.text ?? props.text}
      {!props.location && <img alt="" src={props.open ? arrowUp : arrowDown} />}
    </StDevider>
  );
};

const StDevider = styled.div<SideHeaderStyle>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${(props) => props.fontSize}px;
  padding: ${(props) => props.padding};
  font-family: ${(props) => (props.clicked ? "medium" : "regular")};
  line-height: 20px;
  background-color: ${(props) =>
    props.pathname === props.locationpath ? "#031238" : ""};
  height: 57px;
  box-sizing: border-box;
  img {
    margin-bottom: 5px;
  }
  cursor: pointer;
`;

export default SideBarHead;

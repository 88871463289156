import { ReactNode, createContext, useContext, useState } from "react";

interface OutletData {
  data: any;
  setData: React.Dispatch<React.SetStateAction<any>>;
}

const OutletDataContext = createContext<OutletData | undefined>(undefined);

interface OutletDataProviderProps {
  children: ReactNode;
}

export const OutletDataProvider: React.FC<OutletDataProviderProps> = ({
  children,
}) => {
  const [data, setData] = useState(null);

  return (
    <OutletDataContext.Provider value={{ data, setData }}>
      {children}
    </OutletDataContext.Provider>
  );
};

export const useOutletData = () => useContext(OutletDataContext);

import React from "react";
import useGetRequestDetail from "../../hook/api/useGetRequestDetail";
import { useNavigate, useParams } from "react-router-dom";
import PageTemplate from "../../atomic/template/PageTemplate";
import DivTwoPart from "../../atomic/atom/DivTwoPart";
import TextLine from "../../atomic/atom/TextLine";
import PageHeader from "../../atomic/organism/PageHeader";
import OrangeButton from "../../atomic/atom/OrangeButton";

const ConsultRequestDetail = () => {
  const introId = useParams().introId;
  const nowPage = useParams().nowPage;
  const { data } = useGetRequestDetail({ introId });
  const navigate = useNavigate();
  return (
    <PageTemplate>
      <DivTwoPart
        display="grid"
        justify="center"
        width="800px"
        padding="60px"
        margin="0 auto"
        border="1px solid #d6d9dd"
        border_radius={24}
      >
        <DivTwoPart
          font_size={28}
          font_color="#2276dc"
          font_family="bold"
          padding="28px 0"
          margin="0 auto"
        >
          프록토매틱 솔루션 도입 상담 신청서
        </DivTwoPart>
        <DivTwoPart margin="0 auto">
          손흥민 김연아 레이 베서니 마크 레츠고
        </DivTwoPart>
        <DivTwoPart display="grid" gap={20} margin="40px 0 0 0">
          <DivTwoPart display="grid" grid_template_column="1fr 1fr" gap={24}>
            <DivTwoPart display="grid" gap={8}>
              <TextLine text="성함" fontSize={18} lineheight={30} />
              <DivTwoPart
                border="1px solid #d9d9d9"
                border_radius={8}
                padding="12px 16px"
                font_size={17}
                lineheight={30}
              >
                {data?.intro.introName}
              </DivTwoPart>
            </DivTwoPart>
            <DivTwoPart display="grid" gap={8}>
              <TextLine text="소속기관" fontSize={18} lineheight={30} />
              <DivTwoPart
                border="1px solid #d9d9d9"
                border_radius={8}
                padding="12px 16px"
                font_size={17}
                lineheight={30}
              >
                {data?.intro.introGroup}
              </DivTwoPart>
            </DivTwoPart>
          </DivTwoPart>
          <DivTwoPart display="grid" gap={8}>
            <TextLine text="이메일" fontSize={18} lineheight={30} />
            <DivTwoPart
              border="1px solid #d9d9d9"
              border_radius={8}
              padding="12px 16px"
              font_size={17}
              lineheight={30}
            >
              {data?.intro.introEmail}
            </DivTwoPart>
          </DivTwoPart>
          <DivTwoPart display="grid" gap={8}>
            <TextLine text="전화번호" fontSize={18} lineheight={30} />
            <DivTwoPart
              border="1px solid #d9d9d9"
              border_radius={8}
              padding="12px 16px"
              font_size={17}
              lineheight={30}
            >
              {data?.intro.introPhone}
            </DivTwoPart>
          </DivTwoPart>
          <DivTwoPart display="grid " grid_template_column="1fr 1fr" gap={24}>
            <DivTwoPart display="grid" gap={8}>
              <TextLine text="예상 응시자 수" fontSize={18} lineheight={30} />
              <DivTwoPart
                border="1px solid #d9d9d9"
                border_radius={8}
                padding="12px 16px"
                font_size={17}
                lineheight={30}
              >
                {data?.intro.introExpectTester}
              </DivTwoPart>
            </DivTwoPart>
            <DivTwoPart display="grid" gap={8}>
              <TextLine text="방문 경로" fontSize={18} lineheight={30} />
              <DivTwoPart
                border="1px solid #d9d9d9"
                border_radius={8}
                padding="12px 16px"
                font_size={17}
                lineheight={30}
              >
                {data?.intro.introVisitPath}
              </DivTwoPart>
            </DivTwoPart>
          </DivTwoPart>
          <DivTwoPart display="grid" gap={8}>
            <TextLine text="문의 내용" fontSize={18} lineheight={30} />
            <DivTwoPart
              border="1px solid #d9d9d9"
              border_radius={8}
              padding="12px 16px"
              font_size={17}
              lineheight={30}
              // height={300}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.intro?.introContext
                    ? data?.intro.introContext
                    : "",
                }}
              />
            </DivTwoPart>
          </DivTwoPart>
          <DivTwoPart display="grid" grid_template_column="150px 400px">
            <TextLine text="신청날짜" />
            <TextLine text={data?.intro.introAt} />
          </DivTwoPart>
          <DivTwoPart display="grid" grid_template_column="150px 400px">
            <TextLine text="뉴스레터" />
            <TextLine
              text={data?.intro.introMarketingAgree ? "신청함" : "신청안함"}
            />
          </DivTwoPart>
        </DivTwoPart>
        <OrangeButton
          text="목록으로"
          margin="60px auto 0"
          onclick={() => navigate(`/marketing/consultRequest/${nowPage}`)}
        />
      </DivTwoPart>
    </PageTemplate>
  );
};

export default ConsultRequestDetail;

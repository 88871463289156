export const getKeyArray = (obj: {
  [key: string]: string | boolean | number | null;
}): string[] => {
  const titleArray: string[] = [];

  for (const key in obj) {
    titleArray.push(key);
  }
  return titleArray;
};

import React from "react";
import TableItem from "../molecule/TableItem";
import dayjs from "dayjs";
import { styled } from "styled-components";
import TextLine from "../atom/TextLine";
import { useNavigate } from "react-router-dom";

interface TableProps {
  headerList?: string[];
  items: { [key: string]: any }[] | undefined;
  nowPage?: number;
  onClick?: () => void;
}

const UserTable: React.FC<TableProps> = (props) => {
  const navigate = useNavigate();
  return (
    <StTable>
      {props.headerList && (
        <TableItem item={props?.headerList} use="header" widthforitem={200} />
      )}
      {props.items?.length && props.items?.length > 0 ? (
        props.items?.map((value, index) => {
          return (
            <TableItem
              key={index}
              item={[
                value.name,
                value.email ? value.email : "-",
                value.plan,
                value.enterpriseName ? value.enterpriseName : "-",
                value.paymentsAmount,
                dayjs(value.signUpAt).format("YYYY MM-DD HH:mm:ss"),
              ]}
              use="item"
              ent="true"
              widthforitem={200}
              onClick={() => navigate(`/common/userList/user/${value.uuid}`)}
            />
          );
        })
      ) : (
        <div className="noList">
          <TextLine
            text="검색 결과가 없습니다... 그건 제 잔상입니다만...?"
            fontSize={25}
            fontcolor="#888888"
          />
        </div>
      )}
    </StTable>
  );
};

const StTable = styled.div`
  .noList {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
  }
`;

export default UserTable;

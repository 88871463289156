import React from "react";
import MypageTemplate from "../../atomic/template/MypageTemplate";

const MyPage: React.FC = () => {
  return (
    <div>
      <MypageTemplate />
    </div>
  );
};

export default MyPage;

import styled from "styled-components";
import replyArrow from "../../../img/replyArrow.png";
import { IQuestionComponent } from "../interface/i.question";
import { useState } from "react";
import { v4 } from "uuid";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";
import customAxios from "../../../api/handler";

const QuestionDetailComponents = ({
  props,
}: {
  props: {
    components: IQuestionComponent[];
    questionUuid: string;
    hostUuid: string;
    updateQuestionDetail: () => void;
  };
}) => {
  const questionComponentUuid = v4();
  const navigate = useNavigate();
  const [modalImage, setModalImage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (image: string) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalImage("");
    setIsModalOpen(false);
  };

  const deleteComponentHandler = async (
    questionComponentUuid: string,
    hostEmail: string
  ) => {
    await customAxios
      .delete(
        `/admin/proc/questions/delete?questionUuid=${props.questionUuid}&questionComponentUuid=${questionComponentUuid}&hostUuid=${props.hostUuid}&hostEmail=${hostEmail}`
      )
      .then(({ data }) => {
        props.updateQuestionDetail();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <StyledDetailComponent>
      <div className="utilSection">
        <img className="arrowImage" src={replyArrow} alt=""></img>
        <button
          className="postingAnswerButton"
          onClick={() => {
            navigate(`${questionComponentUuid}/${props.hostUuid}`);
          }}
        >
          <div className="answerButton">답변 작성</div>
        </button>
      </div>
      {
        <div className="childComponents">
          {props.components.map((component, idx) => {
            if (idx === 0) {
              return null;
            }

            const colorCategory =
              component.questionComponentType === "host"
                ? `0 2px 5px hsla(0, 0%, 0%, 0.2);`
                : `0 2px 5px rgb(129, 179, 255);`;            

            const imageRetunred =
              component.signedImageList.length > 0 ? (
                <div className="imageBox">
                  {component.signedImageList.map((image, idx) => {
                    return (
                      <img
                        className="image"
                        src={image.questionImageUrl}
                        key={idx + 1}
                        onClick={() => openModal(image.questionImageUrl)}
                      ></img>
                    );
                  })}
                </div>
              ) : (
                <div></div>
              );

            return (
              <div
                className="componentSection"
                key={component.questionComponentUuid}
              >
                <StyledComponentBox color={colorCategory}>
                  <div className="context">
                    {component.questionComponentContext}
                  </div>
                  <div className="imageSection">
                    {imageRetunred}
                    {isModalOpen && (
                      <div className="modal">
                        <div className="modal-content">
                          <span className="close" onClick={closeModal}>
                            &times;
                          </span>
                          <img
                            src={modalImage}
                            alt="확대된 이미지"
                            className="modal-image"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </StyledComponentBox>
                {component.questionComponentType === 'admin' ? (
                  <div className="buttonSection">
                    <button
                      className="updateButton"
                      onClick={() => {
                        navigate(
                          `${component.questionComponentUuid}/${props.hostUuid}/update`
                        );
                      }}
                    >
                      <div>수정</div>
                    </button>
                    <button
                      className="deleteButton"
                      onClick={async () => {
                        deleteComponentHandler(
                          component.questionComponentUuid,
                          component.questionComponentOwnerEmail
                        );
                      }}
                    >
                      <div>삭제</div>
                    </button>
                    <div className="author">
                      {component.authorNickname ? component.authorNickname : component.authorUpdateNickname}
                    </div>
                  </div>
                ) : <div></div>}
              </div>
            );
          })}
        </div>
      }
    </StyledDetailComponent>
  );
};

export default QuestionDetailComponents;

const StyledDetailComponent = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  width: 1300px;
  height: 900px;
  gap: 20px;
  font-family: "semibold";

  .utilSection {
    display: flex;
    flex-direction: column;
    width: 10%;
    .arrowImage {
      width: 100px;
    }

    .postingAnswerButton {
      height: 50px;
    }
  }

  .childComponents {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
    gap: 20px;

    .componentSection {
      display: flex;
      gap: 20px;
      height: 100%;

      .buttonSection {
        display: flex;
        flex-direction: column;
        width: 5%;
        gap: 20px;

        .updateButton,
        .deleteButton,
        .author {
          width: 110%;
          height: 15%;
          white-space: nowrap;
          text-align: center;
          border-radius: 8px;
          font-family: "semibold";
          box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 6px;
          cursor: pointer;
        }
        .updateButton {
          border: 1px solid var(--main-blue, #1a75ff);
          background: #1a75ff;

          color: white;
        }

        .deleteButton {
          background-color: rgb(248, 13, 53);
          border: white;

          color: white;
        }

        .author {
          background-color: white(248, 13, 53);
          border: #1a75ff solid 0.5px;

          color: #1a75ff;;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .postingAnswerButton {
    width: 100px;
    height: 100px;
    color: white;
    font-family: "semibold";
    border-radius: 10px;
    background-color: #1a75ff;
    border: transparent;
    white-space: nowrap;
    cursor: pointer;
  }
`;

const StyledComponentBox = styled.div<{ color: string }>`
  width: 85%;
  height: 200px;
  border-radius: 12px;
  box-shadow: ${(props) => props.color};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 10px;

  .context {
    overflow-y: scroll;
    max-width: 100%;
    height: 100px;
    padding: 10px;
  }

  .imageSection {
    width: 95%;
    height: 80px;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;

    .imageBox {
      display: flex;
      gap: 20px;
      margin: 0px 10px;
      width: 10%;
      height: 80px;

      .image {
        max-width: 100%; /* 이미지의 최대 너비를 부모 요소인 .image-box의 너비에 맞춥니다. */
        max-height: 100%; /* 이미지의 최대 높이를 부모 요소인 .image-box의 높이에 맞춥니다. */
        cursor: pointer;

        .modal {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .modal {
      display: block;
      position: fixed;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }

    .modal-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      padding: 20px;
      border-radius: 4px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      text-align: center;
    }

    .modal-image {
      max-width: 100%;
      max-height: 80vh; /* 이미지의 최대 높이 설정 */
    }

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 24px;
      cursor: pointer;
    }
  }
`;
const StyledReplyArrow = styled.img`
  width: 100px;
  height: 70px;
`;

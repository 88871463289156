import React, { useEffect, useState } from "react";
import ControlDate from "./ControlDate";
import DateBox from "./DateBox";
import styled from "styled-components";
import axios from "axios";
import { Holiday, ExamCount } from "../types/type";
import customAxios from "../../../api/handler";
import { landingCalenderMonth, landingCalenderYear } from "../../../atom";
import { useRecoilState } from "recoil";

const Container = styled.div`
  width: 80%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div`
  margin-top: 200px;
  border: 30px solid rgba(0, 0, 0, 0.1);
  border-top: 30px solid #0099ff;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Main = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [nowDate, setNowDate] = useState<Date>(new Date());
  const [clickedDate, setClickedDate] = useState<Date>();
  const [holiday, setHoliday] = useState<Holiday[]>([]);
  const [examCount, setExamCount] = useState<ExamCount[]>([]);

  const getHoliday = async () => {
    const bodyData = {
      url: `https://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService/getRestDeInfo?`,
      serviceKey:
        "Kra%2BTb8%2FK3HIB5oJzCLF62ICxpORKvEAxRueb90NI7WmcbFEYaeOMoK0%2BgSyKVcRB6xOXdTSd6PNKTIkbwnpvQ%3D%3D",
      solYear: nowDate.getFullYear(),
      solMonth: (nowDate.getMonth() + 1).toString().padStart(2, "0"),
      numOfRows: 20,
    };

    const response = await axios.get(
      `${bodyData.url}ServiceKey=${bodyData.serviceKey}&solYear=${bodyData.solYear}&solMonth=${bodyData.solMonth}`
    );
    const saveData = [].concat(response.data.response.body.items.item);
    setHoliday(saveData);
  };

  const [atomYear, setAtomYear] = useRecoilState(landingCalenderYear);
  const [atomMonth, setAtomMonth] = useRecoilState(landingCalenderMonth);

  useEffect(() => {
    setIsLoading(true); // Start loading
    setAtomYear(nowDate.getFullYear()),
      setAtomMonth(nowDate.getMonth() + 1),
      Promise.all([getHoliday()])
        .then(() => {
          setIsLoading(false); // Finished loading
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false); // Handle error and finish loading
        });
  }, [nowDate]);

  useEffect(() => {
    setIsLoading(true); // Start loading
    Promise.all([
      customAxios
        .get(`/admin/proc/landing/examcount/${atomYear}/${atomMonth}`)
        .then(({ data }) => {
          setExamCount(data.response);
          setIsLoading(false); // Finished loading
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false); // Finished loading
        }),
    ]);
  }, [holiday]);

  return (
    <Container>
      <ControlDate nowDate={nowDate} setNowDate={setNowDate} />
      {isLoading ? (
        <Spinner />
      ) : (
        <DateBox
          nowDate={nowDate}
          setNowDate={setNowDate}
          clickedDate={clickedDate}
          setClickedDate={setClickedDate}
          holiday={holiday}
          examCount={examCount}
        />
      )}
    </Container>
  );
};

export default Main;

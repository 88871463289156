import styled from "styled-components";
import { IQuestionDetail } from "../interface/i.question";

const QuestionDetailHeader = ({
  questionDetail,
}: {
  questionDetail: IQuestionDetail;
}) => {
  const {
    questionCategory,
    questionCreatedAt,
    questionTitle,
    isAnswered,
  } = questionDetail;

  const answer = isAnswered ? "답변 완료" : "답변 대기";
  return (
    <StyledHeader>
      <div className="header">
        <div className="titles">
          <div className="title">{questionTitle}</div>
          <div className="createdAt">{questionCreatedAt}</div>
        </div>
        <div className="etc">
          <div className="category">{questionCategory}</div>
          <div className="answer">{answer}</div>
        </div>
      </div>
    </StyledHeader>
  );
};

export default QuestionDetailHeader;

const StyledHeader = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  width: 84%;
  font-family: "semibold";

  .header {
    display: flex;
    align-content: flex-start;
    white-space: nowrap;

    .etc {
      display: flex;
      justify-content: flex-end;
      font-size: 15px;
      width: 30%;
      gap: 20px;
      margin-top: 15px;

      .category {
        border: 1px solid var(--main-blue, #1a75ff);
        background: var(--gray-white, #fff);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 6px;
        border-radius: 12px;
        height: 30px;
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        color: #1a75ff;
      }

      .answer {
        background-color: #1a75ff;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 6px;
        border-radius: 12px;
        height: 30px;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        color: whitesmoke;
      }
    }

    .titles {
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 10px;
      width: 850px;

      .title {
        font-family: "semibold";
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
      }

      .createdAt {
        font-family: "Light";
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #5c5c5c;
      }
    }
  }
`;

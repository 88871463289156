import { useNavigate } from "react-router-dom";
import MarketingHeader from "../../CommonComponents/marketingHeader";
import TableListItem from "../../CommonComponents/tableListItem";
import { promotionListData } from "../morkData";
import { useEffect, useState } from "react";
import apis from "../../../../api/getApi";
import { TableContainer } from "../../Coupon/CouponList";

const PromotionList = () => {
  const navigate = useNavigate();
  const [createModalOn, setCreateModalOn] = useState<boolean>(false);
  const [promotionList, setPromotionList] = useState<
    {
      [key: string]: string;
    }[]
  >();
  const onClickCreatePromotion = () => {
    navigate("/marketing/createPromotion");
  };
  const onClickPluseButton_modalOpen = () => {
    setCreateModalOn(true);
  };
  const onClickPluseButton_modalClose = () => {
    setCreateModalOn(false);
  };
  const onClickListItem = (uuid: string) => {
    navigate({
      pathname: "/marketing/promotionDetail",
      search: `?promotion=${uuid}`,
    });
  };

  const getPromotionListItem = async () => {
    try {
      const { data } = await apis.getPromotionList();
      console.log(data);
      setPromotionList(data.promotions);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (!createModalOn) {
      getPromotionListItem();
    }
  }, [createModalOn]);

  console.log(promotionList);
  return (
    <>
      <div style={{ padding: "20px" }}>
        <MarketingHeader
          title="프로모션 관리"
          iconDisplay={true}
          onClickPluseIcon={onClickCreatePromotion}
        />
        <TableListItem
          itemNum="no."
          couponTitle="프로모션 제목"
          promotionStatus={"진행 상황"}
          createdAdmin={"생성인"}
          updatedAdmin={"최종 수정인"}
          tableTitle
        />
        <TableContainer>
          {promotionList?.map((item, idx) => (
            <TableListItem
              key={item.title + idx}
              itemNum={(idx + 1).toString()}
              couponTitle={item.title}
              promotionStatus={
                item.status === "expired"
                  ? "만료됨"
                  : item.status === "scheduled"
                  ? "진행예정"
                  : "진행중"
              }
              createdAdmin={item.createdAdmin}
              updatedAdmin={item.updatedAdmin}
              onClick={() => onClickListItem(item.uuid)}
            />
          ))}
        </TableContainer>
      </div>
    </>
  );
};

export default PromotionList;

import React from "react";
import HeaderDevider from "../molecule/HeaderDevider";
import { styled } from "styled-components";

interface headerButtonProps {
  setPart: React.Dispatch<React.SetStateAction<number>>;
  part: number;
}

const HeaderButtons: React.FC<headerButtonProps> = (props) => {
  return (
    <StButtons>
      <HeaderDevider
        text="공통"
        path="common"
        onClick={props.setPart}
        part={props.part}
        state={1}
      />
      <HeaderDevider
        text="개발"
        path="develope"
        onClick={props.setPart}
        part={props.part}
        state={2}
      />
      <HeaderDevider
        text="마케팅"
        path="marketing"
        onClick={props.setPart}
        part={props.part}
        state={3}
      />
    </StButtons>
  );
};

const StButtons = styled.div`
  display: flex;
  justify-content: center;
`;

export default HeaderButtons;

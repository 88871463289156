import customAxios from "./handler";
const offset = new Date().getTimezoneOffset();
const apis = {
  getChargeCount: ({ hostType, payType }) => {
    return customAxios.get(
      `/admin/proc/pays/count?hostType=${hostType}&payType=${payType}&offset=${offset}`
    );
  },
  getConsultList: ({ nowPage, pageCount }) => {
    return customAxios.get(
      `/admin/proc/intro/list?page=${nowPage}&pageCount=${pageCount}&offset=${offset}`
    );
  },
  getPermissionList: ({ nowPage, pageCount }) => {
    return customAxios.get(
      `/admin/proc/hosts/approved/list?page=${nowPage}&pageCount=${pageCount}&offset=-540`
    );
  },
  getPermissionDetail: ({ uid }) => {
    return customAxios.get(`/admin/proc/hosts/approved/${uid}`);
  },
  patchPermission: ({ hostEmail }) =>
    customAxios.patch(`/admin/proc/hosts/approved/update`, { hostEmail }),
  getUserList: () => {
    return customAxios.get(`/admin/proc/hosts?offset=${offset}`);
  },
  getRequestDetail: ({ introId }) => {
    return customAxios.get(`/admin/proc/intro?introId=${introId}&offset=-540`);
  },
  getMember: ({ uid }) => {
    return customAxios.get(`/admin/proc/masters/users/${uid}?offset=${offset}`);
  },
  getMemberAuthority: ({ uid }) => {
    return customAxios.get(`/admin/proc/masters/users/${uid}/permissions`);
  },
  getMyPage: () => {
    return customAxios.get(`/admin/proc/auth/admin?offset=${offset}`);
  },
  getMasterUserList: () => {
    return customAxios.get(`/admin/proc/masters/users?offset=${offset}`);
  },
  getMasterConsiderUserDetail: ({ uid }) => {
    return customAxios.get(
      `/admin/proc/masters/pending-users/${uid}?offset=${offset}`
    );
  },
  getUserSpecific: ({ uuid }) => {
    return customAxios.get(`/admin/proc/hosts/${uuid}?offset=${offset}`);
  },
  getCouponList: () => customAxios.get(`/admin/proc/coupons?offset=${offset}`),
  getCouponDetail: (uuid) =>
    customAxios.get(`/admin/proc/coupons/${uuid}?offset=${offset}`),
  patchCouponDate: (uuid, newDate) =>
    customAxios.patch(`/admin/proc/coupons/${uuid}?offset=${offset}`, {
      ...newDate,
    }),
  postCreateCoupon: (couponData) =>
    customAxios.post(`/admin/proc/coupons?offset=${offset}`, { ...couponData }),
  postCreatePromotion: (promotionData) =>
    customAxios.post(`/admin/proc/promotions?offset=${offset}`, {
      ...promotionData,
    }),
  getPromotionList: () =>
    customAxios.get(`/admin/proc/promotions?offset=${offset}`),
  getPromotionDetail: (uuid) =>
    customAxios.get(`/admin/proc/promotions/${uuid}?offset=${offset}`),
  patchPromotionDate: (uuid, newDate) =>
    customAxios.patch(`/admin/proc/promotions/${uuid}?offset=${offset}`, {
      ...newDate,
    }),
  getEnterprise: (entName) =>
    customAxios.get(
      `/admin/proc/credits/search/managers?entName=${entName}&offset=${offset}`
    ),
  postCreditProvide: (creditData) =>
    customAxios.post(`/admin/proc/credits?offset=${offset}`, { ...creditData }),
  getCreditList: () => customAxios.get(`/admin/proc/credits?offset=${offset}`),
  postCreditCancle: (creditData) =>
    customAxios.post(`/admin/proc/credits/cancel?offset=${offset}`, {
      ...creditData,
    }),
  getVideoAnalysisResultList: (tUuid, tId, email) =>
    customAxios.get(
      `/admin/proc/testers/analysis-video/search?testUuid=${tUuid}&testerId=${tId}&q=${email}&offset=${offset}`
    ),
  getVideoAnalysisResultDetail: (testerId, eTime) =>
    customAxios.get(
      `/admin/proc/testers/analysis-video/${testerId}?eTime=${eTime}&offset=${offset}`
    ),
  getReportAnalysisResultList: (testUuid, testerId, keyword) =>
    customAxios.get(
      `/admin/proc/testers/analysis-tester/search?testUuid=${testUuid}&testerId=${testerId}&q=${keyword}&offset=${offset}`
    ),
  getReportAnalysisResultDetail: (testerId) =>
    customAxios.get(
      `/admin/proc/testers/analysis-tester/${testerId}?offset=${offset}`
    ),
  getRequestCredits: () =>
    customAxios.get(
      `/admin/proc/credits/request-charge?offset=${offset}&lang=ko`
    ),
  getSearchRequestCredits: (word) =>
    customAxios.get(
      `/admin/proc/credits/request-charge/search?q=${word}&offset=${offset}&lang=ko`
    ),
  getRequestCreditDetail: (requestId) =>
    customAxios.get(
      `/admin/proc/credits/request-charge/${requestId}?offset=${offset}`
    ),

  postCreditRecovery: (creditData) =>
    customAxios.post(
      `/admin/proc/credits/approved-charge/recovery?offset=${offset}`,
      creditData
    ),
  postCreditsPayments: (requestData) =>
    customAxios.post(
      `/admin/proc/credits/request-charge?offset=${offset}`,
      requestData
    ),
  postBonusCreditsPayments: (requestData) =>
    customAxios.post(
      `/admin/proc/credits/request-charge/bonus?offset=${offset}`,
      requestData
    ),
  getCreditApprovalDetail: () =>
    customAxios.get("/admin/proc/credits/approved-charge?offset=-540"),
};

export default apis;

import React from "react";
import ListTextItem, { ListItemTextItemType } from "./listTextItem";

import { styled } from "styled-components";

interface ListItemBoxType {
  children: React.ReactElement | React.ReactElement[];
  focused: boolean;
}

interface ExtendedListItemBox extends React.FC<ListItemBoxType> {
  textItem: React.FC<ListItemTextItemType>;
}

const ListItemBox: ExtendedListItemBox = ({ children, focused }) => {
  return <StSistItemBox focused={focused.toString()}>{children}</StSistItemBox>;
};
ListItemBox.textItem = ListTextItem;

const StSistItemBox = styled.div<{ focused: string }>`
  padding: 15px;
  background-color: ${(props) =>
    JSON.parse(props.focused) ? "#EEF1F8" : "#F4F4F4"};
  border: ${(props) =>
    JSON.parse(props.focused) ? "1px solid #ff9d00" : "none"};

  margin-bottom: 10px;
  border-radius: 0px;
  cursor: pointer;
`;

export default ListItemBox;

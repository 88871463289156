import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
// import { modalPadding } from "../atoms";

/** 모달을 띄웠을 때 뒷배경이 움직이지 않게 잡아주기 */
const useScrollLock = () => {
  //   const setModalOn = useSetRecoilState(modalPadding);
  useEffect(() => {
    const scrollY = window.scrollY;
    const body = document.body;
    // setModalOn(true);
    // Save the current scroll position and disable scrolling
    body.style.position = "fixed";
    body.style.width = "100%"; // 왜인지 모르겠으나 이걸 안쓰면 무한대로 확장됨.
    body.style.top = `-${scrollY}px`;

    // Add a class to the body to apply background styling
    // body.classList.add('modal-open');

    return () => {
      //   setModalOn(false);
      // Restore the scroll position and enable scrolling
      const scrollY = Math.abs(parseInt(body.style.top));
      body.style.position = "";
      body.style.width = "";
      body.style.top = "";
      // body.style.paddingRight = "60px";
      window.scrollTo(0, scrollY);

      // Remove the class from the body
      body.classList.remove("modal-open");
    };
  }, []);
};

export default useScrollLock;

import { styled } from "styled-components";
import refreshIcon from "../../../img/refresh.png";
interface HeaderContentType {
  title: string;
  iconDisplay?: boolean;
  onClickPluseIcon?: () => void;
  forcequitButton?: boolean;
  onClickForceQuitButton?: () => void;
  refresh?: boolean;
  onClickRefreshButton?: () => void;
  refreshLoading?: boolean;
}

export const MarketingHeader: React.FC<HeaderContentType> = ({
  title,
  iconDisplay,
  onClickPluseIcon,
  forcequitButton,
  onClickForceQuitButton,
  refresh,
  onClickRefreshButton,
  refreshLoading,
}) => {
  return (
    <div className="marketing_titleBox">
      <div>
        <strong className="title">{title}</strong>
        {iconDisplay ? (
          <div className="pluseButton" onClick={onClickPluseIcon}>
            +
          </div>
        ) : null}
        {refresh ? (
          <RefreshButton
            onClick={refreshLoading ? () => {} : onClickRefreshButton}
          >
            <img
              className={refreshLoading ? "isLoading" : ""}
              src={refreshIcon}
              width={25}
              height={25}
            />
          </RefreshButton>
        ) : null}
      </div>
      {forcequitButton ? (
        <div className="forceQuitButton" onClick={onClickForceQuitButton}>
          강제종료
        </div>
      ) : null}
    </div>
  );
};

const RefreshButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  img {
    cursor: pointer;
  }
  img.isLoading {
    cursor: auto;
    opacity: 0.5;
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    animation: rotation 1s linear infinite;
  }
`;

export default MarketingHeader;

import { useState } from "react";
import styled from "styled-components";
import ModalBack from "../../../atomic/atom/ModalBack";

const Container = styled.div`
  width: 40%;
  height: 45px;
  margin: 5px;
  /* padding-left: 5px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;

  p {
    font-size: 15px;
    margin: 5px;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  z-index: 9999;
  text-align: center;
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const ModalContent = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #333;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #999;

  &:hover {
    color: #333;
  }
`;

const MainContainer = styled.div`
  display: contents;
`;

interface Props {
  text: string;
  enterpriseCount: number;
  enterpriseHost: number;
}

function formatNumberWithIntl(number: number) {
  return new Intl.NumberFormat().format(number);
}

const InformationBoxHost = ({
  text,
  enterpriseCount,
  enterpriseHost,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <MainContainer>
      <Container onClick={openModal}>
        <p>{text}</p>
        <p>{formatNumberWithIntl(enterpriseCount + enterpriseHost)}</p>
      </Container>

      {isModalOpen && (
        <>
          <ModalBack clickfunction={closeModal} />
          <Modal>
            <ModalTitle>{text}</ModalTitle>
            <ModalContent>
              <p>기업계정 수 : {formatNumberWithIntl(enterpriseCount)}</p>
              <p>자계정 수 : {formatNumberWithIntl(enterpriseHost)}</p>
            </ModalContent>
            <CloseButton onClick={closeModal}>&times;</CloseButton>
          </Modal>
        </>
      )}
    </MainContainer>
  );
};

export default InformationBoxHost;

import { Outlet, useParams } from "react-router-dom";
import customAxios from "../../../api/handler";
import { useContext, useEffect, useState } from "react";
import {
  IQuestionDetail,
  IQuestionDetailResponse,
} from "../interface/i.question";
import QuestionDetailHeader from "./QuestionDetailHeader";
import styled from "styled-components";
import QuestionDetailContents from "./QuestionDetailContents";
import QuestionDetailComponents from "./QuestionDetailComponents";

const QuestionDetail = () => {
  const newQuestion = new Array<IQuestionDetail>();
  const { questionUuid } = useParams() as { questionUuid: string };
  const [questionDetail, setQuestionDetail] = useState<IQuestionDetail>(
    newQuestion[0]
  );
  const [reloadQuestionDetail, setReloadQuestionDetail] =
    useState<boolean>(false);

  const getQuestionDetail = async () => {
    await customAxios
      .get(`/admin/proc/questions/detail/${questionUuid}`)
      .then(({ data }) => {
        const { questionDetail } = data as IQuestionDetailResponse;

        setQuestionDetail(questionDetail);
      });
  };

  const updateQuestionDetail = () => {
    setReloadQuestionDetail(!reloadQuestionDetail);
  };

  useEffect(() => {
    getQuestionDetail();
  }, []);

  useEffect(() => {
    getQuestionDetail();
  }, [reloadQuestionDetail]);

  return (
    <>
      <StyledDetail>
          {questionDetail?.questionTitle !== undefined ? (
            <QuestionDetailHeader
              questionDetail={questionDetail}
            ></QuestionDetailHeader>
          ) : null}
          {questionDetail?.questionTitle !== undefined ? (
            <QuestionDetailContents
              questionDetail={questionDetail}
            ></QuestionDetailContents>
          ) : null}
          {questionDetail?.component.length > 0 ? (
            <QuestionDetailComponents
              props={{
                components: questionDetail?.component,
                questionUuid,
                hostUuid: questionDetail.questionHostUuid,
                updateQuestionDetail,
              }}
            ></QuestionDetailComponents>
          ) : null}
      </StyledDetail>
      <Outlet
        context={{
          setReloadQuestionDetail: setReloadQuestionDetail,
          reloadQuestionDetail: reloadQuestionDetail,
          updateQuestionDetail: updateQuestionDetail,
        }}
      />
    </>
  );
};

export default QuestionDetail;

const StyledDetail = styled.div`
  display: flex;
  width: 1400px;
  height: 750px;
  padding: 60px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  margin-left: auto;
  overflow-y: scroll;
  word-break: break-all;

  .line {
    display: flex;
    height: 1px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    color: #d7d7d7;
  }

`;

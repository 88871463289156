import React, { useEffect, useState } from "react";
import MarketingHeader from "../../CommonComponents/marketingHeader";
import { useLocation, useNavigate } from "react-router-dom";
import apis from "../../../../api/getApi";
import {
  CouponDetailTitleSt,
  DateBox,
  DateDateType,
  DateEditBox,
  DateEditButton,
} from "../../Coupon/CouponDetail";
import { dateContent } from "../../utils";
import BannerPrevidewContainer from "../PromotionList/bannerPreviewContainer";
import { styled } from "styled-components";
import { Dispatch } from "@toast-ui/editor";
import PreviewIcon from "../../../../img/view.png";

interface PromotionDetailType {
  uuid: string;
  title: string;
  type: string;
  validityStartDate: string;
  validityEndDate: string;
  createdAdmin: string;
  updatedAdmin: string | null;
  comments: string;
  isKrServer: boolean;
  isGlServer: boolean;
}

interface BannerContentType {
  a: string;
  b1: string;
  b2: string;
  c: string;
  link: {
    signUp: string;
    signIn: string;
  };
}

const PromotionDetail: React.FC = () => {
  const location = useLocation();
  const promotionId = location.search.substring(11);
  const [editError, setEditError] = useState<string | null>(null);
  const [promorionDetail, setPromotionDetail] = useState<PromotionDetailType>({
    uuid: "",
    title: "",
    type: "",
    validityStartDate: "",
    validityEndDate: "",
    createdAdmin: "",
    updatedAdmin: null,
    comments: "",
    isKrServer: false,
    isGlServer: false,
  });

  const [bnContent, setBnContent] = useState<BannerContentType>({
    a: "",
    b1: "",
    b2: "",
    c: "",
    link: {
      signUp: "",
      signIn: "",
    },
  });

  const [newDateData, setNewDate] = useState<DateDateType>({
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  });
  const [dateEdit, setDateEdit] = useState<boolean>(false);
  const [kr_contentOpen, setKr_ContentOpen] = useState<boolean>(false);
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);

  const getPromotionDetailData = async () => {
    try {
      const { data } = await apis.getPromotionDetail(promotionId);

      if (data.isSuccess) {
        const {
          uuid,
          title,
          type,
          validityStartDate,
          validityEndDate,
          contents,
          isKrServer,
          isGlServer,
          comments,
          createdAdmin,
          updatedAdmin,
        } = data;
        setPromotionDetail({
          uuid,
          title,
          type,
          validityStartDate,
          validityEndDate,

          createdAdmin,

          updatedAdmin,
          comments,
          isKrServer,
          isGlServer,
        });
        setBnContent(contents);

        const startDate = validityStartDate.split(" ");
        const endDate = validityEndDate.split(" ");

        setNewDate({
          startDate: startDate[0],
          startTime: startDate[1],
          endDate: endDate[0],
          endTime: endDate[1],
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const BasicSection: React.FC<{
    sectionTitle: string;
    sectionData?: keyof PromotionDetailType;
  }> = ({ sectionTitle, sectionData }) => (
    <CouponDetailTitleSt>
      <section>{sectionTitle}</section>
      {sectionData ? (
        <section>{promorionDetail[sectionData]?.toString()}</section>
      ) : null}
    </CouponDetailTitleSt>
  );

  const inputProps = (inputType: string, onChangeType: keyof DateDateType) => {
    return {
      className: "content",
      type: inputType,
      value: newDateData[onChangeType],
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        setNewDate({ ...newDateData, [onChangeType]: e.target.value }),
    };
  };
  useEffect(() => {
    getPromotionDetailData();
  }, []);

  // useEffect(() => {
  //   const linkElement = document.querySelector(
  //     "#previewContentBox:last-child>div:last-child"
  //   ) as HTMLElement;

  //   const actionLink = () => {
  //     // window.open(bannerContent.link);
  //   };

  //   linkElement.style.cursor = "pointer";
  //   linkElement?.addEventListener("click", actionLink);
  //   return () => {
  //     linkElement?.removeEventListener("click", actionLink);
  //   };
  // }, [bannerContent.link]);

  const onClickSubmit = async (
    startDate: string,
    startTime: string,
    endDate: string,
    endTime: string
  ) => {
    try {
      const response = await apis.patchPromotionDate(promotionId, {
        validityStartDate: `${startDate} ${startTime}`,
        validityEndDate: `${endDate} ${endTime}`,
      });

      if (response.data.isSuccess) {
        setDateEdit(false);
        await getPromotionDetailData();
      }
    } catch (e: any) {
      setEditError(e.data.errorMessage);
    }
  };
  React.useEffect(() => {
    setEditError(null);
  }, [newDateData]);
  return (
    <PromotionDetailWrap>
      <MarketingHeader
        title={promorionDetail.title}
        forcequitButton
        onClickForceQuitButton={() =>
          onClickSubmit("1993-08-02", "19:00:00", "1993-08-02", "19:00:01")
        }
      />
      <div
        style={{ height: "70vh", overflow: "scroll", scrollbarWidth: "none" }}
      >
        <CouponDetailTitleSt>
          <section>유효기간</section>
          <section>
            {dateEdit ? (
              <DateEditBox>
                <input {...inputProps("date", "startDate")} />
                <input {...inputProps("time", "startTime")} />
                <span>~</span>
                <input {...inputProps("date", "endDate")} />
                <input {...inputProps("time", "endTime")} />
                <DateEditButton
                  onClick={() =>
                    onClickSubmit(
                      newDateData.startDate,
                      newDateData.startTime,
                      newDateData.endDate,
                      newDateData.endTime
                    )
                  }
                >
                  적용
                </DateEditButton>
              </DateEditBox>
            ) : (
              <DateBox>
                {dateContent(promorionDetail.validityStartDate, "date")}
                {dateContent(promorionDetail.validityStartDate, "time")}
                <div>~</div>
                {dateContent(promorionDetail.validityEndDate, "date")}
                {dateContent(promorionDetail.validityEndDate, "time")}
                <DateEditButton onClick={() => setDateEdit(true)}>
                  편집
                </DateEditButton>
              </DateBox>
            )}
            {editError ? (
              <p className="errorText" style={{}}>
                {editError}
              </p>
            ) : null}
          </section>
        </CouponDetailTitleSt>
        <BasicSection sectionTitle="형태" sectionData="type" />
        <CouponDetailTitleSt>
          <section>콘텐츠</section>
          <section style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: 10 }}>
              <PreviewButton
                onClick={() => {
                  setPreviewOpen(!previewOpen);
                }}
              >
                프로모션 영문 콘텐츠 미리보기{" "}
                {previewOpen ? (
                  <span style={{ fontSize: 13 }}>닫기</span>
                ) : null}
                <img src={PreviewIcon} alt="" width={20} height={20} />
              </PreviewButton>
            </div>
            <LinkInfoBox>
              <p style={{ borderRight: "1px solid #d3d3d3", paddingRight: 10 }}>
                <span style={{ fontSize: 14, paddingRight: 5 }}>
                  로그인 전 링크:
                </span>
                {bnContent.link.signUp === "proctormaticSignup"
                  ? " 프록토매틱 회원가입 페이지"
                  : bnContent.link.signUp}
              </p>
              <p>
                <span style={{ fontSize: 14, paddingRight: 5 }}>
                  로그인 후 링크:
                </span>
                {bnContent.link.signIn === "proctormaticMypage"
                  ? "프록토매틱 마이페이지"
                  : bnContent.link.signIn}
              </p>
            </LinkInfoBox>
          </section>
          {kr_contentOpen ? (
            <BannerPrevidewContainerWrap>
              <BannerPrevidewContainer
                contentsA={bnContent.a}
                contentsB1={bnContent.b1}
                contentsB2={bnContent.b2}
                contentsC={bnContent.c}
              />
            </BannerPrevidewContainerWrap>
          ) : null}
        </CouponDetailTitleSt>

        <CouponDetailTitleSt>
          <section>노출 서버</section>
          <section>{promorionDetail.isKrServer ? "한국" : "글로벌"}</section>
        </CouponDetailTitleSt>

        <BasicSection sectionTitle="생성인" sectionData="createdAdmin" />
        <BasicSection sectionTitle="수정인" sectionData="updatedAdmin" />
        <BasicSection sectionTitle="비고" sectionData="comments" />
      </div>

      {previewOpen ? (
        <BannerPrevidewContainerWrap>
          <BannerPrevidewContainer
            contentsA={bnContent.a}
            contentsB1={bnContent.b1}
            contentsB2={bnContent.b2}
            contentsC={bnContent.c}
          />
        </BannerPrevidewContainerWrap>
      ) : null}
    </PromotionDetailWrap>
  );
};

export default PromotionDetail;

const PromotionDetailWrap = styled.div`
  padding: 20px;
  .linkContentBox {
    padding: 20px 20px;
    p {
      padding-bottom: 10px;
      span {
        padding-right: 15px;
      }
    }
  }
`;
const BannerPrevidewContainerWrap = styled.div`
  & > div {
    display: block;
  }
`;

const PreviewButton = styled.div`
  padding: 5px 20px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #4f5460;
  width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: #e9eefc;
  }
`;

const LinkInfoBox = styled.div`
  display: flex;
  padding: 10px 0;
  p:last-child {
    padding-left: 10px;
  }
  span {
    color: #646464;
  }
`;

import React from "react";
import PaymentTemplate from "../../atomic/template/PaymentTemplate";
import { styled } from "styled-components";
import { Outlet } from "react-router-dom";

const PaymentList = () => {
  return (
    <>
      <StPage>
        <PaymentTemplate />
      </StPage>
      <Outlet />
    </>
  );
};

const StPage = styled.div`
  width: 100%;
  /* max-height: calc(100vh - 75px); */
  height: 100%;
  overflow-x: hidden;
  padding: 48px;
  box-sizing: border-box;
`;

export default PaymentList;

import { styled } from "styled-components";
import NoticeBoardCategory from "./NoticeBoardCategory";
import { INotice, INoticeListProps } from "../interface/i.notice";

/** 공지사항 보드 */
const NoticeBoard = ({noticeList} : {noticeList: INotice[]}): JSX.Element => {
  return (
    <StyledBoard>
      <NoticeBoardCategory notices={noticeList}></NoticeBoardCategory>
    </StyledBoard>
  );
};

export default NoticeBoard;

export const StyledBoard = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid var(--gray-light-gray, #d7d7d7);
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

import React from "react";
import TextLine from "../atom/TextLine";
import { styled } from "styled-components";
import { Filter } from "../template/RefundTemplate";
import Input from "../atom/Input";
import Button from "../atom/Button";
import DivButton from "../atom/DivButton";

interface HeaderProps {
  title: string;
  search: boolean;
  filter?: Filter;
  setChooseFilter?: React.Dispatch<React.SetStateAction<number>>;
  chooseFilter?: number;
  searchFunction?: (event: React.FormEvent) => void;
  useref?: React.MutableRefObject<HTMLInputElement | null>;
}

const PageHeader: React.FC<HeaderProps> = ({
  title,
  search,
  filter,
  chooseFilter,
  setChooseFilter,
  searchFunction,
  useref,
}) => {
  return (
    <StPageHeader filtercount={filter?.filterList?.length}>
      <TextLine text={title} fontSize={30} fontFamily="medium" />
      <div className="leftPart">
        {search && (
          <form className="searchForm">
            <Input
              width={200}
              height={30}
              placeholderText="이메일 입력"
              border="1px solid black"
              borderradius={8}
              padding="0 10px"
              ref={useref}
            />
            <Button
              width={60}
              height={30}
              text="검색"
              fontSize={14}
              fontcolor="white"
              backgroundcolor="#283b6f"
              border="none"
              borderradius={8}
              onclick={searchFunction}
            />
          </form>
        )}
        {filter?.use && (
          <div className="filter">
            {filter?.filterList?.map((value, index) => {
              return (
                <div
                  onClick={() => setChooseFilter?.(index)}
                  key={index}
                  style={
                    chooseFilter === index
                      ? { backgroundColor: "#283b6f", color: "white" }
                      : {}
                  }
                >
                  {value}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </StPageHeader>
  );
};

const StPageHeader = styled.div<{ filtercount?: number }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .leftPart {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
    margin-top: auto;
    .searchForm {
      display: grid;
      grid-template-columns: auto auto;
      gap: 10px;
    }
    .filter {
      display: grid;
      grid-template-columns: repeat(${(props) => props.filtercount}, 30px);
      gap: 10px;
      align-items: center;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 30px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
`;

export default PageHeader;

import React from "react";
import useGetPermissionDetail from "../../hook/api/useGetPermissionDetail";
import { useNavigate, useParams } from "react-router-dom";
import PageTemplate from "../../atomic/template/PageTemplate";
import DivTwoPart from "../../atomic/atom/DivTwoPart";
import TextLine from "../../atomic/atom/TextLine";
import OrangeButton from "../../atomic/atom/OrangeButton";
import apis from "../../api/getApi";
import useToast from "../../hook/useToast";

const CorporationPermissionDetail = () => {
  const uid = useParams().uid;
  const nowPage = useParams().nowPage;
  const { data } = useGetPermissionDetail({ uid });
  const navigate = useNavigate();
  const showToast = useToast();
  console.log(data);
  return (
    <PageTemplate>
      <DivTwoPart
        display="grid"
        justify="center"
        width="800px"
        padding="60px"
        margin="0 auto"
        border="1px solid #d6d9dd"
        border_radius={24}
      >
        <DivTwoPart
          font_size={28}
          font_color="#2276dc"
          font_family="bold"
          padding="28px 0"
          margin="0 auto"
        >
          프록토매틱 솔루션 도입 상담 신청서
        </DivTwoPart>
        <DivTwoPart margin="0 auto">
          손흥민 김연아 레이 베서니 마크 레츠고
        </DivTwoPart>
        <DivTwoPart display="grid" gap={20} margin="40px 0 0 0">
          <DivTwoPart display="grid" grid_template_column="1fr 1fr" gap={24}>
            <DivTwoPart display="grid" gap={8}>
              <TextLine text="성함" fontSize={18} lineheight={30} />
              <DivTwoPart
                border="1px solid #d9d9d9"
                border_radius={8}
                padding="12px 16px"
                font_size={17}
                lineheight={30}
              >
                {data?.host.hostName}
              </DivTwoPart>
            </DivTwoPart>
            <DivTwoPart display="grid" gap={8}>
              <TextLine text="소속기관" fontSize={18} lineheight={30} />
              <DivTwoPart
                border="1px solid #d9d9d9"
                border_radius={8}
                padding="12px 16px"
                font_size={17}
                lineheight={30}
              >
                {data?.host.hostGroupName}
              </DivTwoPart>
            </DivTwoPart>
          </DivTwoPart>
          <DivTwoPart display="grid " grid_template_column="1fr 1fr" gap={24}>
            <DivTwoPart display="grid" gap={8}>
              <TextLine text="사업자 등록번호" fontSize={18} lineheight={30} />
              <DivTwoPart
                border="1px solid #d9d9d9"
                border_radius={8}
                padding="12px 16px"
                font_size={17}
                lineheight={30}
              >
                {data?.host.hostGroupRegistryNumber}
              </DivTwoPart>
            </DivTwoPart>
            <DivTwoPart display="grid" gap={8}>
              <TextLine text="직책" fontSize={18} lineheight={30} />
              <DivTwoPart
                border="1px solid #d9d9d9"
                border_radius={8}
                padding="12px 16px"
                font_size={17}
                lineheight={30}
              >
                {data?.host.hostJobPosition}
              </DivTwoPart>
            </DivTwoPart>
          </DivTwoPart>
          <DivTwoPart display="grid" gap={8}>
            <TextLine text="이메일" fontSize={18} lineheight={30} />
            <DivTwoPart
              border="1px solid #d9d9d9"
              border_radius={8}
              padding="12px 16px"
              font_size={17}
              lineheight={30}
            >
              {data?.host.hostEmail}
            </DivTwoPart>
          </DivTwoPart>
          <DivTwoPart display="grid" gap={8}>
            <TextLine text="전화번호" fontSize={18} lineheight={30} />
            <DivTwoPart
              border="1px solid #d9d9d9"
              border_radius={8}
              padding="12px 16px"
              font_size={17}
              lineheight={30}
            >
              {data?.host.hostPhoneNumber}
            </DivTwoPart>
          </DivTwoPart>
          <DivTwoPart display="grid" grid_template_column="150px 400px">
            <TextLine text="마케팅 수신여부" />
            <TextLine
              text={data?.host.isAgreedOfMarketing ? "수락" : "수락안함"}
            />
          </DivTwoPart>
          <DivTwoPart display="grid" grid_template_column="150px 400px">
            <TextLine text="신청날짜" />
            <TextLine text={data?.host.hostCreatedAt} />
          </DivTwoPart>
        </DivTwoPart>
        <DivTwoPart
          display="grid"
          grid_template_column="204px 204px"
          gap={20}
          margin="60px auto 0"
        >
          <OrangeButton
            text="목록으로"
            onclick={() =>
              navigate(`/marketing/corporationPermission/${nowPage}`)
            }
            backgroundcolor="#20315B"
          />
          <OrangeButton
            text="수락"
            onclick={() =>
              apis
                .patchPermission({
                  hostEmail: data?.host.hostEmail,
                })
                .then(() => {
                  showToast({ message: "정상 가입 되었습니다." });
                  navigate(`/marketing/corporationPermission/${nowPage}`);
                })
                .catch(() => {
                  showToast({
                    message: "문제가 발생하였습니다. 개발자에게 문의해주세요.",
                  });
                })
            }
          />
        </DivTwoPart>
      </DivTwoPart>
    </PageTemplate>
  );
};

export default CorporationPermissionDetail;

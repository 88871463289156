import React from "react";
import TextLine from "../atom/TextLine";
import { styled } from "styled-components";

interface tableProp {
  item: string[];
  use: string;
  ent?: string;
  widthforitem?: number;
  onClick?: () => void;
}

const TableItem: React.FC<tableProp> = ({
  item,
  use,
  ent,
  onClick,
  widthforitem,
}) => {
  return (
    <StHeader
      use={use}
      ent={ent || ""}
      widthforitem={widthforitem || 160}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {item.map((value, index) => {
        return <TextLine key={index} text={value} />;
      })}
    </StHeader>
  );
};

const StHeader = styled.div<{ use: string; ent: string; widthforitem: number }>`
  display: flex;
  background-color: ${(props) => (props.use === "header" ? "#f9f9f9" : "")};
  padding: 10px 10px;
  gap: 10px;
  cursor: ${(props) => (props.ent ? "pointer" : "")};
  &:hover {
    background-color: ${(props) => (props.ent ? "#969090" : "")};
  }
  :nth-child(1) {
  }
  :nth-child(n) {
    display: flex;
    justify-content: center;
    width: ${(props) => props.widthforitem}px;
  }
  :nth-last-child(1) {
    margin-left: auto;
  }
`;

export default TableItem;

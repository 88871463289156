import React from "react";
import loginLeftImg from "../../img/loginBackground.png";
import { styled } from "styled-components";
import SignupInputFiled from "../../atomic/organism/SignupInputFiled";

const Signup = () => {
  return (
    <StLogin>
      <div className="imgBox">
        <img src={loginLeftImg} alt="" />
      </div>
      <SignupInputFiled />
    </StLogin>
  );
};
const StLogin = styled.div`
  display: flex;
  .imgBox {
    width: 70%;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;
export default Signup;

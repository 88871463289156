import styled from "styled-components";
import { IQuestionList } from "../interface/i.question";
import { NavigateFunction, useNavigate } from "react-router-dom";

const QuestionBoardContents = ({ question }: { question: IQuestionList }) => {
  const colorCategory = `#ffeddc`;
  const colorImportant = `rgb(254, 224, 224)`;
  const {
    questionHostEmail,
    questionCategory,
    questionCreatedAt,
    questionTitle,
    questionUuid,
  } = question;
  const navigate = useNavigate();

  const navigateDetailPage = () => {
    navigateQuestionDetail(navigate, questionUuid);
  };
  return (
    <StyledContents>
      <input type="checkbox" className="checkbox" />
      <div className="data" onClick={navigateDetailPage}>
        <div className="writer">{questionHostEmail}</div>
        <div className="title">{questionTitle}</div>
        <StyledCategory color={colorCategory}>
          {questionCategory}
        </StyledCategory>
        <div className="createdAt">{questionCreatedAt}</div>
      </div>
    </StyledContents>
  );
};

export default QuestionBoardContents;

const navigateQuestionDetail = (
  navigate: NavigateFunction,
  questionUuid: string
) => {
  navigate(`/common/qnaList/detail/${questionUuid}`);
};

const StyledContents = styled.div`
  display: flex;
  height: 18px;
  padding: 12px 24px;
  align-items: center;
  gap: 30px;
  align-self: stretch;
  justify-content: flex-start;
  white-space: nowrap;
  background-color: ${(props) => props.color};

  .checkbox {
    display: flex;
    width: 32px;
    height: 15px;
    padding: 5.665px;
    justify-content: center;
    align-items: center;
  }

  .data {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    cursor: pointer;

    .writer {
      width: 180px;
      white-space: nowrap; /* 텍스트 줄 바꿈 비활성화 */
      overflow: hidden; /* 오버플로우 내용 숨김 */
      text-overflow: ellipsis; /* 생략 부호(...) 표시 */
      max-width: 200px; /* 원하는 최대 너비 설정 (예: 200px) */
    }

    .title {
      display: flex;
      width: 850px;
      white-space: nowrap; /* 텍스트 줄 바꿈 비활성화 */
      overflow: hidden; /* 오버플로우 내용 숨김 */
      text-overflow: ellipsis; /* 생략 부호(...) 표시 */
      max-width: 850px; /* 원하는 최대 너비 설정 (예: 200px) */
    }

    .createdAt {
      width: 105px;
    }
  }
`;

export const StyledCategory = styled.div<{ color: string }>`
  width: 80px;
  border-radius: 4px;
  background: white;
`;

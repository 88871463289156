import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import ExcelCell from "../molecule/ExcelCell";
import Input from "../atom/Input";
import OnChangeInput from "../atom/OnchangeInput";

const ExcelReader: React.FC = () => {
  const [excelData, setExcelData] = useState<string[][] | null | any>(null); // 받아온 excel 데이터의 원본
  const [focusCellRow, setFocusCellRow] = useState<number>(-1); // 유저의 클릭을 감지 가로 셀
  const [focusCellColumn, setFocusCellColumn] = useState<number>(-1); // 유저의 클릭을 감지 세로 셀
  const headerLineStart = 1; // 엑셀 파일 내에서 헤더가 시작하는 라인
  const inputRef = useRef<HTMLInputElement | null>(null); // input 값

  /** 엑셀파일을 불러와 데이터로 변환 후 저장 */
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0]; // 단일 시트를 가정합니다.
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
        });

        setExcelData(jsonData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const [dataRowCellCount, setDataRowCellCount] = useState<number>(0);
  const [dataColumnCellCount, setDataColumnCellCount] = useState<number>(0);

  /** 엑셀에서 읽어온 데이터의 세로 cell 갯수를 센다. */
  const countColumnCell = () => {
    for (let i = 0; i < excelData?.length; i++) {
      if (excelData[i]?.length > 0) {
        setDataColumnCellCount(i + 1);
      }
    }
  };

  /** 엑셀에서 읽어온 데이터의 최대 가로 cell 갯수를 센다. */
  const countRowCell = () => {
    for (let i = 0; i < excelData?.length - 1; i++) {
      if (dataRowCellCount < excelData[i]?.length) {
        setDataRowCellCount(excelData[i]?.length);
      }
    }
  };

  const modifyExcel = (newValue: string, row: number, column: number) => {
    const newArray: any = excelData;
    newArray[row + 1][column] = newValue;
  };

  /** 엑셀 행 추가 */
  const rowLinePlues = () => {};

  /** 엑셀 열 추가 */
  const columnLinePlus = () => {};

  useEffect(() => {
    countColumnCell();
  }, [excelData]);

  useEffect(() => {
    countRowCell();
  }, [excelData, dataColumnCellCount]);

  /** 셀을 클릭시 input 이 생성되며 focus 된다. */
  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, [focusCellColumn, focusCellRow]);

  return (
    <StExcel>
      <input type="file" onChange={handleFileUpload} />
      {/* <ExcelCell
            text="+"
            border="1px solid black"
            // width={1000}
            // min_height={40}
            font_size={20}
            justify_content="center"
            align_items="center"
            onClick={() => {
              setDataRowCellCount(dataRowCellCount + 1);
            }}
          /> */}
      {excelData && (
        <div className="table">
          <div className="header">
            {[...Array(dataRowCellCount)]?.map(
              (header: string, index: number) => (
                <ExcelCell
                  text={excelData[0][index]}
                  key={index}
                  width={200}
                  min_height={40}
                  justify_content="center"
                  align_items="center"
                  font_size={20}
                  border="1px solid black"
                  background_color="#F0F1F2"
                />
              )
            )}
          </div>
          <div className="body">
            {[...Array(dataColumnCellCount)]?.map(
              (row: string[], rowIndex: number) => (
                <div className="bodyLine" key={rowIndex}>
                  {/* {row.map((cell, cellIndex: number) => ( */}
                  {[...Array(dataRowCellCount)]?.map(
                    (cell, cellIndex: number) => {
                      return (
                        <div key={cellIndex}>
                          {rowIndex === focusCellRow &&
                          cellIndex === focusCellColumn ? (
                            <OnChangeInput
                              width={200}
                              height={30}
                              border="1px solid black"
                              onblur={() => {
                                setFocusCellColumn(-1);
                                setFocusCellRow(-1);
                              }}
                              onchange={(e) => {
                                modifyExcel(
                                  e.target.value,
                                  focusCellRow,
                                  focusCellColumn
                                );
                              }}
                              inputRef={inputRef}
                            />
                          ) : (
                            <ExcelCell
                              text={
                                excelData?.slice(headerLineStart)[rowIndex][
                                  cellIndex
                                ]
                              } // 헤더 부분을 빼주기 위해 slice 1 을 한다.
                              key={cellIndex}
                              width={200}
                              min_height={30}
                              justify_content="center"
                              align_items="center"
                              border="1px solid black"
                              background_color={cell?.s?.bgColor}
                              onClick={() => {
                                setFocusCellRow(rowIndex);
                                setFocusCellColumn(cellIndex);
                              }}
                            />
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              )
            )}
          </div>
          <div>
            <ExcelCell
              text="+"
              border="1px solid black"
              width={1000}
              min_height={40}
              font_size={20}
              justify_content="center"
              align_items="center"
              onClick={() => {
                setDataColumnCellCount(dataColumnCellCount + 1);
              }}
            />
          </div>
        </div>
      )}
    </StExcel>
  );
};

const StExcel = styled.div`
  display: flex;
  flex-direction: column;
  .table {
    display: flex;
    flex-direction: column;
    .header {
      display: flex;
    }
    .body {
      display: grid;
      .bodyLine {
        display: flex;
      }
    }
  }
`;

export default ExcelReader;

import { ChangeEventHandler, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { INoticeChildsCategoryOptionsProps } from "../NoticeList/interface/i.notice";
import { category } from "../../atom";

const NoticePostContentsOptions = ({
  props,
}: {
  props: INoticeChildsCategoryOptionsProps;
}) => {
  const [normalChecked, setNormalChecked] = useState(false);
  const [hostChecked, setHostCheckd] = useState(false);
  const [testerChecked, setTesterChecked] = useState(false);

  const [trueChecked, setTrueChecked] = useState(false);
  const [falseChecked, setFalseChecked] = useState(false);

  const [exposeKrChecked, setExposeKrCheckd] = useState(false);
  const [exposeEnChecked, setExposeEnCheckd] = useState(false);

  // 체크박스 변경 핸들러
  const handleCategoryCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value === "일반") {
      setNormalChecked(true);
      setHostCheckd(false);
      setTesterChecked(false);
    } else if (value === "주최자") {
      setHostCheckd(true);
      setNormalChecked(false);
      setTesterChecked(false);
    } else if (value === "응시자") {
      setTesterChecked(true);
      setNormalChecked(false);
      setHostCheckd(false);
    }

    props.saveNoticeCategoryOptionsHandler.bind(this)({ categoryOptions: value });

    props.handleNoticeSaveData();
  };

  const handleExposeCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    console.log("VALUE:", value);
    
    if (value === "kr") {
      setExposeKrCheckd(true);
      setExposeEnCheckd(false);
    } else if (value === "en") {
      setExposeKrCheckd(false);
      setExposeEnCheckd(true);
    };

    const v = value === 'kr' ? 'kr' : 'en';  

    props.saveNoticeExposeOptionsHandler.bind(this)({ exposeServer: v });

    props.handleNoticeSaveData();

    console.log(v);
    
  }

  const handleIsImportantCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value === "true") {
      setTrueChecked(true);
      setFalseChecked(false);
    } else if (value === "false") {
      setTrueChecked(false);
      setFalseChecked(true);
    };

    const v = value === "true" ? 1 : 0;

    props.saveNoticeIsImportantOptionsHandler.bind(this)({ isImportant : v });

    
  }


  return (
    <StyledCategorySection>
      <div className="categoryBox">
        <div className="category">유형</div>
      </div>
      <div className="optionsInput">
        <label className="normal">
          <input
            onChange={handleCategoryCheckboxChange}
            className="inputs"
            type="radio"
            checked={normalChecked}
            value="일반"
          ></input>
          <div className="normalSelect">일반</div>
        </label>
        <label className="host">
          <input
            onChange={handleCategoryCheckboxChange}
            className="inputs"
            type="radio"
            checked={hostChecked}
            value="주최자"
          ></input>
          <div className="hostSelect">주최자</div>
        </label>
        <label className="tester">
          <input
            onChange={handleCategoryCheckboxChange}
            className="inputs"
            type="radio"
            checked={testerChecked}
            value="응시자"
          ></input>
          <div className="testerSelect">응시자</div>
        </label>
      </div>
      <div className="isImportant">
        <div className="importantBox">
          <div className="important">중요</div>
        </div>
        <div className="optionsImportantInput">
          <label className="true">
            <input
              onChange={handleIsImportantCheckBoxChange}
              className="inputs"
              type="radio"
              checked={trueChecked}
              value="true"
            ></input>
            <div className="trueSelect">YES</div>
          </label>
          <label className="false">
            <input
              onChange={handleIsImportantCheckBoxChange}
              className="inputs"
              type="radio"
              checked={falseChecked}
              value="false"
            ></input>
            <div className="falseSelect">NO</div>
          </label>
        </div>
      </div>
      <div className="exposeServer">
        <div className="exposeBox">
          <div className="expose">노출 지역</div>
        </div>
        <div className="optionsExposeInput">
          <label className="kr">
            <input
              onChange={handleExposeCheckBoxChange}
              className="inputs"
              type="radio"
              checked={exposeKrChecked}
              value="kr"
            ></input>
            <div className="krSelect">KR</div>
          </label>
          <label className="en">
            <input
              onChange={handleExposeCheckBoxChange}
              className="inputs"
              type="radio"
              checked={exposeEnChecked}
              value="en"
            ></input>
            <div className="enSelect">EN</div>
          </label>
        </div>
      </div>
    </StyledCategorySection>
  );
};

export default NoticePostContentsOptions;

export const StyledCategorySection = styled.div`
  display: flex;
  width: 990px;
  padding: 20px;
  gap: 15px;
  white-space: nowrap;
  justify-content: flex-start;
  .categoryBox {
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 12px;
    padding: 18px;
    width: 30px;

    .category {
      align-items: center;
    }
  }

  .optionsInput {
    display: flex;
    align-items: center;
    gap: 12px;

    .normal {
      display: flex;
      gap: 4px;

      .inputs {
        width: 30px;
        height: 30px;
      }

      .normalSelect {
        margin-top: 4px;
        display: flex;
        height: 30px;
        align-items: center;
      }
    }

    .host {
      display: flex;
      gap: 4px;

      .inputs {
        width: 30px;
        height: 30px;
      }

      .hostSelect {
        margin-top: 4px;
        display: flex;
        height: 30px;
        align-items: center;
      }
    }

    .tester {
      display: flex;
      gap: 4px;

      .inputs {
        width: 30px;
        height: 30px;
      }

      .testerSelect {
        margin-top: 4px;
        display: flex;
        height: 30px;
        align-items: center;
      }
    }
  }

  .isImportant {
    display: flex;
    align-items: center;
    gap: 12px;

    .importantBox {
      border: 1px solid rgba(128, 128, 128, 0.5);
      border-radius: 12px;
      padding: 18px;
      width: 30px;

      .important {
        align-items: center;
      }
    }

    .optionsImportantInput {
      display: flex;
      gap: 12px;

      .true {
        display: flex;
        gap: 4px;

        .inputs {
          width: 30px;
          height: 30px;
        }

        .trueSelect {
          margin-top: 4px;
          display: flex;
          height: 30px;
          align-items: center;
        }
      }

      .false {
        display: flex;
        gap: 4px;

        .inputs {
          width: 30px;
          height: 30px;
        }

        .falseSelect {
          margin-top: 4px;
          display: flex;
          height: 30px;
          align-items: center;
        }
      }
    }
  }

  .exposeServer {
    display: flex;
    align-items: center;
    gap: 12px;

    .exposeBox {
      border: 1px solid rgba(128, 128, 128, 0.5);
      border-radius: 12px;
      padding: 18px;
      width: 100%;

      .expose {
        align-items: center;
      }
    }

    .optionsExposeInput {
      display: flex;
      gap: 12px;

      .kr {
        display: flex;
        gap: 4px;

        .inputs {
          width: 30px;
          height: 30px;
        }

        .krSelect {
          margin-top: 4px;
          display: flex;
          height: 30px;
          align-items: center;
        }
      }

      .en {
        display: flex;
        gap: 4px;

        .inputs {
          width: 30px;
          height: 30px;
        }

        .enSelect {
          margin-top: 4px;
          display: flex;
          height: 30px;
          align-items: center;
        }
      }
    }
  }
`;

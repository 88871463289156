import React from "react";
import styled from "styled-components";
import {
  KeyboardDoubleArrowLeft,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";

const Container = styled.div`
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer; /* Add cursor pointer for the year and month label */

  p {
    font-size: 30px;
    font-weight: 700;
  }
`;

const BtnBox = styled.div`
  display: flex;

  button {
    border: 0;
    outline: 0;
    background-color: white;
    width: 30px;
    height: 30px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: gray;
    }
  }
`;

interface Props {
  nowDate: Date;
  setNowDate: React.Dispatch<React.SetStateAction<Date>>;
}

const ControlDate = ({ nowDate, setNowDate }: Props) => {
  const changeYear = (change: number) => {
    const date = new Date(nowDate.getTime());
    date.setFullYear(date.getFullYear() + change);
    setNowDate(date);
  };

  const changeMonth = (change: number) => {
    const date = new Date(nowDate.getTime());
    date.setMonth(date.getMonth() + change);
    setNowDate(date);
  };

  const goToToday = () => {
    const today = new Date();
    setNowDate(today);
  };

  return (
    <Container>
      <BtnBox>
        <button onClick={() => changeYear(-1)}>
          <KeyboardDoubleArrowLeft fontSize="large" />
        </button>
        <button onClick={() => changeMonth(-1)}>
          <KeyboardArrowLeft fontSize="large" />
        </button>
      </BtnBox>
      <p onClick={goToToday}>{`${nowDate.getFullYear()}.${
        nowDate.getMonth() + 1
      }`}</p>
      <BtnBox>
        <button onClick={() => changeMonth(1)}>
          <KeyboardArrowRight fontSize="large" />
        </button>
        <button onClick={() => changeYear(1)}>
          <KeyboardDoubleArrowRight fontSize="large" />
        </button>
      </BtnBox>
    </Container>
  );
};

export default ControlDate;

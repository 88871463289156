import React, { ChangeEvent, ChangeEventHandler } from "react";
import { ObjectType } from "..";
import { styled } from "styled-components";

interface TextFieldType {
  label: string;
  value: ObjectType;
  setterFunction: React.Dispatch<React.SetStateAction<ObjectType>>;
}
const TextField: React.FC<TextFieldType> = ({
  label,
  value,
  setterFunction,
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setterFunction({ ...value, [label]: e.target.value });
  };

  return (
    <TextFieldContentBox>
      <label htmlFor={`${label}TextField`}>{label} : </label>
      <TextInput
        value={value[label]}
        name={`${label}TextField`}
        type="text"
        onChange={onChange}
      />
    </TextFieldContentBox>
  );
};
const TextFieldContentBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 15px 5px 30px;
  justify-content: center;
  align-items: center;
`;
const TextInput = styled.input`
  border: none;
  padding: 6px;
  border-radius: 3px;
  background-color: #f4f4f4;
  display: flex;
  flex-grow: 1;
  margin-left: 7px;
`;

export default TextField;

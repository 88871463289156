import React from "react";
import { styled } from "styled-components";
import customAxios from "../../api/handler";
import { useParams } from "react-router-dom";
import useToast from "../../hook/useToast";

interface AuthorityLineProp {
  pageName?: string;
  pageEng?: string;
  check?: boolean;
  setChanged: React.Dispatch<React.SetStateAction<boolean>>;
  changed: boolean;
}

const AuthorityLine: React.FC<AuthorityLineProp> = (props) => {
  const uid = useParams().uid;
  const showToast = useToast();
  const authorityChange = () => {
    customAxios
      .patch(`/admin/proc/masters/users/permissions`, {
        adminUuid: uid,
        page: props.pageEng,
      })
      .then(() => {
        showToast({ message: "권한 변경됨" });
        props.setChanged(!props.changed);
      })
      .catch(({ data }) => {
        showToast({ message: data.errorMessage });
      });
  };

  return (
    <StAuthorityLine
      className="authorityItem"
      check={props.check}
      onClick={authorityChange}
      setChanged={props.setChanged}
      changed={props.changed}
    >
      <div>{props.pageName}</div>
      <div className="checkBox" />
    </StAuthorityLine>
  );
};

const StAuthorityLine = styled.div<AuthorityLineProp>`
  display: grid;
  grid-template-columns: 200px 100px;
  justify-content: center;
  align-items: center;
  gap: 200px;
  width: 500px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  &:hover {
    background-color: #bdbdbd;
  }
  .checkBox {
    margin: 0 auto;
    width: 15px;
    height: 15px;
    background-color: ${(props) => (props.check ? "#8ce0ae" : "red")};
  }
`;

export default AuthorityLine;

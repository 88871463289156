import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
  LegacyRef,
  MutableRefObject,
  useLayoutEffect,
  useMemo,
} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { styled } from "styled-components";
import { PromotionBannerContentType } from "../Promotion/CreatePromotion/index";

interface EditerType {
  contentObj: PromotionBannerContentType;
  contentObjKey: keyof PromotionBannerContentType;
  setTextContent: Dispatch<SetStateAction<PromotionBannerContentType>>;
  quillRef: MutableRefObject<ReactQuill | null>;
}

const Editer: React.FC<EditerType> = React.memo(
  ({ contentObj, contentObjKey, setTextContent, quillRef }) => {
    const [text, setText] = useState<string>(``);
    const [selectedColor, setSelectedColor] = useState<string>("#ffffff"); // Initial color
    // const quillRef = useRef<ReactQuill | null>(null);

    useEffect(() => {
      if (quillRef.current) {
        const quill = quillRef.current.getEditor();
        quill.format("color", selectedColor);

        quill.root.addEventListener("input", () => {
          setText(quill.root.innerHTML);
        });
      }
    }, []);
    /////
    const modules = useMemo(() => {
      return {
        keyboard: {
          bindings: {
            "header enter": {
              key: "Enter",
              format: ["header", "size", "color"],
              handler() {
                // Check if the editor content is empty
                const editorText = quillRef?.current
                  ?.getEditor()
                  .getText()
                  .trim();
                if (!editorText) {
                  // Editor is empty, prevent the default Enter key behavior
                  return false;
                }

                // Editor is not empty, allow the default Enter key behavior
                return false;
              },
            },
          },
        },
      };
    }, []);
    /////

    const handleChange = (value: string) => {
      setText(value);
      setTextContent({ ...contentObj, [contentObjKey]: value });
    };

    const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newColor = event.target.value;

      if (quillRef.current) {
        const quill = quillRef.current.getEditor();
        quill.format("color", newColor);
      }
      setSelectedColor(newColor);
    };

    const getCurrentTextColor = () => {
      if (quillRef.current) {
        const quill = quillRef.current.getEditor();
        const selection = quill.getSelection();
        if (selection) {
          const [line] = quill.getLine(selection.index);
          if (line) {
            const format = quill.getFormat(line.index, line.length);
            return format.color || "#000000"; // 커서 위치의 텍스트 색상 또는 기본 색상을 반환합니다.
          }
        }
      }
      return "#000000";
    };

    useEffect(() => {
      setSelectedColor(getCurrentTextColor());
    }, [text]);

    return (
      <EditerWrap>
        <ColorInput
          type="color"
          value={selectedColor}
          onChange={handleColorChange}
          style={{ marginBottom: "10px" }}
        />
        <div onClick={() => setSelectedColor(getCurrentTextColor())}>
          <ReactQuill
            ref={quillRef}
            value={contentObj[contentObjKey]}
            //   defaultValue={`${contentObj[contentObjKey]}`}
            onChange={handleChange}
            modules={modules}
          />
        </div>
      </EditerWrap>
    );
  }
);

// .ql-toolbar.ql-snow
export default Editer;

const EditerWrap = styled.div`
  position: relative;
  .ql-editor {
    border-radius: 3px;
    background-color: #000000;
    padding: 10px 15px;
  }
  .ql-toolbar.ql-snow {
    display: none;
  }

  .ql-container.ql-snow {
    border: none;
  }
`;

const ColorInput = styled.input<{ value: string }>`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  -webkit-appearance: none;
  padding: 0;
  border: ${(props) => `1px solid ${props.value}`};
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  margin-bottom: 0 !important;
  box-shadow: 3px 3px 3px -1px #111e4342;
  &::-webkit-color-swatch {
    border: none;
    border-radius: 100px;
    padding: 0;
    box-shadow:
      0 4px 6px rgba(50, 50, 93, 0.11),
      0 1px 3px rgba(0, 0, 0, 0.08);
  }
`;

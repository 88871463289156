import React from "react";
import { styled } from "styled-components";

export interface ListItemTextItemType {
  title: string;
  value: string;
}
const ListTextItem: React.FC<ListItemTextItemType> = ({ title, value }) => {
  return (
    <StListTextItem>
      <span>{title} :</span> <span>{value}</span>
      {title === "Tester EnvStart Time" ? (
        <span
          style={{ color: "rgb(63, 107, 218)", fontSize: 12, paddingLeft: 5 }}
        >{`(UTC -0)`}</span>
      ) : null}
    </StListTextItem>
  );
};

const StListTextItem = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

export default ListTextItem;

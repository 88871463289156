import React, { useEffect } from "react";
import MarketingHeader from "../../CommonComponents/marketingHeader";
import { styled } from "styled-components";
import CancleConfirmModal from "./cancleConfirmModal";
import apis from "../../../../api/getApi";
import downRightIcon from "../../../../img/down-right.png";

interface ListItemType {
  credits: number;
  enterpriseName: string;
  hostEmail: string;
  restCredits: number;
  rewardDate: string;
  rewardTradeId: number;
}
export interface SelectiedDataType {
  company: string;
  credit: number;
  hostEmail: string;
  rewardTradeId: number;
}

const CreditProvideList: React.FC = () => {
  const [creditList, setCreditList] = React.useState<ListItemType[]>([]);
  console.log(creditList);
  const [selectedData, setSelectiedData] = React.useState<SelectiedDataType>({
    company: "",
    credit: 0,
    hostEmail: "",
    rewardTradeId: 0,
  });
  const [confirmModalOn, setConfirmModalOn] = React.useState<boolean>(false);
  const [focusedItem, setFocusedItem] = React.useState<number | null>(null);
  const cancleConfirmOn = (
    company: string,
    credit: number,
    hostEmail: string,
    rewardTradeId: number
  ) => {
    setSelectiedData({ company, credit, hostEmail, rewardTradeId });
    setConfirmModalOn(true);
  };
  const cancleConfirmOff = () => {
    setSelectiedData({
      company: "",
      credit: 0,
      hostEmail: "",
      rewardTradeId: 0,
    });
    setConfirmModalOn(false);
  };

  const getCreditList = async () => {
    try {
      const { data } = await apis.getCreditList();

      if (data.isSuccess) {
        console.log(data.rewardCredits);
        setCreditList(data.rewardCredits);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onClickCancleCredit = async (
    hostEmail: string,
    rewardTradeId: number
  ) => {
    const { data } = await apis.postCreditCancle({ hostEmail, rewardTradeId });

    if (data.isSuccess) {
      cancleConfirmOff();
      getCreditList();
    }
  };
  useEffect(() => {
    getCreditList();
  }, []);
  const TableRowItem: React.FC<ListItemType> = ({
    credits,
    enterpriseName,
    hostEmail,
    restCredits,
    rewardDate,
    rewardTradeId,
  }) => (
    <StTableRowItem
      onClick={() => setFocusedItem(rewardTradeId)}
      // height={`${focusedItem === rewardTradeId ? "auto" : "200px"}`}
    >
      <div className={`contentBox`}>
        <div className="mainContent">
          <p>{hostEmail}</p>
          <p>{enterpriseName}</p>
          <p>{credits}</p>
          <p>{restCredits}</p>
          <p>{rewardDate}</p>
          <p style={{ justifyContent: "center", display: "flex" }}>
            {restCredits > 0 ? (
              <button
                style={{ width: 100, height: 30 }}
                onClick={() =>
                  cancleConfirmOn(
                    enterpriseName,
                    restCredits,
                    hostEmail,
                    rewardTradeId
                  )
                }
              >
                회수
              </button>
            ) : null}
          </p>
        </div>
      </div>
    </StTableRowItem>
  );

  return (
    <div style={{ padding: "20px" }}>
      <MarketingHeader title="적립금 지급 목록" />
      <StTableRowItem>
        <div className="contentBox titlebox">
          <p>기업 이메일.</p>
          <p>기업명.</p>
          <p>첫 지급액.</p>
          <p>남은 적립금.</p>
          <p>지급일.</p>
        </div>
      </StTableRowItem>
      <div
        style={{ height: "70vh", overflow: "scroll", scrollbarWidth: "none" }}
      >
        {creditList.map((item, idx) => (
          <TableRowItem key={idx} {...item} />
        ))}
      </div>
      {confirmModalOn ? (
        <CancleConfirmModal
          company={selectedData.company}
          credit={selectedData.credit}
          hostEmail={selectedData.hostEmail}
          rewardTradeId={selectedData.rewardTradeId}
          modalCloseFc={cancleConfirmOff}
          creditCancleFc={onClickCancleCredit}
        />
      ) : null}
    </div>
  );
};

export default CreditProvideList;

const StTableRowItem = styled.section<{ height?: string }>`
  .contentBox {
    border-bottom: 1px solid #e4e4e5;
    overflow: hidden;
    transition: all 5s;
  }
  .contentBox:not(.titlebox) {
    transition: all 5s;
    height: ${(props) => props.height};
    cursor: pointer;
  }
  /* .contentBox:not(.titlebox):hover {
    height: 200px;
  } */
  .contentBox p {
    max-width: 1000px;
  }

  .contentBox > .mainContent > p {
    padding: 25px 20px;
    display: flex;
    align-items: center;
  }
  .contentBox > .subContent {
    margin: 0 30px;
    border-top: 0.5px dashed #eeeeee;
    padding: 20px 50px;
    display: flex;
    flex-direction: row;
  }
  .contentBox > .subContent > div {
    div {
      display: flex;
      flex-direction: row;
    }
    p {
      padding: 10px;
    }
    span {
      font-family: "Regular";
    }
    span:first-child {
      font-size: 13px;
      color: #636363;
      padding-right: 10px;
    }
  }

  .contentBox.titlebox p {
    background-color: #f7f9ff;
    padding: 10px 20px;
  }
  .contentBox.titlebox {
    border-bottom: 1px solid #e4e4e5;
  }
  div.contentBox > .mainContent,
  .contentBox.titlebox {
    display: grid;
    grid-template-columns: 300px 1fr 200px 200px 200px 200px;
    /* border-bottom: 1px solid #e4e4e5; */
  }

  .contentBox.titlebox > p:not(:nth-child(2)) {
    text-align: center;
  }
  div.contentBox > .mainContent > p:not(:nth-child(2)) {
    margin: auto;
  }
`;

const CancleButton = styled.button`
  height: 30px;
  width: 100px;
  border: 1px solid #f7f9ff;
  border-radius: 3px;
  background-color: #d11c1c;
  color: #fff;
  box-shadow: 1px 1px 3px #8282823b;
  cursor: pointer;
  font-size: 13px;
  &:hover {
    opacity: 0.6;
    margin-top: 2px;
    margin-bottom: -2px;
  }
`;

const Cancle = styled.span`
  height: 30px;
  width: 100px;
  border-radius: 3px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d11c1c;
  border: 1px solid #d11c1c;
`;

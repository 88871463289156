import { styled } from "styled-components";
import { SelectiedDataType } from ".";

interface CancleConfirmModalType {
  company: string;
  credit: number;
  modalCloseFc: () => void;
  creditCancleFc: (hostEmail: string, rewardTradeId: number) => Promise<void>;
  hostEmail: string;
  rewardTradeId: number;
}

const CancleConfirmModal: React.FC<CancleConfirmModalType> = ({
  company,
  credit,
  modalCloseFc,
  creditCancleFc,
  hostEmail,
  rewardTradeId,
}) => {
  return (
    <CancleConfirmModalWrap>
      <CancleConfirmModalContent>
        <div>
          <p>
            <span>{company}</span>에 지급한 <span>{credit}적립금</span>을 정말
            회수하시겠습니까?
          </p>
          <p> 이미 사용된 적립금은 회수되지 않습니다.</p>
        </div>
        <ButtonWrap>
          <CancleConfirmButton
            onClick={() => creditCancleFc(hostEmail, rewardTradeId)}
          >
            네
          </CancleConfirmButton>
          <CancleConfirmButton onClick={modalCloseFc}>
            아니요
          </CancleConfirmButton>
        </ButtonWrap>
      </CancleConfirmModalContent>
    </CancleConfirmModalWrap>
  );
};

export default CancleConfirmModal;

const CancleConfirmModalWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000025;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CancleConfirmModalContent = styled.div`
  padding: 50px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 1px 3px 5px 0px #1220425e;
  width: 900px;
  position: relative;
  p:nth-child(1) {
    font-size: 20px;
    span:first-child {
      color: #123ca1;
    }
    span:last-child {
      font-weight: 600;
    }
  }
  p:nth-child(2) {
    margin-top: 20px;
    font-size: 15px;
    color: #56585e;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 15px;
  }
  button:nth-child(1) {
    background-color: #ea3636;
    color: #fff;
  }
  button:nth-child(2) {
    background-color: #ccd1db;
    color: #fff;
  }
`;

const CancleConfirmButton = styled.button`
  height: 40px;
  width: 100px;
  border: 1px solid #e5e8f0;
  border-radius: 3px;
  cursor: pointer;
  font-size: 13px;
  &:hover {
    box-shadow: none;
    color: #ebeef5;
  }
`;

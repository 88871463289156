import React, { useState } from "react";
import { styled } from "styled-components";

interface HeaderProp {
  text: string;
  path: string;
  state: number;
  onClick: React.Dispatch<React.SetStateAction<number>>;
  part: number;
}

interface HeaderStyle {
  part: number;
  state: number;
  path: string;
}

const HeaderDevider: React.FC<HeaderProp> = (props) => {
  return (
    <StDivider
      part={props.part}
      state={props.state}
      path={props.path}
      onClick={() => {
        props.onClick(props.state);
      }}
    >
      {props.text}
    </StDivider>
  );
};

const StDivider = styled.div<HeaderStyle>`
  font-size: 18px;
  font-family: "semibold";
  line-height: 24px;
  padding: 24px;
  border-bottom: ${(props) =>
    props.part === props.state ? "3px solid #1A75FF" : ""};
  cursor: pointer;
`;

export default HeaderDevider;

import React from "react";
import TableItem from "../molecule/TableItem";
import dayjs from "dayjs";
import { styled } from "styled-components";
import TextLine from "../atom/TextLine";
import { useNavigate } from "react-router-dom";

interface TableProps {
  headerList?: string[];
  items: { [key: string]: any }[] | undefined;
  nowPage?: number;
  onClick?: () => void;
}

const MemberTable: React.FC<TableProps> = (props) => {
  const navigate = useNavigate();
  return (
    <StTable>
      {props.headerList && <TableItem item={props?.headerList} use="header" />}
      {props.items?.length && props.items?.length > 0 ? (
        props.items?.map((value, index) => {
          return (
            <TableItem
              key={index}
              // item={Object.keys(value).map((item) => value[item])}
              item={[
                value.name,
                value.nickName,
                value.email ? value.email : "-",
                value.department,
                value.phoneNumber,
                dayjs(value.signUpDate).format("YYYY MM-DD HH:mm:ss"),
              ]}
              use="item"
              ent="edint"
              onClick={() => navigate(`/common/master/member/${value.uuid}`)}
            />
          );
        })
      ) : (
        <div className="noList">
          <TextLine
            text="검색 결과가 없습니다... 그건 제 잔상입니다만...?"
            fontSize={25}
            fontcolor="#888888"
          />
        </div>
      )}
    </StTable>
  );
};

const StTable = styled.div`
  .noList {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
  }
`;

export default MemberTable;

import styled from "styled-components";
import { IQuestionDetail } from "../interface/i.question";
import QuestionDetailImage from "./QuestionDetailImage";

const QuestionDetailContents = ({
  questionDetail,
}: {
  questionDetail: IQuestionDetail;
}) => {
  return (
    <StyledDetailContents>
      <div className="context">
        {questionDetail.component[0].questionComponentContext}
      </div>
      <QuestionDetailImage
        questionImage={questionDetail.component[0].signedImageList}
      ></QuestionDetailImage>
    </StyledDetailContents>
  );
};

/**
 * component => 배열
 * component의 signedImage도 배열
 */
export default QuestionDetailContents;

const StyledDetailContents = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
  justify-content: space-between;
  width: 1030px;
  height: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 6px;
  border-radius: 12px;
  border: 0.5px solid gray;
  padding: 10px 10px;
  font-family: "semibold";
  position: relative;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  ChartOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import customAxios from "../../../api/handler";

export const options = {
  indexAxis: "y" as const,
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  responsive: true,
  plugins: {},
};

const Button = styled.button`
  background-color: #f5f5f5;
  color: #000;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  padding: 5px;
  cursor: pointer;
  height: 30px;
`;

const Container = styled.div`
  width: 100%;
  margin: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const MainContainer = styled.div`
  padding: 15px;
  width: 90%;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div`
  margin-top: 20%;
  margin-left: 40%;
  border: 30px solid rgba(0, 0, 0, 0.1);
  border-top: 30px solid #0099ff;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ChartView = () => {
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const today = new Date();
  const sDay = new Date();

  sDay.setDate(sDay.getDate() - 90);
  const [startDate, setStartDate] = useState<Date | null>(sDay);
  const [endDate, setEndDate] = useState<Date | null>(today);
  const [activeButtonIndex, setActiveButtonIndex] = useState<number>(0);

  ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

  const [originChartData, setOriginChartData] = useState([
    {
      device_model: "iPhone 16",
      device_os_system: "16.6.1",
      device_os_version: "ios",
      count: 50,
      bgColor: "rgba(255, 0, 0, 0.7)",
    },
    {
      device_model: "SM-M930",
      device_os_system: "11",
      device_os_version: "Android",
      count: 20,
      bgColor: "rgba(0, 38, 255, 0.7)",
    },
    {
      device_model: "SM-M910",
      device_os_system: "10",
      device_os_version: "Android",
      count: 30,
      bgColor: "rgba(212, 255, 0, 0.7)",
    },
    {
      device_model: "SM-M880",
      device_os_system: "11.0",
      device_os_version: "Android",
      count: 20,
      bgColor: "rgba(227, 13, 250, 0.7)",
    },
    {
      device_model: "iPhone 12 mini",
      device_os_system: "13.1",
      device_os_version: "ios",
      count: 40,
      bgColor: "rgba(0, 247, 255, 0.7)",
    },
  ]);

  const [chartData, setChartData] = useState(originChartData);

  const [data, setData] = useState({
    labels: chartData.map(
      (item) =>
        `${item.device_model} ${item.device_os_system} ${item.device_os_version}`
    ),
    datasets: [
      {
        label: "이용자수",
        data: chartData.map((item) => item.count),
        //backgroundColor: "rgba(255, 0, 0, 0.7)",
        backgroundColor: chartData.map((item) => item.bgColor),
      },
    ],
  });

  useEffect(() => {
    setIsLoading(true);
    customAxios
      .get(`/admin/proc/devices`)
      .then(({ data }) => {
        setIsLoading(false);
        setOriginChartData(data.response);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    customAxios
      .get(`/admin/proc/devices/${startDate}/${endDate}`)
      .then(({ data }) => {
        setIsLoading(false);
        setOriginChartData(data.response);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [startDate, endDate]);

  useEffect(() => {
    setData({
      labels: chartData.map(
        (item) =>
          `${item.device_model} ${item.device_os_system} ${item.device_os_version}`
      ),
      datasets: [
        {
          label: "이용자수",
          data: chartData.map((item) => item.count),
          backgroundColor: chartData.map((item) => item.bgColor),
        },
      ],
    });
  }, [chartData, originChartData]);

  useEffect(() => {
    switch (activeButtonIndex) {
      case 0:
        const sortedData0 = originChartData
          .slice()
          .sort((a, b) => b.count - a.count);
        setChartData(sortedData0);
        break;
      case 1:
        const sortedData1 = originChartData
          .slice()
          .sort((a, b) => b.count - a.count);
        setChartData(sortedData1);
        break;
      case 2:
        const sortedData2 = originChartData
          .slice()
          .sort((a, b) => a.count - b.count);
        setChartData(sortedData2);
        break;
      case 3:
        const sortedData3 = originChartData.filter(
          (item) => item.device_os_system === "Android"
        );
        sortedData3.sort((a, b) => {
          const osA = a.device_os_version.split(".").map(Number);
          const osB = b.device_os_version.split(".").map(Number);

          for (let i = 0; i < Math.max(osA.length, osB.length); i++) {
            const numA = osA[i] || 0;
            const numB = osB[i] || 0;

            if (numA !== numB) {
              return numB - numA;
            }
          }
          return 0;
        });
        setChartData(sortedData3);
        break;
      case 4:
        const sortedData4 = originChartData.filter(
          (item) => item.device_os_system === "Android"
        );
        sortedData4.sort((a, b) => {
          const osA = a.device_os_version.split(".").map(Number);
          const osB = b.device_os_version.split(".").map(Number);

          for (let i = 0; i < Math.max(osA.length, osB.length); i++) {
            const numA = osA[i] || 0;
            const numB = osB[i] || 0;

            if (numA !== numB) {
              return numA - numB;
            }
          }
          return 0;
        });
        setChartData(sortedData4);
        break;
      case 5:
        const sortedData5 = originChartData.filter(
          (item) => item.device_os_system === "iOS"
        );
        sortedData5.sort((a, b) => {
          const osA = a.device_os_version.split(".").map(Number);
          const osB = b.device_os_version.split(".").map(Number);

          for (let i = 0; i < Math.max(osA.length, osB.length); i++) {
            const numA = osA[i] || 0;
            const numB = osB[i] || 0;

            if (numA !== numB) {
              return numB - numA;
            }
          }
          return 0;
        });
        setChartData(sortedData5);
        break;
      case 6:
        const sortedData6 = originChartData.filter(
          (item) => item.device_os_system === "iOS"
        );
        sortedData6.sort((a, b) => {
          const osA = a.device_os_version.split(".").map(Number);
          const osB = b.device_os_version.split(".").map(Number);

          for (let i = 0; i < Math.max(osA.length, osB.length); i++) {
            const numA = osA[i] || 0;
            const numB = osB[i] || 0;

            if (numA !== numB) {
              return numA - numB;
            }
          }
          return 0;
        });
        setChartData(sortedData6);
        break;
      default:
    }
  }, [activeButtonIndex, originChartData]);

  const handleButtonClick = (index: number) => {
    setActiveButtonIndex(index);
  };

  const buttons = [
    { text: "전체", index: 0 },
    { text: "이용자↑", index: 1 },
    { text: "이용자↓", index: 2 },
    { text: "Android OS↑", index: 3 },
    { text: "Android OS↓", index: 4 },
    { text: "ios OS↑", index: 5 },
    { text: "ios OS↓", index: 6 },
  ];

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <MainContainer>
      <Container>
        {buttons.map((button, index) => (
          <Button
            key={index}
            onClick={() => handleButtonClick(index)}
            style={{
              backgroundColor:
                activeButtonIndex === index ? "#dfdfdf" : "#fcfcfc",
            }}
          >
            {button.text}
          </Button>
        ))}
      </Container>
      <Container>
        <DatePicker
          selected={startDate}
          dateFormat="yyyy/MM/dd"
          onChange={(date) => setStartDate(date)}
          placeholderText="Select a date"
          maxDate={today}
        />
        <p> ~ </p>
        <DatePicker
          selected={endDate}
          dateFormat="yyyy/MM/dd"
          onChange={(date) => setEndDate(date)}
          placeholderText="Select a date"
          minDate={startDate}
          maxDate={today}
        />
        <br />
      </Container>
      <Bar options={options} data={data} />
    </MainContainer>
  );
};

export default ChartView;

import React, { useEffect, useRef, useState } from "react";
import MarketingHeader from "../../CommonComponents/marketingHeader";
import { styled } from "styled-components";
import apis from "../../../../api/getApi";
import { CloseButton } from "../../Coupon/CouponList/couponMakeModal";
import { useNavigate } from "react-router-dom";
import checkbox_0 from "../../../../img/checkbox_0.png";
import checkbox_1 from "../../../../img/checkbox_1.png";
import useScrollLock from "../../../../hook/useScrollLock";

interface FocusItemType {
  enterpriseName: string;
  email: string;
  uuid: string;
}

const CreditProvide = () => {
  const navigate = useNavigate();
  const [comments, setComments] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [focusItem, setFocusItem] = React.useState<FocusItemType | null>(null);
  const [placeholder, setPlaceHolder] = useState<string>("");
  const [listItemData, setListItemData] = useState<FocusItemType[] | null>(
    null
  );
  const [amount, setAmount] = useState<string>("0");
  const [creditProvideConfirmOpen, setCreditProvideConfirmOpen] =
    useState<boolean>(false);
  const [creditProvideConfirmCheck, setCreditProvideConfirmCheck] =
    useState<boolean>(false);
  const [creditProvideConfirmCheckInfo, setCreditProvideConfirmCheckInfo] =
    useState<boolean>(false);

  const searchCompany = async (e: React.FormEvent) => {
    e.preventDefault();
    if (companyName) {
      try {
        const { data } = await apis.getEnterprise(companyName);
        setListItemData(data.managers);
      } catch (e) {
        console.log(e);
      }
    } else {
      setPlaceHolder("기업명을 입력해주세요.");
    }
  };
  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
  };

  const onChangeAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setAmount(Number(inputValue).toString());
  };
  const creditProvide = async () => {
    try {
      const { data } = await apis.postCreditProvide({
        hostUuid: focusItem?.uuid,
        credits: Number(amount),
        comments,
      });
      if (data.isSuccess) {
        setFocusItem(null);
        navigate("/marketing/creditProvideList");
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onClickProvide = () => {
    if (creditProvideConfirmCheck) {
      creditProvide();
    } else {
      setCreditProvideConfirmCheckInfo(true);
    }
  };
  useEffect(() => {
    setPlaceHolder("");
  }, [companyName]);

  useEffect(() => {
    if (!focusItem) {
      setCreditProvideConfirmOpen(false);
      setCreditProvideConfirmCheck(false);
      setCreditProvideConfirmCheckInfo(false);
      setAmount("0");
    }
  }, [focusItem]);
  useEffect(() => {
    if (creditProvideConfirmCheck) {
      setCreditProvideConfirmCheckInfo(false);
    }
  }, [creditProvideConfirmCheck]);

  // useEffect(() => {
  //   if (!creditProvideConfirmOpen) {
  //     if (creditProvideConfirmCheck) {
  //       setCreditProvideConfirmCheck(false);
  //     }
  //     if (creditProvideConfirmCheckInfo) {
  //       setCreditProvideConfirmCheckInfo(false);
  //     }
  //   }
  // }, [creditProvideConfirmOpen]);
  // // console.log(creditProvideConfirmCheck);
  const checkBoxRef2 = useRef(null);
  // useRootScrollLock();
  useScrollLock();
  useEffect(() => {
    if (checkBoxRef2.current) {
      console.log(checkBoxRef2?.current);
    }
  }, [checkBoxRef2.current]);

  return (
    <form
      onSubmit={searchCompany}
      style={{
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <MarketingHeader title="적립금 지급" />
      <TitleContent>
        <p></p>
        <p>기업명.</p>
        <p>계정.</p>
        <SearchBox>
          <input
            type="text"
            className="marketing_input"
            onChange={onChangeSearchInput}
            value={companyName}
            placeholder={placeholder}
          />
          <button>기업 검색</button>
        </SearchBox>
      </TitleContent>
      {listItemData?.map((item, idx) => {
        return (
          <ContentItemRow
            key={idx}
            onClick={() => {
              if (!focusItem) {
                setFocusItem(item);
              } else {
                setFocusItem(null);
              }
            }}
          >
            <div>
              <input
                type="checkbox"
                checked={item.uuid === focusItem?.uuid}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFocusItem(item);
                  } else {
                    setFocusItem(null);
                  }
                }}
              />
            </div>
            <p>{item.email}</p>
            <p>{item.enterpriseName}</p>
          </ContentItemRow>
        );
      })}
      <SlideContentWrap focusitem={focusItem ? "true" : "false"}>
        <section>
          {creditProvideConfirmOpen ? (
            <div className="confirmBox">
              <div>
                <p>
                  기업 <span>{focusItem?.enterpriseName}</span>에
                </p>
                <p>
                  적립금 <span>{amount}</span>원을 지급합니다.
                </p>
              </div>

              <ConfirmCheckBox
                onClick={() => setCreditProvideConfirmCheck((prev) => !prev)}
              >
                {creditProvideConfirmCheck ? (
                  <img src={checkbox_1} alt="" width={25} height={25} />
                ) : (
                  <img src={checkbox_0} alt="" width={25} height={25} />
                )}
                <p>기업과 지급액을 확인하고 진행합니다.</p>
              </ConfirmCheckBox>

              <div>
                <button onClick={onClickProvide}>지급</button>
                <button
                  onClick={() => {
                    setCreditProvideConfirmCheck(false);
                    setCreditProvideConfirmCheckInfo(false);
                    setCreditProvideConfirmOpen(false);
                  }}
                >
                  취소
                </button>
              </div>
              {creditProvideConfirmCheckInfo ? (
                <div>체크박스를 확인해주세요</div>
              ) : null}
            </div>
          ) : (
            <div className="contentBox">
              <div>
                <span>{focusItem?.enterpriseName}</span>
              </div>
              <div>
                <input
                  id={"amount"}
                  type="number"
                  value={Number(amount).toString()} // Convert to a num #ec6022 ber and back to string to ensure no leading zero
                  className="marketing_input"
                  onChange={onChangeAmountChange}
                  min={0}
                />
              </div>
              <div>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#71747c",
                    fontFamily: "Regular",
                    padding: "10px 0",
                  }}
                >
                  비고
                </p>
                <textarea
                  style={{
                    width: "100%",
                    height: "50px",
                    border: "1px solid #bdc2d0",
                    outline: "none",
                    resize: "none",
                    boxSizing: "border-box",
                  }}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setComments(e.target?.value)
                  }
                />
              </div>

              <div>
                <button
                  onClick={() => setCreditProvideConfirmOpen(true)}
                  disabled={Number(amount) <= 0}
                >
                  지급하기
                </button>
              </div>
            </div>
          )}
          <CloseButton onClick={() => setFocusItem(null)}>닫기</CloseButton>
        </section>
      </SlideContentWrap>
    </form>
  );
};

export default CreditProvide;

export const TitleContent = styled.div`
  display: grid;
  grid-template-columns: 50px 350px 350px 1fr;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #babfc9;
  background-color: #f7f9ff;
  p {
    border-right: 1px solid #15254e;
    padding-left: 15px;
  }
  button {
    margin-left: 15px;
    width: 100px;
    border: none;
    border-radius: 3px;
    background-color: #15254e;
    cursor: pointer;
    color: #fff;
  }

  input {
    height: 20px;
    font-size: 16px;
    background-color: #fff;
    border: 1px solid #15254e;
  }
`;

export const SearchBox = styled.div`
  display: flex;
  padding-left: 400px;
`;

export const ContentItemRow = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 50px 350px 350px 1fr;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #babfc9;

  p {
    padding-left: 15px;
  }
  input[type="checkbox"] {
    width: 16px;
    height: 16px;
  }
`;

const SlideContentWrap = styled.div<{ focusitem: string }>`
  position: absolute;
  top: 100px;
  right: 0px;
  width: 400px;
  border-radius: 8px;
  background-color: #fff;
  width: 400px;
  box-shadow: 5px 5px 12px 1px #15254e3b;
  border: 3px solid #ec6022;
  transform: ${(props) =>
    JSON.parse(props.focusitem)
      ? "translate3d(-100px, 0, 0)"
      : "translate3d(500px, 0, 0)"};
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  display: flex;
  justify-content: center;
  align-items: center;
  section {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    .contentBox,
    .confirmBox {
      width: 80%;
      padding: 50px 0;
      font-family: "Regular";
    }
    .contentBox {
      div:first-child {
        border-bottom: 1px solid #bdc2d0;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-family: "semiBold";
        font-size: 20px;
      }
      div:first-child:before {
        content: "지급 기업";
        position: absolute;
        left: 0;
        bottom: 10px;
        font-size: 12px;
        color: #71747c;
        font-family: "Regular";
      }

      div:nth-child(2) {
        padding: 29px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #bdc2d0;
        input {
          border: 1px solid #bdc2d0;
        }
        input::-webkit-inner-spin-button {
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
        }
      }
      div:nth-child(2):after {
        content: "원 지급";
        font-size: 14px;
        color: #4a4c51;
        font-family: "Regular";
        padding-left: 10px;
      }
      div:last-child {
        margin-top: 10px;
        text-align: center;

        p {
          font-family: "Regular";
          color: #444444;
          font-size: 14px;
          margin: 20px;
        }
        button {
          width: 100%;
          padding: 10px;
          border: none;
          border-radius: 3px;
          background-color: #ec6022;
          cursor: pointer;
          color: #fff;
        }
        button:disabled {
          opacity: 0.3;
          cursor: auto;
        }
        /* border-top: 1px dashed red; */
      }
    }

    .confirmBox {
      font-size: 18px;
      color: #19191a;
      line-height: 27px;
      text-align: center;
      div:first-child {
        border-bottom: 1px solid #bdc2d0;
        padding-bottom: 20px;
        span {
          font-size: 22px;
          font-family: "semiBold";
          color: #ec6022;
        }
      }
      div:nth-child(2) {
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #38393b;
      }
      div:nth-child(3) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        padding-top: 10px;
        button {
          width: 100%;
          padding: 10px;
          border: none;
          border-radius: 3px;
          cursor: pointer;
          color: #fff;
        }
        button:first-child {
          background-color: #ec6022;
        }
        button:last-child {
          background-color: #3f4659;
        }
      }
      div:last-child {
        font-size: 14px;
        color: #db2121;
        padding-top: 10px;
      }
    }

    input {
      height: 25px;
      font-size: 16px;
      background-color: #fff;
      border: 1px solid #15254e;
    }
  }
`;

const ConfirmCheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  border-bottom: none;
`;
const TriangleBox = styled.div`
  position: absolute;
  bottom: -27px;
  width: 400px;
  display: flex;
  overflow: hidden;
`;
const Triangle = styled.div<{ left: number }>`
  /* left: ${(props) => `${props.left}px`};
  bottom: -50px; */
  width: 0;
  height: 0;
  border-bottom: 15px solid transparent;
  border-top: 15px solid #f4f5f6;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
`;

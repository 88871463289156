import React from "react";
import { styled } from "styled-components";
import UserListTemplate from "../../atomic/template/UserListTemplate";

const UserList = () => {
  return (
    <StTemplate>
      <UserListTemplate />
    </StTemplate>
  );
};

const StTemplate = styled.div`
  padding: 48px;
`;

export default UserList;

import { styled } from "styled-components";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { INoticeParentProps } from "../NoticeList/interface/i.notice";
import customAxios from "../../api/handler";
import { confirmKeyList } from "../../atom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

const NoticePostHeader = ({ props }: { props: INoticeParentProps }) => {
  const { notice } = props;
  const navigate = useNavigate();
  const finalCategory = props.category;
  const finalIsImportant = props.isImportant;
  const finalExposeServer = props.exposeServer;

  const [confirmImageS3KeyList, setConfirmImageS3KeyList] =
    useRecoilState(confirmKeyList);
    
  const keyList = confirmImageS3KeyList.map((v, idx) => {
    return v.key;
  });
  useEffect(() => {
    console.log();
    
  }, [confirmKeyList, confirmImageS3KeyList, notice, finalCategory, finalIsImportant, finalExposeServer]);
  
  const submitHandler = async () => {

    await customAxios
      .patch(`/admin/proc/notices/completion`, {
        noticeId: +notice.noticeId,
        category: finalCategory,
        context: notice.context,
        title: notice.title,
        confirmImageS3KeyList: keyList,
        isImportant: finalIsImportant,
        region: finalExposeServer
      })
      .then((res) => {
        console.log(res);

        noticeList(navigate);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const navigateNoticeList = () => {
    noticeList(navigate);
  }

  return (
    <StyledNoticePostHeader>
      <div className="headers">
        <div className="noticeTitle">공지사항 작성 (이미지는 업로드는 하단의 Upload를 통해 진행)</div>
      </div>
      <div className="noticeButton">
        <button className="cancelButton" onClick={navigateNoticeList}>취소</button>
        <button type="submit" className="saveButton" onClick={submitHandler}>
          저장
        </button>
      </div>
    </StyledNoticePostHeader>
  );
};

export default NoticePostHeader;

export const StyledNoticePostHeader = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  width: 84%;

  .noticeTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 40px;
    width: 800px;
    gap: 10px;

    font-family: "semibold";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;

    .discription {
      margin-top: 8px;
      font-size: 14px;
      color: rgba(128, 128, 128, 0.7);
    }
  }

  .noticeButton {
    width: 450px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 30px;

    .cancelButton,
    .saveButton {
      display: inline-block;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;

      font-family: "semibold";
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      line-height: 20px; /* 125% */
      cursor: pointer;
    }

    .cancelButton {
      border: 1px solid var(--main-blue, #1a75ff);
      background: var(--gray-white, #fff);
      white-space: nowrap;

      color: var(--main-blue, #1a75ff);
      text-align: center;
      cursor: pointer;
    }

    .saveButton {
      background-color: #1a75ff;
      border: white;

      color: var(--gray-white, #fff);
      text-align: center;
    }
  }
`;

const noticeList = (navigate: NavigateFunction) => {
  navigate(`/common/noticeList`);
};

import React from "react";
import LogoImg from "../atom/LogoImg";
import HeaderButtons from "../molecule/HeaderButtons";
import { styled } from "styled-components";
import { getCookie, removeCookie } from "../../Cookie";
import { useNavigate } from "react-router-dom";

interface headerProp {
  setPart: React.Dispatch<React.SetStateAction<number>>;
  part: number;
}

const Header: React.FC<headerProp> = (props) => {
  const navigate = useNavigate();

  return (
    <StHeader>
      <LogoImg width={238} height={36} onclick={props.setPart} />
      <HeaderButtons setPart={props.setPart} part={props.part} />
      {getCookie("refreshToken") ? (
        <div
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={() => {
            removeCookie("refreshToken");
            removeCookie("accessToken");
            navigate(`/login`);
          }}
        >
          로그아웃
        </div>
      ) : (
        <div />
      )}
    </StHeader>
  );
};

const StHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  align-items: center;
  padding: 0px 32px;
  height: 75px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
`;

export default Header;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NoticeHeader from "./NoticeBoard/NoticeHeader";
import NoticeBoard from "./NoticeBoard/NoticeBoard";
import { styled } from "styled-components";
import customAxios from "../../api/handler";
import { useLocation } from "react-router";
import {
  INotice,
  INoticeCountProps,
  INoticeListProps,
} from "./interface/i.notice";

/** 메인틀 */
const NoticeList = () => {
  const { search } = useLocation();

  const [noticeList, setNoticeList] = useState<Array<INotice>>(
    new Array<INotice>()
  );
  const [pageShowCount, setPageShowCount] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const pageCount = 15;

  let pageList = [];

  /** 공지사항 목록 받아오기 */
  const getNoticeList = async () => {
    await customAxios
      .get(`/admin/proc/notices?page=${page}&pageCount=${pageCount}&platform=web`)
      .then(({ data }) => {
        const { noticeList } = data as INoticeListProps;

        setNoticeList(noticeList);
      })
      .catch(() => {});
  };

  /** 공지사항 전체 목록 받아오기 */
  const getNoticeCount = async () => {
    await customAxios.get(`/admin/proc/notices/count`).then(({ data }) => {
      const { noticeCount } = data as INoticeCountProps;

      setPageShowCount(parseInt(noticeCount) / 15 + 1);
    });
  };

  useEffect(() => {
    getNoticeCount();
  }, []);

  useEffect(() => {
    getNoticeList();
  }, [page]);

  for (let index = 1; index <= pageShowCount; index++) {
    pageList.push(index);
  }

  return (
    <StyledNoticeList>
      <NoticeHeader></NoticeHeader>
      <NoticeBoard noticeList={noticeList}></NoticeBoard>
      <div className="pageNumber">
        {pageList.map((v, idx) => {
          return (
            <button
              key={idx + 1}
              className="page"
              onClick={() => {
                setPage(v);
              }}
            >
              {v}
            </button>
          );
        })}
      </div>
    </StyledNoticeList>
  );
};

export default NoticeList;

const StyledNoticeList = styled.div`
  display: flex;
  width: 90%;
  height: 85%;
  padding: 48px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .pageNumber {
    width: 80%;
    height: auto;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    font-family: "semibold";

    .page {
      color: white;
      border-radius: 8px;
      background: var(--app-bgbg, #20315b);
    }
  }
`;

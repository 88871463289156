import React, { Dispatch, MutableRefObject, SetStateAction } from "react";
import MarketingHeader from "../../CommonComponents/marketingHeader";
import styled from "styled-components";
import PromotionInput from "../PromotionList/promotionInput";
import pencilIcon from "../../../../img/pencil.png";
import Editer from "../../CommonComponents/editer";
import ReactQuill from "react-quill";
import BannerPrevidewContainer from "../PromotionList/bannerPreviewContainer";
import apis from "../../../../api/getApi";
import { useNavigate } from "react-router-dom";

export interface PromotionDataType {
  title: string;
  bannerType: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  comments: string;
}
export interface ServerType {
  isKrServer: boolean;
  isGlServer: boolean;
}

export interface LinkType {
  kr_logoutLink: string;
  kr_loginLink: string;
  en_logoutLink: string;
  en_loginLink: string;
}

export interface PromotionBannerContentType {
  contentsA: string;
  contentsB1: string;
  contentsB2: string;
  contentsC: string;
}

const CreatePromotion: React.FC = () => {
  const navigate = useNavigate();

  const quillRef1 = React.useRef<ReactQuill | null>(null);
  const quillRef2 = React.useRef<ReactQuill | null>(null);
  const quillRef3 = React.useRef<ReactQuill | null>(null);
  const quillRef4 = React.useRef<ReactQuill | null>(null);
  const [error, setError] = React.useState<string>();
  const initialLink = {
    kr_logoutLink: "proctormaticSignup",
    kr_loginLink: "proctormaticMypage",
    en_logoutLink: "proctormaticSignup",
    en_loginLink: "proctormaticMypage",
  };
  const [defaultLink, setDefaultLLink] = React.useState<LinkType>(initialLink);
  const [customLink, setCustomLLink] = React.useState<LinkType>({
    kr_logoutLink: "",
    kr_loginLink: "",
    en_logoutLink: "",
    en_loginLink: "",
  });

  const [writeContentType, setWriteContentType] = React.useState<string>("");
  const [krPreviewOpen, setKrPreviewOpen] = React.useState<boolean>(false);
  const [enPreviewOpen, setEnPreviewOpen] = React.useState<boolean>(false);
  const [server, setServer] = React.useState<string>("kr");

  const [promotionData, setPromotionData] = React.useState<PromotionDataType>({
    title: "",
    bannerType: "topBanner",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    comments: "",
  });

  const [content, setContent] = React.useState<PromotionBannerContentType>({
    contentsA: "",
    contentsB1: "",
    contentsB2: "",
    contentsC: "",
  });
  // const [en_promotionBannerContent, setEn_PromotionBannerContent] =
  //   React.useState<PromotionBannerContentType>({
  //     contentsA: "",
  //     contentsB1: "",
  //     contentsB2: "",
  //     contentsC: "",
  //   });
  const [kr_selectedLink, setKr_selectedLink] = React.useState({
    login: "",
    logout: "",
  });
  const [en_selectedLink, setEn_selectedLink] = React.useState({
    login: "",
    logout: "",
  });

  const [selectedLinkOption, setSelectedLinkOption] = React.useState({
    kr_loginLink: "",
    kr_logoutLink: "",
    en_loginLink: "",
    en_logoutLink: "",
  });

  const pattern = /<[^>]*>([^<]+)<[^>]*>/i;

  const promotionInputPros = (
    type: string,
    dataKey: keyof PromotionDataType
  ) => {
    return {
      type: type,
      dataKey: dataKey,
      value: promotionData,
      setValue: setPromotionData,
    };
  };

  const promotionContentProps = (
    key: keyof PromotionBannerContentType,
    quillRef: MutableRefObject<ReactQuill | null>,
    bannerContent: PromotionBannerContentType,
    setBannerContent: Dispatch<SetStateAction<PromotionBannerContentType>>
  ) => {
    return {
      contentObj: bannerContent,
      contentObjKey: key,
      setTextContent: setBannerContent,
      quillRef,
    };
  };

  //노출 서버 선택
  const checkServer = (e: React.ChangeEvent) => {
    setServer(e.target.id);
  };

  //노출 형태 선택
  const checkBannerType = (e: React.ChangeEvent) => {
    const id = e.target.id;
    setPromotionData((prev) => ({ ...prev, bannerType: id }));
  };

  //비고
  const onChangeComments = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = e.target.value;
    setPromotionData((prev) => ({ ...prev, comments: val }));
  };

  console.log(selectedLinkOption);

  //링크
  const onChangeLink = (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target instanceof HTMLSelectElement) {
      //셀렉으로 들어온 값
      const selectId = e.target.id;
      const optionId = e.target.options[e.target.selectedIndex].id;
      const val = e.target.value;
      setSelectedLinkOption((prev) => ({ ...prev, [selectId]: val }));

      if (optionId === "defaultLink") {
        setDefaultLLink((prev) => ({ ...prev, [selectId]: val }));
      } else {
        setDefaultLLink((prev) => ({ ...prev, [selectId]: "notDefault" }));
      }
    } else {
      // input[text]으로 들어온 값
      const id = e.target.id;
      const val = e.target.value;
      setCustomLLink((prev) => ({ ...prev, [id]: val }));
    }
  };

  //생성버튼 클릭
  const onClickCreatePromotionSubmit = async () => {
    const payload = {
      title: promotionData.title,
      type: promotionData.bannerType,
      validityStartDate: `${promotionData.startDate} ${promotionData.startTime}`,
      validityEndDate: `${promotionData.endDate} ${promotionData.endTime}`,
      isKrServer: server === "kr",
      isGlServer: server === "en",
      contents: {
        a: content.contentsA,
        b1: content.contentsB1,
        b2: content.contentsB2,
        c: content.contentsC,
        link: {
          signUp:
            defaultLink.kr_logoutLink !== "notDefault"
              ? defaultLink.kr_logoutLink
              : customLink.kr_logoutLink,
          signIn:
            defaultLink.kr_loginLink !== "notDefault"
              ? defaultLink.kr_loginLink
              : customLink.kr_loginLink,
        },
      },

      comments: promotionData.comments,
    };

    let contentError = 0;
    Object.keys(promotionData).map((item) => {
      if (
        item !== "comments" &&
        !promotionData[item as keyof PromotionDataType]
      ) {
        // title, bannerType, startDate, startTime, endDate, endTime 값 확인
        contentError++;
      }
    });
    if (contentError > 0) {
      setError("누락된 값이 있는지 확인하세요.");
    } else {
      if (
        !content.contentsA.match(pattern) &&
        !content.contentsB1.match(pattern) &&
        !content.contentsB2.match(pattern) &&
        !content.contentsC.match(pattern)
      ) {
        setError("국문 컨텐츠에 하나 이상의 값이 필요해요.");
        return;
      } else if (
        content.contentsA.match(pattern) &&
        !content.contentsB1.match(pattern) &&
        !content.contentsB2.match(pattern)
      ) {
        setError("A영역을 사용하려면 B1 또는 B2의 값이 있어야해요.");
        return;
      } else if (
        (content.contentsB1.match(pattern) ||
          content.contentsB2.match(pattern)) &&
        !content.contentsC.match(pattern)
      ) {
        setError("B영역을 사용하려면 C의 값이 있어야해요.");
        return;
      }

      try {
        const { data } = await apis.postCreatePromotion(payload);
        if (data.isSuccess) {
          // modalClose();
          navigate("/marketing/promotion");
        } else {
          // setErrorText("알수없는 이유로 생성되지 않음");
          setError("프로모션 생성 에러입니다. 네트워크탭을 확인하세요");
        }
      } catch (e: any) {
        setError(e.data.errorMessage);
      }
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <MarketingHeader title="프로모션 생성" />
      <CreatePromotionContentBox>
        <div>
          <CreatePromotionSection>
            <h1>노출 서버</h1>
            <CheckBoxInputBox>
              <p>
                <input
                  type="radio"
                  id="kr"
                  name="server"
                  onChange={checkServer}
                />
                <label htmlFor="kr">한국</label>
              </p>
              <p>
                <input
                  type="radio"
                  id="en"
                  name="server"
                  onChange={checkServer}
                />
                <label htmlFor="en">그 외</label>
              </p>
            </CheckBoxInputBox>
          </CreatePromotionSection>
          <CreatePromotionSection>
            <h1>제목</h1>
            <PromotionTitleBox>
              <PromotionInput {...promotionInputPros("text", "title")} />
            </PromotionTitleBox>
          </CreatePromotionSection>

          <CreatePromotionSection>
            <h1>노출 형태</h1>
            <CheckBoxInputBox>
              <p>
                <input
                  type="checkbox"
                  id="topBanner"
                  // defaultChecked={true}
                  checked={promotionData.bannerType === "topBanner"}
                  onChange={checkBannerType}
                />
                <label htmlFor="topBanner">탑 배너</label>
              </p>
              <p>
                <input
                  type="checkbox"
                  id="popup"
                  disabled={true}
                  checked={promotionData.bannerType === "popup"}
                  onChange={checkBannerType}
                />
                <label htmlFor="popup">팝업</label>
              </p>
            </CheckBoxInputBox>
          </CreatePromotionSection>

          <CreatePromotionSection>
            <h1>유효 기간</h1>
            <DateBox>
              <p>
                <PromotionInput {...promotionInputPros("date", "startDate")} />
                <PromotionInput {...promotionInputPros("time", "startTime")} />
              </p>
              <span>~</span>
              <p>
                <PromotionInput {...promotionInputPros("date", "endDate")} />
                <PromotionInput {...promotionInputPros("time", "endTime")} />
              </p>
            </DateBox>
          </CreatePromotionSection>
          <CreatePromotionSection>
            <h1>비고</h1>
            <NotiBox>
              <textarea onChange={onChangeComments} />
            </NotiBox>
          </CreatePromotionSection>
        </div>
        <PromotionContentSection>
          <CreatePromotionSection>
            <h1>프로모션 콘탠츠 내용</h1>
            <PromotionContentBox>
              <section>
                <div className="poromotionContentInputBox">
                  <p>A</p>
                  <Editer
                    {...promotionContentProps(
                      "contentsA",
                      quillRef1,
                      content,
                      setContent
                    )}
                  />
                </div>
                <div className="poromotionContentInputBox">
                  <p>B-1</p>
                  <Editer
                    {...promotionContentProps(
                      "contentsB1",
                      quillRef2,
                      content,
                      setContent
                    )}
                  />
                </div>

                <div className="poromotionContentInputBox">
                  <p>B-2</p>

                  <Editer
                    {...promotionContentProps(
                      "contentsB2",
                      quillRef3,
                      content,
                      setContent
                    )}
                  />
                </div>

                <div className="poromotionContentInputBox">
                  <p>C</p>
                  <Editer
                    {...promotionContentProps(
                      "contentsC",
                      quillRef4,
                      content,
                      setContent
                    )}
                  />
                </div>
                <div className="poromotionContentInputBox">
                  <p className="linkTitle">Link (로그인 전)</p>
                  <p>
                    <select
                      onChange={onChangeLink}
                      id="kr_logoutLink"
                      value={selectedLinkOption.kr_logoutLink}
                    >
                      <option value="proctormaticSignup" id="defaultLink">
                        회원가입
                      </option>
                      <option id="direct">직접입력</option>
                    </select>
                    {defaultLink.kr_logoutLink === "notDefault" ? (
                      <input
                        type="text"
                        onChange={onChangeLink}
                        id="kr_logoutLink"
                        value={customLink.kr_logoutLink}
                      />
                    ) : null}
                  </p>
                </div>
                <div className="poromotionContentInputBox">
                  <p className="linkTitle">Link (로그인 후)</p>
                  <p>
                    <select
                      onChange={onChangeLink}
                      id="kr_loginLink"
                      value={selectedLinkOption.kr_loginLink}
                    >
                      <option value="proctormaticMypage" id="defaultLink">
                        마이페이지
                      </option>
                      <option id="direct">직접입력</option>
                    </select>
                    {defaultLink.kr_loginLink === "notDefault" ? (
                      <input
                        type="text"
                        id="kr_loginLink"
                        onChange={onChangeLink}
                        value={customLink.kr_loginLink}
                      />
                    ) : null}
                  </p>
                </div>
                <div className="previewBox">
                  <button onClick={() => setKrPreviewOpen(!krPreviewOpen)}>
                    미리보기 {krPreviewOpen ? "닫기" : "열기"}
                  </button>
                </div>
                {krPreviewOpen ? (
                  <BannerPrevidewContainer {...content} />
                ) : null}
              </section>
            </PromotionContentBox>
          </CreatePromotionSection>
        </PromotionContentSection>
      </CreatePromotionContentBox>
      <SubmitBox>
        <div>
          <div className="notiBox">
            <p>1. 꼭 모바일, 테블릿, PC버전을 모두 확인해주세요!</p>
            <p>
              2. 내용이 영역을 넘치게 되면 "..."으로 표시돼요 "..."가 나오지
              않도록 작성해주세요. <br />
              <span>
                만약 영역을 넘치게 되면 프록토매틱에 노출되는 배너가 깨지게
                돼요! :&#40;
              </span>
            </p>
          </div>
        </div>
        <div className="errorBox">
          <button onClick={onClickCreatePromotionSubmit}>생성</button>
          {error ? <p>{error}</p> : null}
        </div>
      </SubmitBox>
    </div>
  );
};

export default CreatePromotion;

const CreatePromotionContentBox = styled.div`
  display: grid;
  grid-template-columns: 700px auto;
  padding: 20px;
  border-bottom: 1px solid #babfc9;
`;
const CreatePromotionSection = styled.section`
  display: grid;
  padding-bottom: 10px;
  /* grid-template-columns: 200px auto; */
  /* border-bottom: 1px solid #dfe0e1; */

  h1 {
    padding: 10px 20px 5px;
    font-size: 14px;
    color: gray;

    /* background-color: #f7f9ff; */
  }
  div {
    padding: 10px 20px;
    display: flex;
  }
`;

const CheckBoxInputBox = styled.div`
  p {
    padding-right: 10px;
  }
`;

const PromotionTitleBox = styled.div`
  input[type="text"] {
    outline: none;
    border: none;
    /* background-color: red; */
    /* padding: 10px; */
    font-size: 18px;
    border: 1px solid #cccfd8;
    width: 618px;
    background-color: #fff;
  }
  input[type="text"]:focus {
    border: 1px solid #15254e;
  }
`;

const DateBox = styled.div`
  display: flex;
  /* justify-content: center; */
  span {
    padding: 5px;
  }
  align-items: center;
  input:nth-child(odd) {
    margin-right: 10px;
  }
`;

const NotiBox = styled.div`
  textarea {
    padding: 10px;
    width: 618px;
    height: 100px !important;
    /* box-sizing: border-box; */
    outline: none;
    border: 1px solid #bababa;
    resize: none;
  }
`;

const PromotionContentSection = styled.div`
  display: flex;
  flex-direction: column;
  p.contentLanTitle {
    cursor: pointer;
    box-sizing: border-box;
    font-size: 15px;
    width: 100%;
    border-radius: 3px;
    background-color: #e6effb;
    padding: 10px;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
  }
  p.contentLanTitle.selected {
    background-color: #15254e;
    color: #fff !important;
  }
  img {
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
  div {
    padding: 0;
  }
`;

const PromotionContentBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px !important;
  section {
    padding: 0 20px;
  }
  section div.poromotionContentInputBox {
    display: grid;
    grid-template-columns: 50px 80px auto;
    /* justify-content: center; */
    align-items: center;
    padding-bottom: 10px;
    div {
      width: 100%;
      grid-column: 2/-1;
    }
    select {
      padding: 5px 15px;
    }
    input {
      border: 1px solid red;
      outline: none;
      padding: 3px;
      font-size: 18px;
      border: 1px solid #cccfd8;
      margin-left: 10px;
    }
  }
  div.previewBox {
    display: flex;
    justify-content: flex-end;
  }
  div.previewBox button {
    margin-bottom: 80px;
    padding: 10px 20px;
  }
  .linkTitle {
    grid-column: 1/3;
  }
`;

const SubmitBox = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  .notiBox {
    font-size: 15px;
    color: #000000;
    background-color: #ffe9e9;
    padding: 20px;
    p:first-child {
      margin-bottom: 10px;
    }
    p {
      span {
        font-size: 14px;
        margin-left: 15px;
        padding-top: 5px;
        display: inline-block;
      }
    }
  }
  .errorBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    p {
      padding: 10px 0;
      color: #c50404;
    }
  }
  button {
    width: 100px;
    height: 40px;
    border: none;
    color: #fff;
    background-color: #15254e;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
  }
`;

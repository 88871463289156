import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { couponData } from "../../../../atom";
import { styled } from "styled-components";
import apis from "../../../../api/getApi";
import MarketingHeader from "../../CommonComponents/marketingHeader";
import { dateContent } from "../../utils";

export interface DateDateType {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}
interface CouponDataType {
  uuid: string;
  title: string;
  code: string;
  amounts: number;
  validityStartDate: string;
  validityEndDate: string;
  usageCount: number;
  comments: string;
  createdAdmin: string;
  updatedAdmin: string | null;
}

const CouponDetail = () => {
  const location = useLocation();
  const couponId = location.search.substring(12);
  const [couponDetailData, setCouponDetailData] = useRecoilState(couponData);
  const [dateEdit, setDateEdit] = useState<boolean>(false);
  const [editError, setEditError] = useState<string | null>(null);
  const [newDateData, setNewDateDate] = useState<DateDateType>({
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  });

  const detailData = async () => {
    try {
      const { data } = await apis.getCouponDetail(couponId);
      console.log(data);
      setCouponDetailData(data);
      const dataArray = (type: string) => data[type].split(" ");
      setNewDateDate({
        startDate: dataArray("validityStartDate")[0],
        startTime: dataArray("validityStartDate")[1],
        endDate: dataArray("validityEndDate")[0],
        endTime: dataArray("validityEndDate")[1],
      });
    } catch (e) {
      console.log(e);
    }
  };

  const newDateDataUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof DateDateType
  ) => {
    setNewDateDate((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const inputProps = (inputType: string, onChangeType: keyof DateDateType) => {
    return {
      className: "content",
      type: inputType,
      value: newDateData[onChangeType],
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        newDateDataUpdate(e, onChangeType),
    };
  };
  const onClickEditButton = () => {
    setDateEdit(true);
  };
  const onClickSubmit = async (
    startDate: string,
    startTime: string,
    endDate: string,
    endTime: string
  ) => {
    console.log(`${newDateData.startDate} ${newDateData.startTime}`);
    console.log(`${newDateData.endDate} ${newDateData.endTime}`);

    try {
      const response = await apis.patchCouponDate(couponId, {
        validityStartDate: `${startDate} ${startTime}`,
        validityEndDate: `${endDate} ${endTime}`,
      });

      if (response.data.isSuccess) {
        setDateEdit(false);
        await detailData();
      }
    } catch (e: any) {
      setEditError(e.data.errorMessage);
    }
  };
  const BasicSection: React.FC<{
    sectionTitle: string;
    sectionDate: keyof CouponDataType;
  }> = ({ sectionTitle, sectionDate }) => (
    <CouponDetailTitleSt>
      <section>{sectionTitle}</section>
      <section>{couponDetailData[sectionDate]}</section>
    </CouponDetailTitleSt>
  );
  React.useEffect(() => {
    detailData();
  }, []);

  React.useEffect(() => {
    setEditError(null);
  }, [newDateData]);

  return (
    <CouponDatailContainer>
      <MarketingHeader
        title={couponDetailData.title}
        forcequitButton
        onClickForceQuitButton={() =>
          onClickSubmit("1993-08-02", "19:00:00", "1993-08-02", "19:00:01")
        }
      />
      <div>
        <BasicSection sectionTitle="쿠폰번호" sectionDate="code" />
        <CouponDetailTitleSt>
          <section>유효기간</section>
          <section>
            {dateEdit ? (
              <DateEditBox>
                <input {...inputProps("date", "startDate")} />
                <input {...inputProps("time", "startTime")} />
                <span>~</span>
                <input {...inputProps("date", "endDate")} />
                <input {...inputProps("time", "endTime")} />
                <DateEditButton
                  onClick={() =>
                    onClickSubmit(
                      newDateData.startDate,
                      newDateData.startTime,
                      newDateData.endDate,
                      newDateData.endTime
                    )
                  }
                >
                  적용
                </DateEditButton>
              </DateEditBox>
            ) : (
              <DateBox>
                {dateContent(couponDetailData.validityStartDate, "date")}
                {dateContent(couponDetailData.validityStartDate, "time")}
                <div>~</div>
                {dateContent(couponDetailData.validityEndDate, "date")}
                {dateContent(couponDetailData.validityEndDate, "time")}

                <DateEditButton onClick={onClickEditButton}>
                  편집
                </DateEditButton>
              </DateBox>
            )}
            {editError ? <p className="errorText">{editError}</p> : null}
          </section>
        </CouponDetailTitleSt>
        <BasicSection sectionTitle="지급 포인트" sectionDate="amounts" />
        <BasicSection sectionTitle="사용자 수" sectionDate="usageCount" />

        <CouponDetailTitleSt>
          <section>사용자 ID</section>
          <section>
            {couponDetailData.usageHosts.map((item) => (
              <p className="hostId" key={item.hostEmail}>
                {item.hostEmail}
              </p>
            ))}
          </section>
        </CouponDetailTitleSt>
        <BasicSection sectionTitle="생성인" sectionDate="createdAdmin" />
        <BasicSection sectionTitle="최종 수정인" sectionDate="updatedAdmin" />
        <BasicSection sectionTitle="비고" sectionDate="comments" />
      </div>
    </CouponDatailContainer>
  );
};

export default CouponDetail;

export const CouponDatailContainer = styled.div`
  padding: 20px;
`;
export const CouponDetailTitleSt = styled.div`
  display: grid;
  grid-template-columns: 130px 1fr;
  border-bottom: 1px solid #e4e4e5;
  align-items: center;

  section {
    padding: 25px 29px;
    line-height: 20px;
    font-size: 15px;
  }
  section:nth-child(1) {
    color: #4f4f4f;
  }
  p.hostId {
    padding: 0 0 10px;
  }
`;

export const DateBox = styled.div`
  display: grid;
  grid-template-columns: 150px 80px 50px 150px 80px 100px;
  gap: 10px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    font-size: 14px;
    border-radius: 3px;
  }
  div:nth-child(3) {
    background-color: #ffffff;
  }
  div span {
    padding: 8px 5px 8px 0px;
  }
`;

export const DateEditBox = styled.div`
  display: grid;
  grid-template-columns: 150px 150px 50px 150px 150px 100px;
  gap: 10px;
  text-align: center;
  .content {
    border: none;
    padding: 6px;
    border-radius: 3px;
    flex-grow: 1;
    height: 20px;
    background-color: #f4f4f4;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const DateEditButton = styled.button`
  height: 30px;
  padding: 0 20px;
  border: 1px solid #0c152b;
  border-radius: 3px;
  background-color: #15254e;
  color: #fff;
  box-shadow: 1px 1px 2px #4c50593b;
  cursor: pointer;
  font-size: 14px;
`;

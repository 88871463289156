import ReactDOM from "react-dom";
import styled from "styled-components";
import QuestionDetailComponentsHeader from "../page/Qna/QuestionDetails/QuestionDetailComponentsHeader";
import QuestionDetailComponentsContents from "../page/Qna/QuestionDetails/QuestionDetailComponentsContents";
import QuestionDetailComponentsImage from "../page/Qna/QuestionDetails/QuestionDetailComponentsImage";
import {
  IGetQuestionComponentResponse,
  IQuestionComponent,
  IUploadImageList,
} from "../page/Qna/interface/i.question";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import customAxios from "../api/handler";

const QuestionAnswer = () => {
  const location = useLocation();
  const endPath = location.pathname.endsWith("/update");
  const { reloadQuestionDetail, setReloadQuestionDetail, updateQuestionDetail } = useOutletContext() as {
    reloadQuestionDetail: boolean;
    setReloadQuestionDetail: () => void;
    updateQuestionDetail: () => void;
  };

  const { questionUuid } = useParams() as { questionUuid: string };
  const { questionComponentUuid } = useParams() as {
    questionComponentUuid: string;
  };
  const { hostUuid } = useParams() as { hostUuid: string };

  const modalRoot = document.getElementById("root_modalOpen") as HTMLElement;

  if (endPath) {
    const [component, setComponent] = useState<{
      questionUuid: IQuestionComponent["questionUuid"];
      questionComponentUuid: IQuestionComponent["questionComponentUuid"];
      questionComponentContext: IQuestionComponent["questionComponentContext"];
      questionOwnerEmail: IQuestionComponent["questionComponentOwnerEmail"];
      questionComponentType: IQuestionComponent["questionComponentType"];
      questionCreatedAt: IQuestionComponent["questionComponentCreatedAt"];
      signedImageList: IUploadImageList[];
    } | null>(null);
    const [context, setContext] = useState<string>("");
    const [confirmImage, setConfirmImage] = useState<Array<IUploadImageList>>(
      []
    );
    const [uploadList, setUploadList] = useState<Array<IUploadImageList>>([]);
    const navigate = useNavigate();

    const getUpdateQuestionComponent = async () => {
      console.log(
        `/admin/proc/questions/update?questionUuid=${questionUuid}&questionComponentUuid=${questionComponentUuid}&hostUuid=${hostUuid}`
      );

      await customAxios
        .get(
          `/admin/proc/questions/update?questionUuid=${questionUuid}&questionComponentUuid=${questionComponentUuid}&hostUuid=${hostUuid}`
        )
        .then(({ data }) => {
          const { questionComponent } = data as IGetQuestionComponentResponse;

          setComponent(questionComponent);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    useEffect(() => {
      getUpdateQuestionComponent();
    }, []);

    useEffect(() => {}, [component]);

    const closeModal = (data: boolean) => {
      navigate(``);
    };

    const saveContextInput = (data: string) => {
      setContext(data);
    };

    const saveSetConfirmImage = (data: IUploadImageList[]) => {
      setConfirmImage(data);
    };

    const saveSetUploadList = (data: IUploadImageList[]) => {
      setUploadList(data);
    };

    const modal = ReactDOM.createPortal(
      <StyledQuestionAnswerModal>
        <div className="answerBox">
          <QuestionDetailComponentsHeader
            props={{
              closeModal,
              context,
              confirmImage,
              uploadList,
              questionComponentUuid,
              questionUuid,
              hostUuid,
              setReloadQuestionDetail,
              reloadQuestionDetail,
              updateQuestionDetail
            }}
          ></QuestionDetailComponentsHeader>
          <QuestionDetailComponentsContents
            props={{
              saveContextInput,
              defaultContext: component?.questionComponentContext,
            }}
          ></QuestionDetailComponentsContents>
          <QuestionDetailComponentsImage
            props={{
              questionComponentUuid,
              questionUuid,
              saveSetConfirmImage,
              saveSetUploadList,
              signedImageList: component?.signedImageList,
            }}
          ></QuestionDetailComponentsImage>
        </div>
      </StyledQuestionAnswerModal>,
      modalRoot
    );

    return <>{modal}</>;
  } else {
    const [context, setContext] = useState<string>("");
    const [confirmImage, setConfirmImage] = useState<Array<IUploadImageList>>(
      []
    );
    const [uploadList, setUploadList] = useState<Array<IUploadImageList>>([]);
    const navigate = useNavigate();

    const closeModal = (data: boolean) => {
      navigate(``);
    };

    const saveContextInput = (data: string) => {
      setContext(data);
    };

    const saveSetConfirmImage = (data: IUploadImageList[]) => {
      setConfirmImage(data);
    };

    const saveSetUploadList = (data: IUploadImageList[]) => {
      setUploadList(data);
    };

    const modal = ReactDOM.createPortal(
      <StyledQuestionAnswerModal>
        <div className="answerBox">
          <QuestionDetailComponentsHeader
            props={{
              closeModal,
              context,
              confirmImage,
              uploadList,
              questionComponentUuid,
              questionUuid,
              hostUuid,
              setReloadQuestionDetail,
              reloadQuestionDetail,
              updateQuestionDetail
            }}
          ></QuestionDetailComponentsHeader>
          <QuestionDetailComponentsContents
            props={{ saveContextInput }}
          ></QuestionDetailComponentsContents>
          <QuestionDetailComponentsImage
            props={{
              questionComponentUuid,
              questionUuid,
              saveSetConfirmImage,
              saveSetUploadList,
            }}
          ></QuestionDetailComponentsImage>
        </div>
      </StyledQuestionAnswerModal>,
      modalRoot
    );

    return <>{modal}</>;
  }
};

export default QuestionAnswer;

const StyledQuestionAnswerModal = styled.div`
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  font-family: "semibold";

  .answerBox {
    position: absolute;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px;
    max-width: 100%;
    max-height: 100%;
    width: 800px;
    height: 800px;
    margin: 60px 600px;
    background-color: white;
    gap: 20px;
  }
`;

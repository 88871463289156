import React, { ReactNode } from "react";
import styled from "styled-components";

interface PageProps {
  children: ReactNode;
}

const PageTemplate: React.FC<PageProps> = (props) => {
  return <StPage>{props.children}</StPage>;
};

const StPage = styled.div`
  width: 100%;
  height: 100%;
  padding: 48px;
  box-sizing: border-box;
  overflow-x: hidden;
`;

export default PageTemplate;

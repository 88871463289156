import React, { useState } from "react";
import PageHeader from "../organism/PageHeader";
import { styled } from "styled-components";
import MemberTable from "../organism/MemberTable";
import TextLine from "../atom/TextLine";
import SignUpConfirmTable from "../organism/SignUpConfirmTable";
import useGetMasterUserList from "../../hook/api/useGetMasterUserList";
import { Outlet } from "react-router-dom";

const MasterTemplate = () => {
  const [resetData, setResetData] = useState<boolean>(false);
  const { data } = useGetMasterUserList();

  return (
    <>
      <StMaster>
        <PageHeader title="관리자 페이지" search={false} />
        <div className="bodyBox">
          <div className="userPart">
            <TextLine text="회원 리스트" fontSize={24} fontFamily="medium" />
            <MemberTable
              headerList={[
                "이름",
                "사원명",
                "이메일",
                "직무",
                "전화번호",
                "가입 일자",
              ]}
              items={data?.registeredAdmins}
              //   nowPage={nowPage}
            />
          </div>
          <div className="userPart">
            <TextLine text="회원가입 승인" fontSize={24} fontFamily="medium" />
            <SignUpConfirmTable
              headerList={[
                "이름",
                "사원명",
                "이메일",
                "직무",
                "전화번호",
                "신청 일자",
              ]}
              items={data?.pendingAdmins}
            />
          </div>
        </div>
      </StMaster>
      <Outlet context={{ resetData: resetData, setResetData: setResetData }} />
    </>
  );
};

const StMaster = styled.div`
  display: grid;
  padding: 48px;
  box-sizing: border-box;
  gap: 30px;
  .bodyBox {
    display: grid;
    gap: 60px;
    margin-top: 40px;
    padding: 0 10px;
    .userPart {
      display: grid;
      gap: 20px;
    }
  }
`;

export default MasterTemplate;

import { styled } from "styled-components";
import spinnerImage from "../../../img/spinner.png";

const SpinnerIcon: React.FC<{
  width: number | string;
  height: number | string;
}> = ({ width, height }) => {
  return (
    <StSpinnerIcon src={spinnerImage} alt="" width={width} height={height} />
  );
};

export default SpinnerIcon;

const StSpinnerIcon = styled.img`
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: rotation 1s linear infinite;
`;

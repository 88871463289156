import React, { useEffect } from "react";
import styled, { css } from "styled-components";

interface ContainerProps {
  sameMonth: boolean;
  sameDay: boolean;
  clickDay: boolean;
  isHoliday: boolean;
  day: Date; // day 프로퍼티 추가
  dayExamCount: number;
}

const Container = styled.div<ContainerProps>`
  height: 110px;
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  background-color: ${({ day }) =>
    day.getDay() === 0
      ? "#f0f0f0"
      : day.getDay() === 6
      ? "#f0f0f0"
      : "inherit"};

  &:hover {
    background-color: #c0c0c0;
  }
  span {
    font-size: 20px;
    color: ${({ sameMonth }) => (sameMonth ? "black" : "gray")};
    font-weight: ${({ dayExamCount, sameMonth }) =>
      sameMonth && dayExamCount !== 0 ? `700` : `200`};
  }
  p {
    padding-left: 15px;
    font-size: 15px;
    font-weight: ${({ sameMonth }) => (sameMonth ? `700` : `200`)};

    /* 날짜의 글자 색상 변경 */
    color: ${({ day }) =>
      day.getDay() === 0 ? "red" : day.getDay() === 6 ? "blue" : "inherit"};
  }

  ${({ sameDay }) =>
    sameDay
      ? css`
          background-color: #c0c0c0;
        `
      : css``}

  ${({ clickDay }) =>
    clickDay
      ? css`
          border: 1px solid blueviolet;
        `
      : css``}

    ${({ isHoliday }) =>
    isHoliday &&
    css`
      color: red;
    `}

  div:first-child {
    position: absolute;
    top: 0;
    left: 0;
  }

  div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
  }
`;

interface Props {
  day: Date;
  nowDate: Date;
  setNowDate: React.Dispatch<React.SetStateAction<Date>>;
  clickedDate: Date | undefined;
  setClickedDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  isHoliday: boolean;
  dayExamCount: number;
}

const allDay = ({
  day,
  nowDate,
  setNowDate,
  clickedDate,
  setClickedDate,
  isHoliday,
  dayExamCount,
}: Props) => {
  const nowTime = new Date();

  const sameMonth = nowDate.getMonth() === day.getMonth();
  const sameDay =
    nowTime.getFullYear() === day.getFullYear() &&
    nowTime.getMonth() === day.getMonth() &&
    nowTime.getDate() === day.getDate();

  const clickDay: boolean = clickedDate
    ? clickedDate.getFullYear() === day.getFullYear() &&
      clickedDate.getMonth() === day.getMonth() &&
      clickedDate.getDate() === day.getDate()
    : false;

  const clickDate = () => {
    setClickedDate(day);
  };

  return (
    <Container
      onClick={() => clickDate()}
      sameMonth={sameMonth}
      sameDay={sameDay}
      clickDay={clickDay}
      isHoliday={isHoliday}
      day={day}
      dayExamCount={dayExamCount}
    >
      <div>
        <p>{day.getDate()}</p>
      </div>
      <div>
        <span>{dayExamCount}건</span>
      </div>
    </Container>
  );
};

export default allDay;

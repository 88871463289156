import React from "react";
import { styled } from "styled-components";
import TextLine from "../atom/TextLine";
import useGetMemberSpecific from "../../hook/api/useGetMemberSpecific";
import { useParams } from "react-router-dom";

const MemberInfo = () => {
  const uid = useParams().uid;
  const { data } = useGetMemberSpecific({ uid });

  return (
    <StMemberInfo className="groupBox">
      <TextLine text="멤버 정보" fontSize={25} fontFamily="medium" />
      <div className="infoPart">
        <div className="infoLine">
          <TextLine text="이름" />
          <TextLine text={data?.name} />
        </div>
        <div className="infoLine">
          <TextLine text="사원명" />
          <TextLine text={data?.nickName} />
        </div>
        <div className="infoLine">
          <TextLine text="직무" />
          <TextLine text={data?.department} />
        </div>
        <div className="infoLine">
          <TextLine text="이메일" />
          <TextLine text={data?.email} />
        </div>
        <div className="infoLine">
          <TextLine text="핸드폰 번호" />
          <TextLine text={data?.phoneNumber} />
        </div>
        <div className="infoLine">
          <TextLine text="가입 일자" />
          <TextLine text={data?.signUpDate} />
        </div>
        <div className="infoLine">
          <TextLine text="마지막 로그인" />
          <TextLine text={data?.lastLoginDate} />
        </div>
      </div>
    </StMemberInfo>
  );
};

const StMemberInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  .infoPart {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    .infoLine {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      font-size: 20px;
    }
  }
`;

export default MemberInfo;

import React from "react";
import { styled } from "styled-components";

interface ModalBackProp {
  clickfunction?: () => void;
  zindex?: number;
  backgroudcolor?: string;
}

const ModalBack: React.FC<ModalBackProp> = (props) => {
  return (
    <StModalBack
      onClick={props.clickfunction}
      zindex={props.zindex}
      backgroudcolor={props.backgroudcolor}
    />
  );
};

const StModalBack = styled.div<ModalBackProp>`
  position: fixed;
  z-index: ${(props) => (props.zindex ? props.zindex : 1)};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.backgroudcolor ? props.backgroudcolor : "transparent"};
`;

export default ModalBack;

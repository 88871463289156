import { useState } from "react";
import { IQuestionComponentImage } from "../interface/i.question";
import styled from "styled-components";

const QuestionDetailImage = ({
  questionImage,
}: {
  questionImage: IQuestionComponentImage[];
}) => {
  const [modalImage, setModalImage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (image: string) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalImage("");
    setIsModalOpen(false);
  };

  const imageRetunred =
    questionImage.length > 0 ? (
      <div className="imageBox">
        {questionImage.map((image, idx) => {
          return (
            <img
              className="image"
              src={image.questionImageUrl}
              key={idx + 1}
              onClick={() => openModal(image.questionImageUrl)}
            ></img>
          );
        })}
      </div>
    ) : (
      <div></div>
    );

  return (
    <StyledQuestionDetailImage>
      {imageRetunred}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img src={modalImage} alt="확대된 이미지" className="modal-image" />
          </div>
        </div>
      )}
    </StyledQuestionDetailImage>
  );
};

export default QuestionDetailImage;

const StyledQuestionDetailImage = styled.div`
  width: 100%;
  height: 30%;
  background-color: white;
  margin-top: 200px;
  display: flex;
  justify-content: flex-start;

  .imageBox {
    display: flex;
    gap: 20px;
    margin: 0px 10px;
    width: 10%;
    height: 90%;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 6px;
    border-radius: 12px;

    .image {
      max-width: 100%; /* 이미지의 최대 너비를 부모 요소인 .image-box의 너비에 맞춥니다. */
      max-height: 100%; /* 이미지의 최대 높이를 부모 요소인 .image-box의 높이에 맞춥니다. */

      .modal {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }

  .modal-image {
    max-width: 100%;
    max-height: 80vh; /* 이미지의 최대 높이 설정 */
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
`;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PageHeader from "../../atomic/organism/PageHeader";
import Pagination from "../../atomic/organism/Pagination";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Filter } from "../../atomic/template/RefundTemplate";
import customAxios from "../../api/handler";
import RequestTable from "../../atomic/organism/ConsultTable";
import useGetConsultList from "../../hook/api/useGetConsultList";

const ConsultRequest = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const emailRef = useRef<HTMLInputElement | null>(null);

  const filter: Filter = {
    use: true,
    filterList: ["통합", "기업", "개인"],
  };
  const pageCount = 18;
  const nowPage = Number(useParams().nowPage);

  const [chooseFilter, setChooseFilter] = useState<number>(0);
  const [hostType, setHostType] = useState<string>("all");
  const [pageCounting, setPageCounting] = useState<number>(1); //
  const [pageShowing, setPageShowing] = useState<number[]>([]);
  // const [requestList, setRequestList] = useState<{ [key: string]: any }>({});
  const [requestCount, setRequestCount] = useState<number>(pageCount);

  /** 개인별 검색하기 */
  const getPersonalRefundList = (event: React.FormEvent) => {
    event.preventDefault();
    navigate(`/common/refundList/1?email=${emailRef.current?.value}`);
  };

  /** 문의 요청 카운트 해주기 */
  const getRefundCount = () => {
    customAxios
      .get(`/admin/proc/intro/count`)
      .then(({ data }) => {
        setRequestCount(data.count);
      })
      .catch(() => {});
  };
  //
  const { data } = useGetConsultList({ nowPage, pageCount });

  // /** 문의 요청 목록 받아오기 */
  // const getRefundList = () => {
  //   customAxios
  //     .get(
  //       `/admin/proc/intro/list?page=${1}&pageCount=${pageCount}&offset=-540`
  //     )
  //     .then(({ data }) => {
  //       setRequestList(data);
  //     })
  //     .catch(({ data }) => {
  //       if (data?.errorCode === "AUTH-003") {
  //         setRequestCount(0);
  //         setRequestList([]);
  //       }
  //     });
  // };

  useEffect(() => {
    // getRefundList();
    getRefundCount();
  }, [search, nowPage, hostType]);

  useEffect(() => {
    setPageCounting(Math.ceil(requestCount / pageCount));
  }, [requestCount]);

  useEffect(() => {
    if (chooseFilter === 0) {
      setHostType("all");
    } else if (chooseFilter === 1) {
      setHostType("ent");
    } else if (chooseFilter === 2) {
      setHostType("ind");
    }
  }, [chooseFilter]);

  return (
    <StTemplate>
      <PageHeader
        title="도입문의 신청 리스트"
        search={true}
        filter={filter}
        chooseFilter={chooseFilter}
        setChooseFilter={setChooseFilter}
        searchFunction={getPersonalRefundList}
        useref={emailRef}
      />
      <RequestTable
        headerList={["신청인", "소속기관", "이메일", "전화번호", "신청 날짜"]}
        items={data?.introList}
        nowPage={nowPage}
      />
      <Pagination
        nowpage={nowPage}
        pageCounting={pageCounting}
        pageShowing={pageShowing}
        setPageShowing={setPageShowing}
        type="consultRequest"
        bigType="marketing"
      />
    </StTemplate>
  );
};

const StTemplate = styled.div`
  display: grid;
  gap: 30px;
  padding: 40px;
  box-sizing: border-box;
`;
export default ConsultRequest;

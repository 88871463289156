import apis from "../../../../api/getApi";
import React, { MutableRefObject, RefObject } from "react";
import styled from "styled-components";
import Header from "../header";
import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Spinner from "../../../Tester/components/spinner";
import HourglassGif from "../../../../img/hourglass.gif";

// type RequestCreditType = {
//   requestId: number;
//   enterpriseName: string;
//   email: string;
//   credits: number; // 적립금(C)
//   currencyAmount: number; // 실제 금액
//   currencyType: "won" | "dollar"; // 화페 단위
//   requestDate: string;
//   status: "pending" | "charge" | "recovery";
// };

type ReponseDataType = {
  credits: number;
  currencyAmount: number;
  currencyType: "won" | "dollar";
  email: string;
  enterpriseName: string;
  isSuccess: boolean;
  isTesterCountBasedCalc: boolean;
  requestDate: string;
  requestId: number;
  status: "pending" | "charge" | "recovery";
  testTimeUnit: null | string;
  testerCount: null | string;
};

const CreditRequestDetaiil: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const [detailData, setDetailData] = React.useState<ReponseDataType | null>(
    null
  );
  const [checked, setChecked] = React.useState<boolean>(false);
  const [additionChecked, setAdditionChecked] = React.useState<boolean>(false);

  const [comments, setComments] = React.useState<string>("");
  const [paymentCompleted, setPaymentCompleted] =
    React.useState<boolean>(false);
  const [bonusCreditsPaymentStart, setBonusCreditsPaymentStart] =
    React.useState<boolean>(false);
  const [basicPaymentStart, setBasicPaymentStart] =
    React.useState<boolean>(false);
  const [bonusCredits, setBonusCredits] = React.useState<number | undefined>(
    undefined
  );
  const [clickedRecoveryBtn, setClickedRecoveryBtn] =
    React.useState<boolean>(false);
  const [recoveryStart, setRecoveryStart] = React.useState<boolean>(false);
  const recoveryCommentInputRef = React.useRef<HTMLInputElement | null>(null);
  const [recoveryComment, setRecoveryComment] = React.useState<string>();
  const [basicPaymentCompleted, setBasicPaymentCompleted] =
    React.useState<boolean>(false);
  const itemKey = {
    credits: "요청 적립금",
    currencyAmount: "결제 금액",
    currencyType: "화페 단위",
    email: "이메일",
    enterpriseName: "기업명",
    isSuccess: "",
    isTesterCountBasedCalc: "적립금 계산 방식",
    requestDate: "요청 일자",
    requestId: "요청ID",
    status: "처리 상태",
    testTimeUnit: "시험시간",
    testerCount: "응시자 수",
  };

  const [eleWidth, setEleWidth] = React.useState<number | undefined>(0);
  const [boxPosition, setboxPosition] = React.useState<string | number>(0);
  const [bonusTargetApprovedId, setBonusTargetApprovedId] =
    React.useState<number>();
  const moveElement = () => {
    if (boxPosition === 0 && eleWidth) {
      setboxPosition("calc(-100% - 20px)");
    }
  };

  const onClickRequestApprovalBtn = () => {
    moveElement();
  };

  const getDetail = async (requestId: string) => {
    try {
      const response = await apis.getRequestCreditDetail(requestId);
      const data = response.data as ReponseDataType;
      console.log(data);
      setTimeout(() => {
        setDetailData(data);
      }, 500);
    } catch (error) {
      console.log("에러");
    }
  };

  const onClickRecoverylBtn = async () => {
    try {
      const response = await apis.postCreditRecovery({
        requestId: detailData?.requestId,
        comments: "",
      });
    } catch (error) {
      console.log("적립금 회수 에러", error);
    }
  };

  const onClickPayBtn = async () => {
    try {
      setBasicPaymentStart(true);
      const response = await apis.postCreditsPayments({
        requestId: detailData?.requestId,
        comments: comments,
      });
      console.log(response);
      const data = response.data as {
        isSuccess: boolean;
        bonusTargetApprovedId: number;
      };
      if (data.isSuccess) {
        setBasicPaymentCompleted(true);
        setBonusTargetApprovedId(data.bonusTargetApprovedId);
      }
      setBasicPaymentStart(false);
      console.log("요청 크레딧 지급 성공", data);
    } catch (error) {
      setBasicPaymentStart(false);
      console.log("요청 크레딧 지급 에러", error);
    }
  };

  const onClickAdditionalPayment = async () => {
    try {
      setBonusCreditsPaymentStart(true);
      const response = await apis.postBonusCreditsPayments({
        bonusTargetApprovedId,
        bonusCredits,
      });
      const data = response.data as {
        isSuccess: boolean;
      };

      setBonusCreditsPaymentStart(false);
      if (data.isSuccess) {
        window.location.reload();
        // navigate(
        //   `/marketing/creditRequestDetail?requestId=${detailData?.requestId}`
        // );
      }
    } catch (error) {
      setBonusCreditsPaymentStart(false);
    }
  };

  React.useEffect(() => {
    if (recoveryStart) {
      recoveryCommentInputRef.current?.focus();
    }
  }, [recoveryStart]);

  React.useEffect(() => {
    const requestId = searchParams.get("requestId");

    if (!location.search || !requestId) {
      if (window.confirm("잘못된 접근입니다.")) {
        navigate("/marketing/creditRequest");
      } else {
        navigate("/common/landing");
      }
    } else {
      const requestId = searchParams.get("requestId");
      if (requestId) getDetail(requestId.toString());
    }
  }, []);

  if (detailData) {
    return (
      <div
        style={{
          height: `calc(100vh - 75px)`,
          backgroundColor: "#e9ebf1",
          padding: 20,
          boxSizing: "border-box",
          overflow: "scroll",
          scrollbarWidth: "none",
          borderTop: "1px solid #e9ebf1",
          maxWidth: "calc(100vw - 300px)",
        }}
      >
        <Header
          title={"적립금 요청 상세내역"}
          iconBgColor={
            detailData?.status === "charge"
              ? "rgb(240 67 67)"
              : detailData?.status === "pending"
              ? "rgb(41 174 223)"
              : "gray"
          }
        />
        <div
          ref={(e) => {
            setEleWidth(e?.clientWidth);
          }}
          style={{
            transform: `translateX(${boxPosition})`,
            // transform: `translateX(calc(-100% - 20px))`,

            transition: "transform 0.6s ease",
            display: "grid",
            gridTemplateColumns: "repeat(2, 100%)",
            gap: 20,
          }}
        >
          <div
            style={{
              height: "85%",
              backgroundColor: "#fff",
              borderRadius: 8,
              boxShadow: "1px 1px 3px #15254e3d",
              marginTop: 20,
              padding: 30,
              overflow: "scroll",
              scrollbarWidth: "none",
            }}
          >
            <ListTable>
              {Object.keys(detailData).map(
                (key: string, index: number): JSX.Element | null => {
                  const item = key as keyof ReponseDataType;
                  const calcMethod =
                    item === "isTesterCountBasedCalc"
                      ? "응시자 인원으로 계산"
                      : "충전 금액 직접입력";
                  const status =
                    item === "status"
                      ? detailData[item] === "charge"
                        ? "승인 완료"
                        : detailData[item] === "pending"
                        ? "승인 대기"
                        : "회수 완료"
                      : null;

                  if (key !== "isSuccess") {
                    if (detailData[item] !== null) {
                      return (
                        <>
                          <div className="title">
                            <div
                              style={{
                                width: 130,
                                height: 55,
                                backgroundColor: "rgb(233 235 241)",
                                borderRadius: 5,
                                justifyContent: "center",
                                alignItems: "center",
                                color: "rgb(89 90 93)",
                              }}
                            >
                              {itemKey[item]}
                            </div>
                          </div>
                          <div>
                            {item === "isTesterCountBasedCalc"
                              ? calcMethod
                              : item === "status"
                              ? status
                              : detailData[item]}
                          </div>
                        </>
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return null;
                  }
                }
              )}
            </ListTable>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "30px 10px",
                position: "relative",
                minHeight: "42.5px",
              }}
            >
              {clickedRecoveryBtn ? (
                <img
                  src={HourglassGif}
                  width={35}
                  style={{ padding: "20px 0px" }}
                />
              ) : detailData?.status === "charge" ? (
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    transition: "left 0.3s linear",
                  }}
                >
                  <input
                    value={recoveryComment}
                    onChange={(e) => setRecoveryComment(e.target.value)}
                    ref={recoveryCommentInputRef}
                    type="text"
                    placeholder="비고란"
                    style={{
                      width: recoveryStart ? 500 : 0,
                      transition: "width 0.3s linear",
                      border: "none",
                      borderBottom: "1px solid #c9c9c9",
                      backgroundColor: "#f7f7f7",
                      padding: recoveryStart ? 10 : 0,
                      outline: "none",
                      marginRight: 20,
                    }}
                  />
                  <RecoveryBtn
                    setRecoveryStart={setRecoveryStart}
                    recoveryStart={recoveryStart}
                    requestId={detailData.requestId}
                    comments={recoveryComment ?? ""}
                    navigate={navigate}
                    setClickedRecoveryBtn={setClickedRecoveryBtn}
                  />
                </div>
              ) : detailData?.status === "pending" ? (
                <RequestApprovalBtn onClick={onClickRequestApprovalBtn} />
              ) : null}
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: 8,
              boxShadow: "1px 1px 3px #15254e3d",
              marginTop: 20,
              // padding: 30,
              display: "grid",
              gridTemplateColumns: "repeat(2,auto)",
              // boxSizing: "border-box",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: basicPaymentCompleted ? "0%" : "50%",
                transition: "left 0.3s linear",
                display: "flex",
                width: "50%",
                height: "100%",
                backgroundColor: "#e9ebf1b8",
                zIndex: 100,
              }}
            />
            {/* 요청된 적립금 지급 박스 */}
            <div
              style={{
                boxSizing: "border-box",
                padding: 30,
              }}
            >
              <div
                style={{
                  padding: "10px 0 10px",
                  borderBottom: " 1px dashed #eaeaea",
                  marginBottom: 30,
                }}
              >
                <p>step1. 요청된 적립금 지급</p>
              </div>
              <p style={{ fontSize: 12, color: "darkgray", paddingBottom: 10 }}>
                승인 내용.
              </p>

              <p
                style={{
                  fontSize: 18,
                  // fontWeight: 400,
                  fontFamily: "Regular",
                  color: "rgb(70, 74, 83",
                  // padding: 50,
                }}
              >
                기업
                <span
                  style={{
                    display: "inline-block",
                    padding: "6px 20px",
                    border: "1px solid rgb(41 174 223)",
                    margin: "0px 10px",
                    color: "#000",
                    borderRadius: 3,
                  }}
                >
                  {detailData.enterpriseName}
                </span>
                이&#40;가&#41; 요청한{" "}
                <span
                  style={{
                    display: "inline-block",
                    padding: "6px 20px",
                    border: "1px solid rgb(41 174 223)",
                    margin: "0px 10px",
                    color: "#000",
                    borderRadius: 3,
                  }}
                >
                  {detailData.credits}c
                </span>
                를&#40;을&#41; 승인합니다.
              </p>

              <div style={{ paddingTop: 40 }}>
                <p
                  style={{ fontSize: 12, color: "darkgray", paddingBottom: 10 }}
                >
                  비고.
                </p>
                <textarea
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  placeholder=""
                  style={{
                    padding: 10,
                    border: "1px solid #cdced1",
                    boxShadow: "inset 1px 1px 1px #2e3a5b5c",
                    resize: "none",
                    borderRadius: 3,
                    width: 500,
                    height: 70,
                    outline: "none",
                  }}
                />
              </div>
              <div style={{}}>
                <div
                  style={{
                    paddingTop: 40,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    name="check"
                    id="check"
                    style={{ width: 10 }}
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                  <label
                    htmlFor="check"
                    style={{ paddingLeft: 5, color: "gray", fontSize: 12 }}
                  >
                    기업과 요청액 등 요청건에 대해 모두 확인했어요.
                  </label>
                </div>
                {basicPaymentStart ? (
                  <img
                    src={HourglassGif}
                    width={35}
                    style={{ padding: "20px 0px" }}
                  />
                ) : (
                  <button
                    style={{
                      padding: 5,
                      fontSize: 15,
                      width: 100,
                      border: checked
                        ? "1px solid rgb(134, 205, 231)"
                        : "1px solid darkgray",
                      borderRadius: 3,
                      backgroundColor: checked
                        ? "rgb(234, 249, 255)"
                        : "#e9ebf1",
                      color: checked ? "#2ba8d5" : "gray",
                      cursor: checked ? "pointer" : "auto",
                      boxShadow: checked
                        ? "rgb(84 159 186 / 37%) 1px 1px 3px"
                        : "none",
                      marginTop: 20,
                      opacity: checked ? 1 : 0.4,
                    }}
                    disabled={!checked}
                    onClick={onClickPayBtn}
                  >
                    {basicPaymentCompleted ? "승인완료" : "승인"}
                  </button>
                )}
              </div>
            </div>

            {/* 추가 적립금 지급 박스 */}
            <div
              style={{
                boxSizing: "border-box",
                padding: 30,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  padding: "10px 0 10px",
                  borderBottom: " 1px dashed #eaeaea",
                  marginBottom: 30,
                }}
              >
                <p>step2. 추가 적립금 지급</p>
              </div>
              <p style={{ fontSize: 12, color: "darkgray", paddingBottom: 10 }}>
                추가 지급 내용.
              </p>

              <p
                style={{
                  fontSize: 18,
                  fontFamily: "Regular",
                  color: "rgb(70, 74, 83",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                기업
                <span
                  style={{
                    display: "inline-block",
                    padding: "6px 20px",
                    border: "1px solid rgb(41 174 223)",
                    margin: "0px 10px",
                    color: "#000",
                    borderRadius: 3,
                  }}
                >
                  {detailData.enterpriseName}
                </span>
                에
                <input
                  type="number"
                  value={bonusCredits}
                  onChange={(e) => {
                    const num = Number(e.target.value);
                    setBonusCredits(num);
                  }}
                  style={{
                    display: "inline-block",
                    padding: "6px 20px",
                    border: "1px solid rgb(41 174 223)",
                    margin: "0px 4px 0 10px",
                    color: "#000",
                    borderRadius: 3,
                    width: 100,
                    outline: "none",
                  }}
                />
                c 를&#40;을&#41; 추가 적립합니다.
              </p>

              <div style={{}}>
                <div
                  style={{
                    paddingTop: 40,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    name="check2"
                    id="check2"
                    style={{ width: 10 }}
                    checked={additionChecked}
                    onChange={(e) => setAdditionChecked(e.target.checked)}
                  />
                  <label
                    htmlFor="check2"
                    style={{ paddingLeft: 5, color: "gray", fontSize: 12 }}
                  >
                    기업과 지급액 등 추가 적립금 지급건에 대해 확인했어요.
                  </label>
                </div>
                {bonusCreditsPaymentStart ? (
                  <img
                    src={HourglassGif}
                    width={35}
                    style={{ padding: "20px 0px" }}
                  />
                ) : (
                  <>
                    <button
                      style={{
                        padding: 5,
                        fontSize: 15,
                        width: 100,
                        border:
                          bonusCredits && additionChecked
                            ? "1px solid rgb(134, 205, 231)"
                            : "1px solid darkgray",
                        borderRadius: 3,
                        backgroundColor:
                          bonusCredits && additionChecked
                            ? "rgb(234, 249, 255)"
                            : "#e9ebf1",
                        color:
                          bonusCredits && additionChecked ? "#2ba8d5" : "gray",
                        cursor:
                          bonusCredits && additionChecked ? "pointer" : "auto",
                        boxShadow:
                          bonusCredits && additionChecked
                            ? "rgb(84 159 186 / 37%) 1px 1px 3px"
                            : "none",
                        marginTop: 20,
                        opacity: bonusCredits && additionChecked ? 1 : 0.4,
                      }}
                      disabled={!bonusCredits || !additionChecked}
                      onClick={onClickAdditionalPayment}
                    >
                      추가 지급
                    </button>
                    <button
                      onClick={
                        () => window.location.reload()
                        // navigate(
                        //   `/marketing/creditRequestDetail?requestId=${detailData?.requestId}`
                        // )
                      }
                      style={{
                        marginLeft: 10,
                        padding: 5,
                        fontSize: 15,
                        width: 150,
                        border: "1px solid #da5e5e",
                        borderRadius: 3,
                        backgroundColor: "#da5e5e",
                        color: "white",
                        cursor: "pointer",
                        boxShadow: "rgb(84 159 186 / 37%) 1px 1px 3px",
                        marginTop: 20,
                      }}
                    >
                      추가 지급 하지 않음
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Spinner />
      </>
    );
  }
};

export default CreditRequestDetaiil;
export const ListTable = styled.div`
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  display: grid;
  grid-template-columns: repeat(2, 150px auto);
  position: relative;
  &:not(:first-child):hover::after {
    content: "자세히보기";
    /* font-family: "semiBold"; */
    color: #15254e;
    font-size: 20px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75px;
    background-color: rgba(240, 242, 245, 0.9);
    border-radius: 8px;
    cursor: pointer;
  }

  div.title {
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  div {
    /* box-sizing: border-box; */
    /* padding: 30px 5px 30px; */
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 5px;
    height: 75px;
    border-bottom: 1px solid #e9ebf1;
    max-width: 100%;

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 300px;
    }
  }
`;

const RecoveryBtn: React.FC<{
  setRecoveryStart: React.Dispatch<React.SetStateAction<boolean>>;
  recoveryStart: boolean;
  requestId: number;
  comments: string;
  navigate: NavigateFunction;
  setClickedRecoveryBtn: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  setRecoveryStart,
  recoveryStart,
  requestId,
  comments,
  navigate,
  setClickedRecoveryBtn,
}) => {
  const onClickRecoveryBtn = async () => {
    try {
      setClickedRecoveryBtn(true);

      const response = await apis.postCreditRecovery({
        requestId,
        comments,
      });
      const data = response.data as { isSuccess: boolean };
      if (data.isSuccess) {
        window.location.reload();
        // navigate(`/marketing/creditRequestDetail?requestId=${requestId}`);
      }
    } catch (error) {
      setClickedRecoveryBtn(false);

      console.log("적립금 회수 에러", error);
    }
  };
  const onClickRecoveryStart = () => {
    if (recoveryStart) {
      onClickRecoveryBtn();
    } else {
      setRecoveryStart(true);
    }
  };
  return (
    <button
      style={{
        padding: 10,
        fontSize: 17,
        width: recoveryStart ? 100 : 250,
        transition: "width 0.3s linear",

        border: "1px solid rgb(240 67 67)",
        borderRadius: 5,
        backgroundColor: "rgb(251 241 241)",
        color: "gray",
        cursor: "pointer",
        boxShadow: "rgb(240 67 67 / 36%) 1px 1px 3px",
      }}
      onClick={onClickRecoveryStart}
    >
      {recoveryStart ? (
        <span
          style={{
            textDecorationLine: "underline",
            color: "rgb(240 67 67)",
          }}
        >
          회수
        </span>
      ) : (
        <>
          적립금{" "}
          <span
            style={{
              textDecorationLine: "underline",
              color: "rgb(240 67 67)",
            }}
          >
            회수
          </span>{" "}
          진행하기
        </>
      )}
    </button>
  );
};

const RequestApprovalBtn: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button
      style={{
        padding: 10,
        fontSize: 17,
        width: 250,
        border: "1px solid rgb(134 205 231)",
        borderRadius: 5,
        backgroundColor: "rgb(234 249 255)",
        color: "gray",
        cursor: "pointer",
        boxShadow: "rgb(84 159 186) 1px 1px 3px",
      }}
      onClick={onClick}
    >
      적립금{" "}
      <span
        style={{
          textDecorationLine: "underline",
          color: "rgb(41 174 223)",
        }}
      >
        승인
      </span>{" "}
      진행하기
    </button>
  );
};

import { UseQueryResult, useQuery } from "react-query";
import apis from "../../api/getApi";

export interface ConsultListApiResponse {
  isSuccess: true;
  introList: [
    {
      introId: number;
      introName: string;
      introGroup: string;
      introEmail: string;
      introPhone: string;
      introAnswer: 0;
      introAt: string;
    },
  ];
}

interface DataProp {
  nowPage: number;
  pageCount: number;
}

const useGetConsultList = ({
  nowPage,
  pageCount,
}: DataProp): UseQueryResult<ConsultListApiResponse, unknown> => {
  const fetcher = async () => {
    const response = await apis.getConsultList({ nowPage, pageCount });
    return response.data;
  };
  return useQuery(["consultList", nowPage], fetcher);
};

export default useGetConsultList;

import React, { useState } from "react";
import { styled } from "styled-components";
import TextLine from "../atom/TextLine";
import AuthorityLine from "../molecule/AuthorityLine";
import useGetMemberSpecificAuthority from "../../hook/api/useGetMemberSpecificAuthority";
import { useParams } from "react-router-dom";

const MemberAuthority = () => {
  const uid = useParams().uid;
  const [changed, setChanged] = useState<boolean>(false);
  const { data } = useGetMemberSpecificAuthority({ uid, changed });

  return (
    <StMemberAuthority className="groupBox">
      <TextLine text="권한 부여" fontSize={25} fontFamily="medium" />
      <div className="pages">
        <div className="authorityHeader">
          <div>페이지</div>
          <div>선택</div>
        </div>
        <AuthorityLine
          pageName="랜딩페이지"
          pageEng="landing"
          check={data?.landing}
          setChanged={setChanged}
          changed={changed}
        />
        <AuthorityLine
          pageName="1:1문의"
          pageEng="qnaList"
          check={data?.qnaList}
          setChanged={setChanged}
          changed={changed}
        />
        <AuthorityLine
          pageName="공지사항 목록보기"
          pageEng="noticeList"
          check={data?.noticeList}
          setChanged={setChanged}
          changed={changed}
        />
        <AuthorityLine
          pageName="공지사항 작성하기"
          pageEng="noticePost"
          check={data?.noticePost}
          setChanged={setChanged}
          changed={changed}
        />
        <AuthorityLine
          pageName="결제"
          pageEng="paymentList"
          check={data?.paymentList}
          setChanged={setChanged}
          changed={changed}
        />
        <AuthorityLine
          pageName="환불"
          pageEng="refundList"
          check={data?.refundList}
          setChanged={setChanged}
          changed={changed}
        />
        <AuthorityLine
          pageName="주최자"
          pageEng="userList"
          check={data?.userList}
          setChanged={setChanged}
          changed={changed}
        />
        <AuthorityLine
          pageName="마이페이지"
          pageEng="mypage"
          check={data?.mypage}
          setChanged={setChanged}
          changed={changed}
        />
        <AuthorityLine
          pageName="관리자"
          pageEng="master"
          check={data?.master}
          setChanged={setChanged}
          changed={changed}
        />
        <AuthorityLine
          pageName="디바이스"
          pageEng="device"
          check={data?.device}
          setChanged={setChanged}
          changed={changed}
        />
        <AuthorityLine
          pageName="비디오 분석결과"
          pageEng="testerVideoDebugging"
          check={data?.testerVideoDebugging}
          setChanged={setChanged}
          changed={changed}
        />
        <AuthorityLine
          pageName="응시자 리포트 데이터"
          pageEng="testerReportData"
          check={data?.testerReportData}
          setChanged={setChanged}
          changed={changed}
        />
        <AuthorityLine
          pageName="서버상태 확인 및 복구"
          pageEng="serverStatus"
          check={data?.serverStatus}
          setChanged={setChanged}
          changed={changed}
        />
        <AuthorityLine
          pageName="프로모션"
          pageEng="promotion"
          check={data?.promotion}
          setChanged={setChanged}
          changed={changed}
        />
        <AuthorityLine
          pageName="쿠폰"
          pageEng="couponList"
          check={data?.couponList}
          setChanged={setChanged}
          changed={changed}
        />
        <AuthorityLine
          pageName="적립금 지급 목록보기"
          pageEng="creditProvideList"
          check={data?.creditProvideList}
          setChanged={setChanged}
          changed={changed}
        />
        <AuthorityLine
          pageName="적립금 지급하기"
          pageEng="creditProvide"
          check={data?.creditProvide}
          setChanged={setChanged}
          changed={changed}
        />
      </div>
    </StMemberAuthority>
  );
};

const StMemberAuthority = styled.div`
  display: grid;
  gap: 30px;
  width: 500px;
  /* margin: 0 auto; */
  .pages {
    border: 1px solid black;
  }
  .authorityHeader {
    background-color: #15254e;
    color: white;
  }
  .authorityHeader {
    display: grid;
    grid-template-columns: 200px 100px;
    align-items: center;
    gap: 200px;
    width: 500px;
    height: 35px;
    font-size: 20px;
    text-align: center;
    .checkBox {
    }
  }
  .authorityItem {
  }
`;

export default MemberAuthority;

import React from "react";

export const ArrowDown = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_197_4798"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="2"
        y="6"
        width="16"
        height="9"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.46012 7.02991C3.21762 6.78408 2.82178 6.78074 2.57595 7.02324C2.33095 7.26574 2.32845 7.66158 2.57095 7.90741L8.96261 14.3891C9.23845 14.6691 9.60761 14.8232 10.0009 14.8232C10.3934 14.8232 10.7634 14.6691 11.0393 14.3891L17.4301 7.90741C17.5493 7.78574 17.6093 7.62741 17.6093 7.46824C17.6093 7.30741 17.5468 7.14574 17.4234 7.02324C17.1776 6.78074 16.7818 6.78408 16.5393 7.02991L10.1484 13.5107C10.0959 13.5657 10.0334 13.5732 10.0009 13.5732C9.96845 13.5732 9.90511 13.5657 9.85261 13.5107L3.46012 7.02991Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_197_4798)">
        <rect
          y="20"
          width="20"
          height="20"
          transform="rotate(-90 0 20)"
          fill={color}
          fill-opacity="0.5"
        />
      </g>
    </svg>
  );
};

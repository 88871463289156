import { MathComponent } from "mathjax-react";
import React, { useState } from "react";
import { EditableMathField, StaticMathField, addStyles } from "react-mathquill";
import { styled } from "styled-components";
import ExcelReader from "../../atomic/organism/ExcelReader";

function SingleSection({ children }) {
  // console.log(children);
  // console.log(React.Children.toArray(children));
  return children;
}

const fruits = [
  { id: 1, name: "apple" },
  { id: 2, name: "pear" },
];

const TestPage = () => {
  const example = String.raw`\sum_\int_{-\infty}^{\infty}e^{-x}\ dx`;
  const [latex, setLatex] = useState("sum\frac{1}{\\sqrt{2}}\\cdot 2");
  const [excelFile, setExcelFile] = useState();

  const a = [1, 2, 3, 2, 6, 7];
  let b = a;
  // b[1] = 9;
  console.log(a[1]);

  addStyles();

  // const xlsxFile = require("read-excel-file/node");

  return (
    <>
      <SingleSection>
        {/* 수식 만들기 예제 중 */}
        <div>
          {/* <MathComponent tex={example} display={true} />
          <EditableMathField
            latex={example}
            onChange={(mathField) => {
              setLatex(mathField.latex());
            }}
          />
          <StaticMathField></StaticMathField> */}
        </div>
        <div></div>
        <ExcelReader />
      </SingleSection>
    </>
  );
};

const StVideo = styled.div`
  width: 529.5px;
  /* height: 353px; */
  margin: 200px 0 0 200px;
`;

const Title = styled.div``;

export default TestPage;

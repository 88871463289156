import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import PageHeader from "../organism/PageHeader";
import { Filter } from "./RefundTemplate";
import { useNavigate, useParams } from "react-router-dom";
import useGetUserList, {
  UserDataGet,
  UserListApiResponse,
} from "../../hook/api/useGetUserList";
import UserTable from "../organism/UserTable";
import Input from "../atom/Input";
import DivButton from "../atom/DivButton";
import OnChangeInput from "../atom/OnchangeInput";

const UserListTemplate = () => {
  const filter: Filter = {
    use: false,
  };

  const { data } = useGetUserList();
  const userSearchRef = useRef<HTMLInputElement | null>(null);

  const [showList, setShowList] = useState<UserListApiResponse | null>();
  const [tempList, setTempList] = useState<UserListApiResponse | null>();
  const [searchForm, setSearchForm] = useState<string | null>(null);

  useEffect(() => {
    setShowList(data);
  }, [data]);

  const navigate = useNavigate();
  const moveToSpecificPage = () => {
    navigate("/common/user");
  };

  const searchUserEmail = () => {
    if (searchForm) {
      const filteredHosts = data?.hosts.filter((value) =>
        value.email.includes(searchForm)
      ) as UserDataGet[];

      setShowList({ ...tempList, hosts: filteredHosts || undefined });
    } else {
      setShowList(data);
    }
  };

  useEffect(() => {
    searchUserEmail();
  }, [searchForm]);

  return (
    <StTemplate>
      <PageHeader title="주최자 리스트" search={false} filter={filter} />
      <div className="searchBox">
        <OnChangeInput
          width={300}
          height={30}
          ref={userSearchRef}
          onchange={(e) => setSearchForm(e.target.value)}
        />
      </div>
      <div className="userOverflow">
        <UserTable
          onClick={moveToSpecificPage}
          headerList={[
            "주최자 이름",
            "이메일",
            "서비스 플랜",
            "회사명",
            "가입 일자",
          ]}
          items={showList?.hosts}
        />
      </div>
    </StTemplate>
  );
};

const StTemplate = styled.div`
  display: grid;
  gap: 30px;
  .userOverflow {
    height: 700px;
    overflow-x: hidden;
  }
  .searchBox {
    display: flex;
  }
`;

export default UserListTemplate;

import React from "react";
import MasterTemplate from "../../atomic/template/MasterTemplate";
import { Outlet } from "react-router-dom";

const Master = () => {
  return (
    <div>
      <MasterTemplate />
    </div>
  );
};

export default Master;

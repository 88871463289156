import { UseQueryResult, useQuery } from "react-query";
import apis from "../../api/getApi";

interface DataProp {
  uid: string | undefined;
  changed: boolean;
}

interface ApiResponse {
  couponList: boolean;
  creditProvide: boolean;
  creditProvideList: boolean;
  device: boolean;
  isSuccess: boolean;
  landing: boolean;
  master: boolean;
  mypage: boolean;
  noticeList: boolean;
  noticePost: boolean;
  paymentList: boolean;
  promotion: boolean;
  qnaList: boolean;
  refundList: boolean;
  serverStatus: boolean;
  testerReportData: boolean;
  testerVideoDebugging: boolean;
  userList: boolean;
}

const useGetMemberSpecificAuthority = ({
  uid,
  changed,
}: DataProp): UseQueryResult<ApiResponse, unknown> => {
  const fetcher = async () => {
    const response = await apis.getMemberAuthority({ uid });
    return response.data;
  };
  return useQuery(["memberAuthority", changed], fetcher);
};

export default useGetMemberSpecificAuthority;

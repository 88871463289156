import styled from "styled-components";
import { INotice, INoticeListProps } from "../interface/i.notice";
import { NavigateFunction, useNavigate } from "react-router-dom";

const NoticeBoardContents = ({ notices }: { notices: INotice }) => {
  const navigate = useNavigate();

  const colorCategory =
    notices.category === "일반" ? `#F1F1F1` : "주최자" ? `#ffeddc` : `#E2EEFF`;
  const colorImportant =
    notices.isImportant === true ? `rgb(254, 224, 224)` : `white`;

    console.log(notices.noticeRegion);
    
  return (
    <StyledNoticeBoardContents color={colorImportant}>
      <input type="checkbox" className="checkbox" />
      <div
        className="noticeData"
        onClick={() => {
          viewNoticeDetail(navigate, notices);
        }}
      >
        <div className="noticeId">{notices.noticeId}</div>
        <div className="noticeTitle">{notices.title}</div>
        <div className="noticeRegion">{notices.noticeRegion === 'kr' ? "한국" : "글로벌"}</div>
        <div className="authorNickname">{notices.authorNickname ? notices.authorNickname : notices.authorUpdateNickname}</div>
        <StyledNoticeCategory color={colorCategory}>
          {notices.category}
        </StyledNoticeCategory>
        <div className="noticeCreatedAt">{notices.createdAt}</div>
      </div>
    </StyledNoticeBoardContents>
  );
};

export default NoticeBoardContents;

export const StyledNoticeBoardContents = styled.div<{ color: string }>`
  display: flex;
  height: 18px;
  padding: 12px 24px;
  align-items: center;
  gap: 30px;
  align-self: stretch;
  justify-content: flex-start;
  white-space: nowrap;
  background-color: ${(props) => props.color};

  .checkbox {
    display: flex;
    width: 32px;
    height: 15px;
    padding: 5.665px;
    justify-content: center;
    align-items: center;
  }

  .noticeData {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    cursor: pointer;

    .noticeId {
      width: 25px;
    }

    .authorNickname {
      width: 80px;
      display: flex;
      justify-content: center;
    }

    .noticeRegion {
      width: 60px;
    }

    .noticeTitle {
      display: flex;
      width: 880px;
    }

    .noticeContext {
      width: 1000px;
      display: flex;
      justify-content: flex-start;
    }

    .noticeCreatedAt {
      width: 180px;
    }
  }
`;

export const StyledNoticeCategory = styled.div<{ color: string }>`
  width: 50px;
  border-radius: 4px;
  background: ${(props) => props.color};
`;

const viewNoticeDetail = (navigate: NavigateFunction, notice: INotice) => {
  navigate(`/common/noticeList/detail/${notice.noticeId}`);
};

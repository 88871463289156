import { styled } from "styled-components";
import NoticeNoticePostHeader from "./NoticePostHeader";
import NoticeNoticePostContents from "./NoticePostContents";
import { useState } from "react";
import { INoticeParentProps, TExposeServer } from "../NoticeList/interface/i.notice";

const NoticePost = () => {
  const [noticeData, setNoticeData] = useState<INoticeParentProps>({
    notice: {
      noticeId: 0,
      title: "",
      context: "",
      category: "",
      isImportant: false,
      confirmImageS3KeyList: [{
        id: 0,
        key: ''
      }],
    }
  });
  const [category, setCategory] = useState<string>('');
  const [isImportant, setIsImportant] = useState<number>(0);
  const [exposeServer, setExposeServer] = useState<TExposeServer | string>('');

  const saveNoticeData = (data: INoticeParentProps) => {
    setNoticeData(data);
  };

  const saveCategory = (data: string) => {
    setCategory(data)
  };

  const saveIsImportant = (data: number) => {
    setIsImportant(data)
  }

  const saveExpose = (data: string) => {
    setExposeServer(data);
  }

  return (
    <StyledNoticePost>
      <NoticeNoticePostHeader props={{ notice: noticeData.notice, isImportant, category, exposeServer }}></NoticeNoticePostHeader>
      <NoticeNoticePostContents
        props={{ saveNoticeData, saveCategory, saveIsImportant, saveExpose }}
      ></NoticeNoticePostContents>
    </StyledNoticePost>
  );
};

export default NoticePost;

export const StyledNoticePost = styled.div`
  display: flex;
  width: 80%;
  height: 85%;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-left: 150px;
`;

import { useEffect, useState } from "react";
import MarketingHeader from "../Marketing/CommonComponents/marketingHeader";
import {
  serverStatusAllCheck,
  readComputerQuantity,
  deployApiServer,
  deplayApiScheduler,
  deplayMobileAi,
  deplayAiAnalysis,
  deplayVideoMerger,
} from "./axios";
import { styled } from "styled-components";
import Spinner from "../Tester/components/spinner";

interface HealthCheckListType {
  isLived: boolean;
  privateIp: string;
  detail: { [key: string]: number };
}
interface ServerStatusType {
  isSuccess: boolean;
  message: string;
  healthCheckList: HealthCheckListType[];
}

const ServerStatus = () => {
  const [computerNum, setComputerNum] = useState<number | null>(null);
  const [serverStatus, setServerStatus] = useState<ServerStatusType[] | null>(
    null
  );
  const deployFcArray = [
    deployApiServer,
    deplayApiScheduler,
    deplayMobileAi,
    deplayAiAnalysis,
    deplayVideoMerger,
  ];
  const [refreshLoading, setRefreshLoading] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string | null>(null);

  const itemList: string[] = [
    "API",
    "API Sch",
    "Mobile AI",
    "AI Analysis",
    "AI Video Merger",
  ];
  //컴퓨터 수량
  const getComputerQuantity = async () => {
    const data = await readComputerQuantity();
    if (data.isSuccess) {
      setComputerNum(data.totalCount);
    } else {
      setErrorText("컴퓨터 수량 못 불러옴");
    }
  };

  //서버 스크립트 모두 체크
  const getServerStatus = async () => {
    setRefreshLoading(true);
    const data = await serverStatusAllCheck();
    setRefreshLoading(false);

    if (data) {
      setServerStatus(data as ServerStatusType[]);
    }
  };

  useEffect(() => {
    getComputerQuantity();
    getServerStatus();
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      {!serverStatus ? <Spinner /> : null}
      <MarketingHeader
        title="서버 상태 확인 및 복구"
        refresh
        onClickRefreshButton={getServerStatus}
        refreshLoading={refreshLoading}
      />
      <div style={{ padding: "20px" }}>
        <TopContentBox>
          <ComputerBox>
            <div>
              <p>상용화 서버 수량</p>
              <p>{computerNum}</p>
            </div>
          </ComputerBox>
          <div className="trueFalseBox">
            <p className="trueBox">
              <span /> <span>TRUE</span>
            </p>
            <p className="falseBox">
              <span />
              <span>FALSE</span>
            </p>
          </div>
        </TopContentBox>
        <ComputerItemContainer>
          {serverStatus
            ? itemList.map((item, idx) => {
                const healthCheckListArray: HealthCheckListType[] =
                  serverStatus[idx].healthCheckList;
                return (
                  <ComputerItemBox key={idx}>
                    <div className="title">{item}</div>
                    <div className="ipBox">
                      {healthCheckListArray.map((item, idx) => (
                        <div className="ipItem" key={idx}>
                          <span>{item.privateIp}</span>
                          <span
                            className={
                              item.isLived ? "statusTure" : "statusFalse"
                            }
                          />
                        </div>
                      ))}
                    </div>

                    <div className="redistribution">
                      <button onClick={() => deployFcArray[idx]()}>
                        재배포
                      </button>
                    </div>
                  </ComputerItemBox>
                );
              })
            : null}
        </ComputerItemContainer>
      </div>
    </div>
  );
};

export default ServerStatus;

const TopContentBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  .trueFalseBox {
    font-size: 14px;
    span:first-child {
      display: inline-block;
      width: 20px;
      height: 7px;
      margin-right: 5px;
      border-radius: 2px;
    }
    .trueBox,
    .falseBox {
      display: flex;
      align-items: center;
    }
    .trueBox span:first-child {
      background-color: #31c853;
    }
    .falseBox span:first-child {
      background-color: #f93061;
    }
  }
`;
const ComputerBox = styled.div`
  border: 2px solid #fff;
  border-radius: 8px;
  box-shadow: 1px 1px 2px #6769713b;
  background: linear-gradient(#112350 85%, #0f1b3b);

  display: inline-flex;

  div {
    display: flex;
    padding: 20px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
  }
  p {
    font-family: "Medium";
  }
  p:first-child {
    padding-right: 20px;
    color: #fff;
    font-size: 16px;
  }
  p:last-child {
    color: #ff5b18;
    font-size: 20px;
  }
`;

const ComputerItemContainer = styled.div`
  padding-top: 30px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
`;
const ComputerItemBox = styled.div`
  border: 2px solid #fff;
  border-radius: 8px;
  box-shadow: 1px 1px 2px #6769713b;
  background-color: #f7f9ff;
  padding: 30px 15px 15px;
  .title {
    font-size: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #6769713b;
    color: #15254e;
    font-family: "semiBold";
    padding-left: 10px;
  }
  .ipBox {
    padding: 10px;
    .ipItem {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      span:first-child {
        color: #565a63;
      }
      span:last-child {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 5px;
      }
      .statusTure {
        background-color: #31c853;
      }
      .statusFalse {
        background-color: #f93061;
      }
    }
  }
  .redistribution {
    margin-top: 10px;
    button {
      width: 100%;
      border: none;
      cursor: pointer;
      padding: 10px;
      border-radius: 3px;
      background-color: #dbe3fa;
      color: #15254e;
      opacity: 0.5;
    }
    button:hover {
      opacity: 1;
    }
  }
`;

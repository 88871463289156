import { styled } from "styled-components";
import { INoticeDetail } from "../interface/i.notice";
import { Viewer } from "@toast-ui/react-editor";
import '@toast-ui/editor/dist/toastui-editor-viewer.css';

const NoticeDetailContents = ({notice} : {notice: INoticeDetail}) => {
  
  return (<StyledNoticeDetailContents>
       {notice.noticeContext !== "" ? <Viewer initialValue={notice.noticeContext} /> : null}
    </StyledNoticeDetailContents>)
};

export default NoticeDetailContents;

const StyledNoticeDetailContents = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  width: 81%;
  height: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 6px;
  border-radius: 12px;

  .toastui-editor-contents {
    margin: 20px 20px;
    font-family: "semibold";
    font-size: 16px;
  }
`;

import React, { useEffect, useState } from "react";
import TextLine from "../atom/TextLine";
import { styled } from "styled-components";
import Input from "../atom/Input";
import SignupForm from "../molecule/SignupForm";
import EmailCertificate from "./EmailCertificate";
import { useNavigate } from "react-router-dom";

const SignupInputField = () => {
  const navigate = useNavigate();
  const [certificate, setCertificate] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);

  return (
    <StInputField>
      <div className="inputWrapper">
        <TextLine text={"회원가입"} fontSize={40} fontFamily="bold" />
        <TextLine
          text={
            certificate
              ? "회원가입에 필요한 개인정보를 입력해주세요"
              : "회원가입 진행을 위해 이메일 인증을 받아주세요"
          }
          margin="25px 0 60px 0 "
          fontcolor="#888888"
        />
        {certificate ? (
          <SignupForm email={email} />
        ) : (
          <EmailCertificate
            setEmail={setEmail}
            setCertificate={setCertificate}
          />
        )}
        <div className="signupBox" onClick={() => navigate(`/login`)}>
          로그인
        </div>
      </div>
    </StInputField>
  );
};

const StInputField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  min-height: calc(100vh - 75px);
  .inputWrapper {
    display: grid;
    justify-content: center;
  }
  .signupBox {
    width: 50px;
    text-align: end;
    margin-top: 12px;
    margin-left: auto;
    font-size: 16px;
    font-family: "regular";
    line-height: 20px;
    color: #888888;
    cursor: pointer;
  }
`;

export default SignupInputField;

import React from "react";
import { styled } from "styled-components";
import SpinnerIcon from "./spinnerIcon";

const Spinner: React.FC = () => {
  return (
    <StSpinnerBg>
      <SpinnerIcon width={40} height={40} />
    </StSpinnerBg>
  );
};

export default Spinner;

const StSpinnerBg = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff9a;
  display: flex;
  justify-content: center;
  align-items: center;
`;

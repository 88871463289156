interface CouponTableContentType {
  itemNum: string;
  couponTitle: string;
  createdAdmin: string;
  updatedAdmin: string | null;
  couponStatus?: string;
  promotionLan?: string;
  promotionStatus?: string;
  tableTitle?: boolean;
  onClick?: () => void;
}

const TableListItem: React.FC<CouponTableContentType> = ({
  itemNum,
  couponTitle,
  createdAdmin,
  updatedAdmin,
  couponStatus,
  promotionLan,
  promotionStatus,
  tableTitle,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`marketing_tableListItem ${tableTitle ? "tableTitle" : ""}`}
      style={{
        opacity: promotionStatus === "만료됨" ? 0.3 : 1,
      }}
    >
      <p className="itemNum">{itemNum}</p>
      <p className="couponTitle">{couponTitle}</p>
      {promotionStatus ? <p className="step">{promotionStatus}</p> : null}
      {couponStatus ? <p className="step">{couponStatus}</p> : null}
      {promotionLan ? <p className="step">{promotionLan}</p> : null}

      <p className="createdAdmin">{createdAdmin}</p>
      <p className="updatedAdmin">{updatedAdmin}</p>
    </div>
  );
};

export default TableListItem;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface ToastProps {
  toastAttribute: boolean;
  toastText: string;
  update: boolean;
}

const ToastPopup: React.FC<ToastProps> = ({
  toastAttribute,
  toastText,
  update,
}) => {
  const [realOff, setRealOff] = useState<boolean>(false);
  const toastRealOutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (toastAttribute) {
      setRealOff(true);
      if (toastRealOutRef.current) {
        clearTimeout(toastRealOutRef.current);
      }
      toastRealOutRef.current = setTimeout(() => setRealOff(false), 5000);
    } else {
      // toastRealOut();
    }
    return () => {
      if (toastRealOutRef.current) {
        clearTimeout(toastRealOutRef.current);
      }
    };
  }, [update]);

  return (
    <>
      {realOff && (
        <ToastContainer toast={toastAttribute}>
          <StToast>{toastText}</StToast>
        </ToastContainer>
      )}
    </>
  );
};

const ToastContainer = styled.div<{ toast: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 40px;
  left: 0;
  z-index: 100;
  /* bottom: ${(props) => (props.toast ? "64px" : "-80px")}; */
  opacity: ${(props) => (props.toast ? "1" : "0")};
  transition: all ${(props) => (props.toast ? "0.3s" : "1s")};
  box-sizing: border-box;
`;

const StToast = styled.div`
  /* width: 100%; */
  /* min-height: 38px; */
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "regular";
  padding: 16px 26px;
  min-width: 180px;
  max-width: 800px;
  font-size: 17px;
  line-height: 25px;
  border-radius: 28px;
`;
export default ToastPopup;

import MarketingHeader from "../../Marketing/CommonComponents/marketingHeader";
import {
  ButtonBox,
  DividingLine,
  ObjectType,
  TableContentBox,
  TableRow,
  TableTitleBox,
  TesterVideoDebuggingWrap,
} from "../TesterVideoDebugging";
import React, { useEffect } from "react";
import TextField from "../TesterVideoDebugging/components/textfield";
import apis from "../../../api/getApi";
import ListItemBox from "../TesterVideoDebugging/components/listItemBox";
import { getKeyArray } from "../TesterVideoDebugging/utils";

import Button from "../../../atomic/atom/Button";
import Spinner from "../components/spinner";

const TesterReportData: React.FC = () => {
  const [spinnerOn, setSpinnerOn] = React.useState<boolean>(false);
  const [searchResult, setSearchResult] = React.useState<ObjectType[]>([]);
  const [inputValue, setInputValue] = React.useState<ObjectType>({
    testUuid: "",
    testerId: "",
    keyword: "",
  });
  const [focusedData, setFocusedData] = React.useState<{
    [key: string]: string | boolean | number | null;
  } | null>(null);

  const getListItemData = async (event: React.FormEvent) => {
    event.preventDefault();
    setSpinnerOn(true);
    try {
      const { data } = await apis.getReportAnalysisResultList(
        inputValue.testUuid,
        inputValue.testerId,
        inputValue.keyword
      );
      if (data.isSuccess) {
        setSearchResult(data.testers);
        console.log(data);
      }
    } catch (e: any) {
      console.log(e);
    }
    setSpinnerOn(false);
  };
  const getListItemDetail = async (id: number) => {
    try {
      const { data } = await apis.getReportAnalysisResultDetail(id);
      if (data.isSuccess) {
        setFocusedData(data);
      }
    } catch (e: any) {
      console.log(e);
      setFocusedData(e.data);
    }
  };

  return (
    <>
      {spinnerOn ? <Spinner /> : null}
      <form style={{ padding: "20px" }} onSubmit={getListItemData}>
        <MarketingHeader title="응시자 리포트 데이터" />
        <TesterVideoDebuggingWrap>
          <section className="contentBox">
            {Object.keys(inputValue).map((item, idx) => {
              return (
                <TextField
                  label={`${item}`}
                  value={inputValue}
                  setterFunction={setInputValue}
                  key={idx}
                />
              );
            })}

            <ButtonBox>
              <button>검색</button>
            </ButtonBox>
          </section>
          {searchResult.length > 0 ? (
            <>
              <section className="contentBox">
                <div className="listItemContent">
                  {searchResult?.map((item, idx) => {
                    return (
                      <div
                        key={`userData` + idx}
                        onClick={() => getListItemDetail(Number(item.testerId))}
                      >
                        <ListItemBox
                          focused={focusedData?.testerId === item.testerId}
                        >
                          <ListItemBox.textItem
                            title={"Status"}
                            value={item.status}
                          />
                          <ListItemBox.textItem
                            title={"Test Name"}
                            value={item.testName}
                          />
                          <ListItemBox.textItem
                            title={"Test Uuid"}
                            value={item.testUuid}
                          />
                          <ListItemBox.textItem
                            title={"Tester Email"}
                            value={item.testerEmail}
                          />
                          <ListItemBox.textItem
                            title={"Tester Id"}
                            value={item.testerId}
                          />
                          <ListItemBox.textItem
                            title={"Tester Name"}
                            value={item.testerName}
                          />
                        </ListItemBox>
                      </div>
                    );
                  })}
                </div>
                <DividingLine />
              </section>
              <section className="contentBox">
                {focusedData ? (
                  getKeyArray(focusedData).map((item, idx) => {
                    const data =
                      typeof focusedData[item] === "string"
                        ? focusedData[item]
                        : JSON.stringify(focusedData[item]);
                    return (
                      <TableRow key={idx}>
                        <TableTitleBox>{item}</TableTitleBox>
                        <TableContentBox>{data}</TableContentBox>
                      </TableRow>
                    );
                  })
                ) : (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ color: "#c2c2c2" }}>목록을 클릭해주세요</p>
                  </div>
                )}
              </section>
            </>
          ) : (
            <div
              style={{
                width: "100%",
                display: "grid",
                gridColumn: "1/3",
                gridAutoRows: "1fr",
                boxSizing: "border-box",
                padding: "50px",
                textAlign: "center",
                color: "#adadad",
              }}
            >
              검색된 내용이 없습니다.
            </div>
          )}
        </TesterVideoDebuggingWrap>
      </form>
    </>
  );
};

export default TesterReportData;

import React from "react";
import TextLine from "../atom/TextLine";

interface propAutorityCheck {
  accessName: string;
  permission: boolean;
}

const AuthorityCheck: React.FC<propAutorityCheck> = (props) => {
  return (
    <TextLine
      text={props.accessName}
      fontcolor={props.permission ? "" : "red"}
      margin="5px 0"
    />
  );
};

export default AuthorityCheck;

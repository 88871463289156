import { useState } from "react";
import styled from "styled-components";
import { IChildBoardHeaderProps } from "../interface/i.question";

const QuestionBoardHeader = ({props} : {props: IChildBoardHeaderProps}) => {

  const dropdownEventHadler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    
    props.saveViewContents(event.target.value);

  };

  return (
    <StyledHeader>
      <div className="boardName">문의사항</div>
      <select className="dropdown" onChange={dropdownEventHadler}>
        <option className="options" value="all">
          전체
        </option>
        <option className="options" value="answer">
          답변 완료
        </option>
        <option className="options" value="waiting">
          답변 대기
        </option>
      </select>
    </StyledHeader>
  );
};

export default QuestionBoardHeader;

const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 40px;
  align-self: stretch;
  width: 100%;
  font-family: "semibold";

  .boardName {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    color: #000;
    gap: 20px;

    /* SemiBold/28 */
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 142.857% */
  }

  .dropdown {
    display: flex;
    justify-content: center;
    border-radius: 12px;
    color: white;
    height: 50px;
    font-family: "semibold";
    font-size: 20px;
    padding: 10px;
    background-color: #1a75ff;
    border: white;
    cursor: pointer;

    .options {
    }
  }
`;

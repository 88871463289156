export const dateContent = (date: string, type: string) => {
  const newDateArray = date?.split(" ");
  const dateArray = newDateArray[0]?.split("-");
  const timeArray = newDateArray[1]?.split(":");
  timeArray?.pop();
  const constDateString = ["년", "월", "일"];
  const constTimeString = ["시", "분"];

  return type === "date" ? (
    <div>
      {dateArray?.map((item, idx) => (
        <span key={idx}>
          {item}
          {constDateString[idx]}
        </span>
      ))}
    </div>
  ) : (
    <div>
      {timeArray?.map((item, idx) => (
        <span key={idx}>
          {Number(item)}
          {constTimeString[idx]}
        </span>
      ))}
    </div>
  );
};

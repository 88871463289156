import React from "react";
import styled from "styled-components";

interface ExcelProps {
  text?: string;
  width?: number;
  min_width?: number;
  min_height?: number;
  justify_content?: string;
  align_items?: string;
  font_size?: number;
  font_family?: string;
  color?: string;
  background_color?: string;
  border?: string;
  onClick?: () => void;
}

const ExcelCell: React.FC<ExcelProps> = (props) => {
  return (
    <StLine
      width={props.width}
      min_width={props.min_width}
      min_height={props.min_height}
      font_family={props.font_family}
      font_size={props.font_size}
      color={props.color}
      background_color={props.background_color}
      justify_content={props.justify_content}
      align_items={props.align_items}
      border={props.border}
      onClick={props.onClick}
    >
      {props.text}
    </StLine>
  );
};

const StLine = styled.div<ExcelProps>`
  display: flex;
  justify-content: ${(props) => props.justify_content};
  align-items: ${(props) => props.align_items};
  min-width: ${(props) => props.min_width}px;
  width: ${(props) => props.width}px;
  min-height: ${(props) => props.min_height}px;
  font-size: ${(props) => props.font_size}px;
  font-family: ${(props) => props.font_family};
  color: ${(props) => props.color};
  background-color: ${(props) => props.background_color};
  border: ${(props) => props.border};
`;

export default ExcelCell;

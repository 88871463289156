import { UseQueryResult, useQuery } from "react-query";
import apis from "../../api/getApi";

interface RequestDetailApiResponse {
  isSuccess: true;
  intro: {
    introId: number;
    introName: string;
    introGroup: string;
    introEmail: string;
    introPhone: string;
    introExpectTester: number;
    introVisitPath: string;
    introContext: string | TrustedHTML;
    introLegalAgree: boolean;
    introMarketingAgree: boolean;
    introAnswer: boolean;
    introAdminUuid: string;
    introAt: string;
  };
}

interface DataProp {
  introId: string | undefined;
}

const useGetRequestDetail = ({
  introId,
}: DataProp): UseQueryResult<RequestDetailApiResponse, unknown> => {
  const fetcher = async () => {
    const response = await apis.getRequestDetail({ introId });
    return response.data;
  };
  return useQuery(["requestDetail"], fetcher);
};

export default useGetRequestDetail;

import React, { useState } from "react";
import SideBarHead from "../molecule/SideBarHead";
import SideBarDevider from "./SideBarDevider";

interface ButtonsProps {
  props: {
    header: string;
    location?: string;
    pageList?: { pageName: string; location: string }[];
  };
}

const SideBarButtons: React.FC<ButtonsProps> = ({ props }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <SideBarHead
        text={props.header}
        fontSize={16}
        clicked={false}
        padding="16px 24px 16px 32px"
        location={props.location}
        locationpath={props.location?.split("/")[2]}
        setOpen={setOpen}
        open={open}
      />
      {open &&
        props.pageList?.map((value, index) => {
          return (
            <SideBarDevider
              key={index}
              text={value.pageName}
              location={value.location}
              locatePath={value.location?.split("/")[2]}
              fontSize={15}
              padding="16px 16px 16px 60px"
            />
          );
        })}
    </div>
  );
};

export default SideBarButtons;

import React from "react";
import styled from "styled-components";
import { device } from "../../device";

interface buttonProps {
  margin?: string;
  font_size?: number;
  font_color?: string;
  font_family?: string;
  border_radius?: number;
  border?: string;
  tab_width?: string;
  tab_height?: number;
  tab_fontsize?: number;
  tab_fontcolor?: string;
  tab_fontfamily?: string;
  tab_border_radius?: number;
  tab_border?: string;
  tab_margin?: string;
  text?: string;
  placeholdercolor?: string;
  padding?: string;
  tab_padding?: string;
  backgroundcolor?: string;
  cursor?: string;
  disabled?: boolean;
  onclick?: () => void;
}

const OrangeButton: React.FC<buttonProps> = (props) => {
  return (
    <StButton
      type="submit"
      margin={props.margin}
      onClick={props.onclick}
      font_size={props.font_size}
      font_color={props.font_color}
      font_family={props.font_family}
      border={props.border}
      border_radius={props.border_radius}
      tab_width={props.tab_width}
      tab_height={props.tab_height}
      tab_fontsize={props.tab_fontsize}
      tab_fontcolor={props.tab_fontcolor}
      tab_fontfamily={props.tab_fontfamily}
      tab_border={props.tab_border}
      tab_border_radius={props.tab_border_radius}
      tab_padding={props.tab_padding}
      tab_margin={props.tab_margin}
      padding={props.padding}
      backgroundcolor={props.backgroundcolor}
      cursor={props.cursor}
      disabled={props.disabled}
    >
      {props.text}
    </StButton>
  );
};

const StButton = styled.button<buttonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.margin};
  width: 204px;
  height: 52px;
  padding: 15px 0;
  font-size: 17px;
  font-family: "medium";
  color: white;
  border-radius: 43px;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.backgroundcolor ? props.backgroundcolor : "#ed893e"};
  border: none;
  @media ${device.tabMob} {
    width: ${(props) => props.tab_width};
    height: ${(props) => props.tab_height}px;
    padding: ${(props) => props.tab_padding};
    font-size: ${(props) => props.tab_fontsize}px;
    font-family: ${(props) => props.tab_fontfamily};
    margin: ${(props) => props.tab_margin};
    color: ${(props) => props.tab_fontcolor};
    border: none;
  }
  cursor: ${(props) => props.cursor};
`;

export default OrangeButton;

import React from "react";
import Main from "./components/Main";
import Information from "./components/Information";
import { styled } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Landing: React.FC = () => {
  return (
    <Container>
      <Information />
      <Main />
    </Container>
  );
};

export default Landing;

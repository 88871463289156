import React, { useEffect, useReducer, useState } from "react";
import { styled } from "styled-components";
import { testerData } from "./morkData";
import TextField from "./components/textfield";
import ListItemBox from "./components/listItemBox";
import { getKeyArray } from "./utils";
import apis from "../../../api/getApi";
import MarketingHeader from "../../Marketing/CommonComponents/marketingHeader";
import Button from "../../../atomic/atom/Button";
import Spinner from "../components/spinner";

export interface ObjectType {
  [key: string]: string;
}
const TesterVideoDebugging: React.FC = () => {
  const [spinnerOn, setSpinnerOn] = React.useState<boolean>(false);
  const [searchResult, setSearchResult] = React.useState<ObjectType[]>([]);
  const [inputValue, setInputValue] = React.useState<ObjectType>({
    testUuid: "",
    testerId: "",
    keyword: "",
  });
  const [focusedData, setFocusedData] = useState<{
    [key: string]: string | boolean | number;
  } | null>(null);
  const [titleArray, setTitleArray] = useState<string[]>();

  const getVideoAnalysisResultListData = async () => {
    setSpinnerOn(true);
    try {
      const { data } = await apis.getVideoAnalysisResultList(
        inputValue.testUuid,
        inputValue.testerId,
        inputValue.keyword
      );
      if (data.isSuccess) {
        setSearchResult(data.testers);
        console.log(data);
      }
    } catch (e) {
      console.log(e);
    }
    setSpinnerOn(false);
  };
  const getVideoAnalysisResultDetailData = async (
    testerId: string,
    eTime: string
  ) => {
    try {
      const { data } = await apis.getVideoAnalysisResultDetail(testerId, eTime);
      if (data.isSuccess) {
        setFocusedData(data);
      }
    } catch (e: any) {
      console.log(e);
      setFocusedData(e.data);
    }
  };
  const onClickListItem = (
    idx: number,
    testerId: string,
    testerEnvStartTime: string
  ): void => {
    getVideoAnalysisResultDetailData(testerId, testerEnvStartTime);
  };
  const onClickSearchButton = async (e: React.FormEvent) => {
    await e.preventDefault();
    getVideoAnalysisResultListData();
  };
  return (
    <>
      {spinnerOn ? <Spinner /> : null}
      <form style={{ padding: "20px" }} onSubmit={onClickSearchButton}>
        <MarketingHeader title="비디오 분석 결과" />
        <TesterVideoDebuggingWrap>
          <section className="contentBox">
            {Object.keys(inputValue).map((item, idx) => {
              return (
                <TextField
                  label={`${item}`}
                  value={inputValue}
                  setterFunction={setInputValue}
                  key={idx}
                />
              );
            })}
            <ButtonBox>
              <button>검색</button>
            </ButtonBox>
          </section>
          {searchResult.length > 0 ? (
            <>
              <section className="contentBox">
                <div className="listItemContent">
                  {searchResult?.map((item, idx) => {
                    return (
                      <div
                        onClick={() =>
                          onClickListItem(
                            idx,
                            item.testerId,
                            item.testerEnvStartTime
                          )
                        }
                        key={`userData` + idx}
                      >
                        <ListItemBox
                          focused={focusedData?.testerId === item.testerId}
                        >
                          <ListItemBox.textItem
                            title={"Test Name"}
                            value={item.testName}
                          />
                          <ListItemBox.textItem
                            title={"Test Step"}
                            value={item.testStep}
                          />
                          <ListItemBox.textItem
                            title={"Tester Id"}
                            value={item.testerId}
                          />
                          <ListItemBox.textItem
                            title={"Tester Name"}
                            value={item.testerName}
                          />
                          <ListItemBox.textItem
                            title={"Tester Analysis Step"}
                            value={item.testerAnalysisStep}
                          />
                          <ListItemBox.textItem
                            title={"Tester EnvStart Time"}
                            value={item.testerEnvStartTime}
                          />
                        </ListItemBox>
                      </div>
                    );
                  })}
                </div>
                <DividingLine />
              </section>
              <section className="contentBox">
                {focusedData ? (
                  getKeyArray(focusedData).map((item, idx) => {
                    const data =
                      typeof focusedData[item] === "string"
                        ? focusedData[item]
                        : JSON.stringify(focusedData[item]);
                    return (
                      <TableRow key={idx}>
                        <TableTitleBox>{item}</TableTitleBox>
                        <TableContentBox>
                          {data}
                          {getKeyArray(focusedData)[idx].endsWith("Time") ? (
                            <span
                              style={{
                                color: "rgb(63, 107, 218)",
                                fontSize: 12,
                                paddingLeft: 5,
                              }}
                            >{`(UTC -0)`}</span>
                          ) : null}
                        </TableContentBox>
                      </TableRow>
                    );
                  })
                ) : (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ color: "#c2c2c2" }}>목록을 클릭해주세요</p>
                  </div>
                )}
              </section>
            </>
          ) : (
            <div
              style={{
                width: "100%",
                display: "grid",
                gridColumn: "1/3",
                gridAutoRows: "1fr",
                boxSizing: "border-box",
                padding: "50px",
                textAlign: "center",
                color: "#adadad",
              }}
            >
              검색된 내용이 없습니다.
            </div>
          )}
        </TesterVideoDebuggingWrap>
      </form>
    </>
  );
};

export const TesterVideoDebuggingWrap = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 3fr;
  grid-template-rows: 76px;
  flex-grow: 1;
  height: 100%;

  .contentBox:nth-child(1) {
    grid-column: 1 / 3;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 200px;
    & > div {
      padding: 15px 15px 20px;
      background-color: #f7f9ff;
      border-bottom: 1px solid #e4e4e5;
    }
    div:nth-child(3) {
      position: relative;
    }
    div:nth-child(3)::after {
      content: "테스트이름, 테스터 이름, 테스터 이메일 등을 포함해서 검색할 수 있습니다.";
      position: absolute;
      bottom: 3px;
      left: 15px;
      font-size: 12px;
    }
    input {
      /* height: 25px; */
      font-size: 16px;
      background-color: #fff;
      border: 1px solid #15254e;
      flex-grow: 1;
      border-radius: 0;
    }
  }
  .contentBox:nth-child(2) {
    display: grid;
    height: 70vh;
    overflow: scroll;
    grid-template-columns: 75fr 1fr;
  }
  .contentBox:nth-child(3) {
    padding: 20px;
    height: 70vh;
    overflow: scroll;
    box-sizing: border-box;
  }
  .listItemContent {
    padding: 20px;
    width: 400px;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-right: 30px;
  align-items: center;
  button {
    height: 40px;
    padding: 0 40px;
    border: 1px solid #0c152b;
    border-radius: 3px;
    background-color: #15254e;
    color: #fff;
    box-shadow: 1px 1px 2px #4c50593b;
    cursor: pointer;
  }
`;

export const DividingLine = styled.div`
  border-radius: "100px";
  background: linear-gradient(270deg, rgb(21 37 78 / 20%), transparent);
`;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 3.5fr;
  div {
    padding: 10px;
    border: 1px solid #babfc9;
  }
`;

export const TableTitleBox = styled.div`
  background-color: #e9ecf6;
  margin-top: -1px;
`;
export const TableContentBox = styled.div`
  margin-left: -1px;
  margin-top: -1px;
`;

export default TesterVideoDebugging;

import React from "react";
import styled from "styled-components";

const Container = styled.div`
  /* background-color: #e2e2e2;
  border: 1px solid black; */
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  weekName: string;
}

const WeekBox = ({ weekName }: Props) => {
  return (
    <Container>
      <p>{weekName}</p>
    </Container>
  );
};

export default WeekBox;

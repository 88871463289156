import { useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import customAxios from "../../../../api/handler";
import { confirmKeyList } from "../../../../atom";
import { StyledNoticePostHeader } from "../../../NoticeWrite/NoticePostHeader";
import { INoticeParentProps } from "../../interface/i.notice";

const NoticeUpdateHeader = ({ props }: { props: INoticeParentProps }) => {
  const { notice } = props;
  const navigate = useNavigate();
  const finalCategory = props.category;
  const finalIsImportant = props.isImportant;
  const finalExposeServer = props.exposeServer;

  const [confirmImageS3KeyList, setConfirmImageS3KeyList] =
    useRecoilState(confirmKeyList);

  const keyList = confirmImageS3KeyList.map((v, idx) => {
    return v.key;
  });

  useEffect(() => {
    console.log();
  }, [confirmKeyList, confirmImageS3KeyList]);

  useEffect(() => {

  })

  const submitHandler = async () => {

    const finalUpdateNotice = {
      noticeId: notice.noticeId,
        category: finalCategory,
        context: notice.context,
        title: notice.title,
        confirmImageS3KeyList: keyList,
        isImportant: finalIsImportant,
        region: finalExposeServer
    };

    await customAxios
      .patch(`/admin/proc/notices/update`, finalUpdateNotice)
      .then((res) => {
        console.log(res);

        noticeDeatil(navigate, notice.noticeId);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const navigateNoticeDeatil = () => {
    noticeDeatil(navigate, notice.noticeId);
  }

  return (
    <StyledNoticePostHeader>
      <div className="headers">
        <div className="noticeTitle">공지사항 수정(이미지는 업로드는 하단의 Upload를 통해 진행)
        <div className="discription">
          내용은 왼쪽 아래 WYSIWYG Click =&gt; 다시 Markdown Click
        </div>
        </div>
      </div>
      <div className="noticeButton">
        <button className="cancelButton" onClick={navigateNoticeDeatil}>취소</button>
        <button type="submit" className="saveButton" onClick={submitHandler}>
          저장
        </button>
      </div>
    </StyledNoticePostHeader>
  );
};

export default NoticeUpdateHeader;

const noticeDeatil = (navigate: NavigateFunction, noticeId: number) => {
  navigate(`/common/noticeList/detail/${noticeId}`);
};


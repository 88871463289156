import { UseQueryResult, useQuery } from "react-query";
import apis from "../../api/getApi";

interface MypageApiResponse {
  isSuccess: true;
  admin: {
    name: string;
    phoneNumber: string;
    email: string;
    department: string;
    createdAt: string;
    lastLoginDate: string;
    nickName: string;
    permission: [
      {
        accessName: string;
        permission: boolean;
      },
    ];
  };
}

const useGetMyPage = (): UseQueryResult<MypageApiResponse, unknown> => {
  const fetcher = async () => {
    const response = await apis.getMyPage();
    return response.data;
  };
  return useQuery(["mypage"], fetcher);
};

export default useGetMyPage;

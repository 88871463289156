import React from "react";
import { styled } from "styled-components";
import MemberTemplate from "../../atomic/template/MemberTemplate";
import MemberAuthority from "../../atomic/organism/MemberAuthority";

const Member = () => {
  return (
    <StMember>
      <MemberTemplate />
    </StMember>
  );
};

const StMember = styled.div``;

export default Member;

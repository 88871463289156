import React from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

interface ButtonProp {
  page: number;
  nowpage: number;
  type?: string;
  bigType?: string;
}

const PaginationButton: React.FC<ButtonProp> = ({
  page,
  nowpage,
  type,
  bigType,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <StPage
      onClick={() => {
        if (search.substring(7)) {
          navigate(`/${bigType}/${type}/${page}?email=${search.substring(7)}`);
        } else {
          navigate(`/${bigType}/${type}/${page}`);
        }
      }}
      page={page}
      nowpage={nowpage}
    >
      {page}
    </StPage>
  );
};

const StPage = styled.div<ButtonProp>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: ${(props) =>
    props.page === props.nowpage ? "#20315B" : ""};
  color: ${(props) => (props.page === props.nowpage ? "white" : "#BBBBBB")};
  border-radius: 8px;
  margin: 0 10px;
  cursor: pointer;
`;

export default PaginationButton;

import { useEffect, useState } from "react";
import QuestionBoard from "./QuestionBoard";
import styled from "styled-components";
import customAxios from "../../../api/handler";
import {
  IQuestionCountResponse,
  IQuestionList,
  IQuestionListResponse,
} from "../interface/i.question";
import QuestionBoardHeader from "./QusetionBoardHeader";
import useToast from "../../../hook/useToast";

const Question = () => {
  const [questionList, setQuestionList] = useState<Array<IQuestionList>>(
    new Array<IQuestionList>()
  );
  const [pageShowCount, setPageShowCount] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [viewContents, setViewContents] = useState<string>("all");
  const pageCount = 15;
  const pageList = [];
  const showToast = useToast();

  const getQuestionList = async () => {
    
    await customAxios
      .get(
        `/admin/proc/questions?page=${page}&pageCount=${pageCount}&viewContents=${viewContents}`
      )
      .then(({ data }) => {
        const { questionList } = data as IQuestionListResponse;

        setQuestionList(questionList);
      })
      .catch(( e :unknown) => {

        showToast({ message : "문의사항 읽을권한 없음"})
      });
  };

  const getQuestionCount = async () => {
    await customAxios.get(`/admin/proc/questions/count?page=${page}&pageCount=${pageCount}&viewContents=${viewContents}`).then(({ data }) => {
      const { count } = data as IQuestionCountResponse;
      
      setPageShowCount(parseInt(count) / 15 + 1);
    });
  };

  useEffect(() => {
    getQuestionList();
  }, [page]);

  useEffect(() => {
    getQuestionList();
    getQuestionCount()
  }, [viewContents]);

  useEffect(() => {
    getQuestionCount();
  }, []);

  const saveViewContents = (data: string) => {
    setViewContents(data);
  };

  for (let index = 1; index <= pageShowCount; index++) {
    pageList.push(index);
  }

  return (
    <StyledList>
      <QuestionBoardHeader props={{ saveViewContents }}></QuestionBoardHeader>
      <QuestionBoard questionList={questionList}></QuestionBoard>
      <div className="pageNumber">
        {pageList.map((v, idx) => {
          return (
            <button
              key={idx + 1}
              className="page"
              onClick={() => {
                setPage(v);
              }}
            >
              {v}
            </button>
          );
        })}
      </div>
    </StyledList>
  );
};

export default Question;

const StyledList = styled.div`
  display: flex;
  width: 90%;
  height: 85%;
  padding: 48px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .pageNumber {
    width: 80%;
    height: auto;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    font-family: "semibold";

    .page {
      color: white;
      border-radius: 8px;
      background: var(--app-bgbg, #20315b);
      cursor: pointer;
    }
  }
`;

import { UseQueryResult, useQuery } from "react-query";
import apis from "../../api/getApi";

interface PermissionDetailApiResponse {
  isSuccess: true;
  host: {
    hostEmail: string;
    hostName: string;
    hostGroupName: string;
    hostPhoneNumber: string;
    hostUuid: string;
    hostCreatedAt: string;
    hostBirthdayAt: string;
    isApproved: boolean;
    hostGroupRegistryNumber: string;
    isAgreedOfMarketing: boolean;
    hostJobPosition: string;
  };
}

interface DataProp {
  uid: string | undefined;
}

const useGetPermissionDetail = ({
  uid,
}: DataProp): UseQueryResult<PermissionDetailApiResponse, unknown> => {
  const fetcher = async () => {
    const response = await apis.getPermissionDetail({ uid });
    return response.data;
  };
  return useQuery(["permissionDetail"], fetcher);
};

export default useGetPermissionDetail;

import apis from "../../../../api/getApi";
import React from "react";
import styled from "styled-components";
import Header from "../header";
import { useNavigate } from "react-router-dom";
import { type } from "os";

type RequestCreditType = {
  requestId: number;
  enterpriseName: string;
  email: string;
  credits: number; // 적립금(C)
  currencyAmount: number; // 실제 금액
  currencyType: "won" | "dollar"; // 화페 단위
  requestDate: string;
  status: "pending" | "charge" | "recovery";
};

type ListReponseType = {
  isSuccess: boolean;
  requestCredits: RequestCreditType[];
};
const CreditRequest: React.FC = () => {
  const navigate = useNavigate();
  const [searchWord, setSearchWord] = React.useState<string>("");
  const [listData, setListData] = React.useState<RequestCreditType[] | []>([]);

  const getList = async () => {
    try {
      const response = await apis.getRequestCredits();
      const data = response.data as ListReponseType;
      console.log(data);
      setListData(data.requestCredits);
    } catch (e: any) {
      console.log("모든 리스트 불러오기 실패:", e);
    }
  };

  const searchList = async () => {
    try {
      const response = await apis.getSearchRequestCredits(searchWord);
      const data = response.data as ListReponseType;
      setListData(data.requestCredits);
    } catch (e: any) {
      console.log(e);
    }
  };
  React.useEffect(() => {
    getList();
  }, []);

  return (
    <div
      style={{
        height: `calc(100vh - 75px)`,
        backgroundColor: "#e9ebf1",
        padding: 20,
        boxSizing: "border-box",
        overflow: "scroll",
        scrollbarWidth: "none",
        borderTop: "1px solid #e9ebf1",
        minWidth: "calc(100vw - 300px)",
      }}
    >
      <Header
        title={"적립금 요청 내역"}
        searchContent
        searchWord={searchWord}
        setSearchWord={setSearchWord}
        onClickSearchButton={searchList}
        iconBgColor="rgb(250 153 54)"
      />
      <div
        style={{
          height: "85%",
          backgroundColor: "#fff",
          borderRadius: 8,
          boxShadow: "1px 1px 3px #15254e3d",
          marginTop: 20,
          padding: 30,
          overflow: "scroll",
          scrollbarWidth: "none",
        }}
      >
        <ListTable num={5}>
          <div className="title">
            <p>기업명.</p>
          </div>
          <div className="title">
            <p>이메일.</p>
          </div>
          <div className="title">
            <p>요청 적립금.</p>
          </div>
          <div className="title">
            <p>요청일.</p>
          </div>
          <div className="title">
            <p>처리 상태.</p>
          </div>
        </ListTable>

        {listData.map((item, idx) => {
          return (
            <ListTable
              num={5}
              key={idx}
              onClick={() =>
                navigate(
                  `/marketing/creditRequestDetail?requestId=${item.requestId}`
                )
              }
            >
              <div>
                <p>{item.enterpriseName}</p>
              </div>
              <div>
                <p>{item.email}</p>
              </div>
              <div>
                <p>{item.credits}</p>
              </div>
              <div>
                <p>{item.requestDate}</p>
              </div>
              <div>
                <p>
                  {item.status === "charge"
                    ? "승인 완료"
                    : item.status === "pending"
                    ? "승인 대기"
                    : "회수 완료"}
                </p>
              </div>
            </ListTable>
          );
        })}
      </div>
    </div>
  );
};

export default CreditRequest;

export const ListTable = styled.div<{ num: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.num}, calc(100% / 5));
  position: relative;
  &:not(:first-child):hover::after {
    content: "자세히보기";
    /* font-family: "semiBold"; */
    color: #15254e;
    font-size: 20px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75px;
    background-color: rgba(240, 242, 245, 0.9);
    border-radius: 8px;
    cursor: pointer;
  }

  div.title {
    box-sizing: border-box;
    padding: 10px 0 15px;
    border-bottom: 1px solid #b1b3ba;
    color: #5b5d64;
    margin: 0 5px;
  }

  div:not(.title) {
    /* box-sizing: border-box; */
    /* padding: 30px 5px 30px; */
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 5px;
    height: 75px;
    border-bottom: 1px solid #e9ebf1;
    max-width: 100%;

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 300px;
    }
  }
`;

const SearchBox = styled.div`
  input {
    outline: none;
    border: none;
    background-color: rgb(227, 232, 245);
    font-size: 18px;
    font-family: "regular";
    padding: 10px 20px;
    border-radius: 5px;
    width: 300px;
    box-shadow: inset 1px 1px 4px #15254e28;
  }
  button {
    outline: none;
    border: none;
    color: #fff;
    border-radius: 5px;
    font-size: 18px;
    font-family: "regular";
    padding: 9px 19px;
    background-color: #2c448f;
    box-shadow: 1px 1px 1px #15254e28;
    border: 1px solid #f9f9f9;
    box-sizing: border-box;
    margin-left: 10px;
  }
`;

import React, { Dispatch, SetStateAction } from "react";
import { CouponDataType } from "./couponMakeModal";
export interface InputType<T> {
  type: string;
  value: T;
  dataKey: keyof T;
  setValue: Dispatch<SetStateAction<T>>;
  defaultValue?: string;
}

const CouponInput: React.FC<InputType<CouponDataType>> = ({
  type,
  value,
  dataKey,
  setValue,
  defaultValue,
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue;
    if (type === "number") {
      newValue = Number(e.target.value).toString().replaceAll("^0+", "");
    } else {
      newValue = e.target.value;
    }
    setValue({ ...value, [dataKey]: newValue });
  };
  return (
    <input
      type={type}
      value={value[dataKey]}
      onChange={onChange}
      min={0}
      defaultValue={defaultValue}
      className="marketing_input"
    />
  );
};

export default CouponInput;

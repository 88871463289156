import React, { useState } from "react";
import { styled } from "styled-components";
import ModalBack from "../atom/ModalBack";
import TextLine from "../atom/TextLine";
import { ArrowDown } from "../../img/ArrowDown";

interface forStyle {
  width: number;
  height: number;
  fontSize?: number;
  fontfamily?: string;
  fontcolor?: string;
  border?: string;
  borderradius?: number;
  padding?: string;
  margin?: string;
  listwidth?: number;
  listheight?: number;
  listpadding?: string;
  listboxborder?: string;
  listboxborderradius?: number;
}

interface htmlProps extends forStyle {
  list: string[];
  placeholder: string;
  choosed?: string | null;
  setChoose: React.Dispatch<React.SetStateAction<string | null>>;
}

const DropDown: React.FC<htmlProps> = (props) => {
  const [dropDownOn, setDropDownOn] = useState(false);

  const dropDownClick = () => {
    setDropDownOn(!dropDownOn);
  };

  return (
    <>
      {dropDownOn && <ModalBack clickfunction={dropDownClick} />}
      <StDropdown
        width={props.width}
        height={props.height}
        listwidth={props.listwidth}
        listheight={props.listheight}
        listpadding={props.listpadding}
        listboxborder={props.listboxborder}
        listboxborderradius={props.listboxborderradius}
        fontSize={props.fontSize}
        fontcolor={props.fontcolor}
        fontfamily={props.fontfamily}
        padding={props.padding}
        margin={props.margin}
        border={props.border}
        borderradius={props.borderradius}
      >
        <div className="chooseShow" onClick={dropDownClick}>
          <TextLine
            text={props.choosed ? props.choosed : props.placeholder}
            fontcolor={props.choosed ? "black" : "#2d2c2c85"}
            //   onclick={dropDownClick}
            lineheight={22}
          />
          <ArrowDown color="black" />
        </div>
        {dropDownOn && (
          <div className="dropDownList">
            {props.list.map((value, index) => {
              return (
                <div
                  className="dropDownItem"
                  key={index}
                  onClick={() => {
                    props.setChoose(value);
                    setDropDownOn(false);
                  }}
                >
                  {value}
                </div>
              );
            })}
          </div>
        )}
      </StDropdown>
    </>
  );
};

const StDropdown = styled.div<forStyle>`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  font-size: ${(props) => props.fontSize}px;
  font-family: ${(props) => props.fontfamily};
  color: ${(props) => props.fontcolor};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderradius}px;
  box-sizing: border-box;
  .chooseShow {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .dropDownList {
    position: absolute;
    z-index: 2;
    top: -1px;
    left: -1px;
    background-color: white;
    width: calc(100% + 2px);
    border: ${(props) => props.listboxborder};
    border-radius: ${(props) => props.listboxborderradius}px;
    box-sizing: border-box;
    .dropDownItem {
      display: flex;
      align-items: center;
      width: ${(props) => props.listwidth}px;
      height: ${(props) => props.listheight}px;
      padding: ${(props) => props.listpadding};
      font-size: ${(props) => props.fontSize}px;
      box-sizing: border-box;
    }
  }
`;

export default DropDown;

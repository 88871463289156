import React, { useState } from "react";

import CouponMakeModal from "./couponMakeModal";
import { useNavigate } from "react-router-dom";
import apis from "../../../../api/getApi";

import MarketingHeader from "../../CommonComponents/marketingHeader";
import { styled } from "styled-components";
import TableListItem from "../../CommonComponents/tableListItem";

interface CouponListItemType {
  createdAdmin: string;
  status: string;
  title: string;
  updatedAdmin: string | null;
  uuid: string;
}
const CouponList: React.FC = () => {
  const navigate = useNavigate();

  const [couponCreationModalOn, setCouponCreationModalOn] =
    React.useState<boolean>(false);
  const [couponListItemData, setCouponListItemData] =
    useState<CouponListItemType[]>();

  const onClickPluseIcon = () => {
    setCouponCreationModalOn(!couponCreationModalOn);
  };
  const onClickModalOff = () => {
    setCouponCreationModalOn(false);
  };
  const onClickListItem = (couponUuid: string) => {
    navigate({
      pathname: "/marketing/couponDetail",
      search: `?couponUuid=${couponUuid}`,
    });
  };

  const getCoupons = async () => {
    try {
      const { data } = await apis.getCouponList();
      console.log(data);
      setCouponListItemData(data.coupons);
    } catch (e) {
      console.log(e);
    }
  };
  React.useEffect(() => {
    if (!couponCreationModalOn) {
      getCoupons();
    }
  }, [couponCreationModalOn]);

  return (
    <>
      <div style={{ padding: "20px" }}>
        <MarketingHeader
          title="쿠폰 관리"
          iconDisplay={true}
          onClickPluseIcon={onClickPluseIcon}
        />
        <TableListItem
          itemNum="no."
          couponTitle="쿠폰명"
          couponStatus="진행상황"
          createdAdmin="생성인"
          updatedAdmin="최종 수정인"
          tableTitle
        />
        <TableContainer>
          {couponListItemData?.map((item, idx) => {
            const newStatus =
              item.status === "scheduled"
                ? "진행 예정"
                : item.status === "ongoing"
                ? "진행 중"
                : item.status === "expired"
                ? "만료"
                : "알수없음";
            return (
              <TableListItem
                key={item.title}
                itemNum={(idx + 1).toString()}
                couponTitle={item.title.toString()}
                createdAdmin={item.createdAdmin}
                updatedAdmin={item.updatedAdmin}
                couponStatus={newStatus}
                onClick={() => onClickListItem(item.uuid.toString())}
              />
            );
          })}
        </TableContainer>
      </div>
      {couponCreationModalOn ? (
        <CouponMakeModal
          onClickPluseIcon={onClickPluseIcon}
          onClickModalOff={onClickModalOff}
        />
      ) : null}
    </>
  );
};

export const TableContainer = styled.div`
  height: calc(100vh - 300px);
  overflow: scroll;
  min-height: 300px;
`;
export default CouponList;

import React, { useEffect, useLayoutEffect, useState } from "react";
// import { Route, Routes } from 'react-router-dom';
// import Landing from './page/Landing';
// import Qna from './page/Qna';
// import Announcement from './page/Announcement';
import Layout from "./atomic/template/Layout";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./page/Login";

import "./App.css";
import { getCookie, setCookie } from "./Cookie";
import Landing from "./page/Landing";
import NoticeList from "./page/NoticeList";
import MyPage from "./page/MyPage";
import UrgentAnalysis from "./page/UrgentAnalysis";
import Device from "./page/Device";
import Tester from "./page/Tester";
import PromotionList from "./page/Marketing/Promotion/PromotionList";
import CouponList from "./page/Marketing/Coupon/CouponList";
import CouponDetail from "./page/Marketing/Coupon/CouponDetail";
import CreditProvideList from "./page/Marketing/CreditProvide/CreditProvideList";
import CreditProvide from "./page/Marketing/CreditProvide/CreditProvide";
import NoticePost from "./page/NoticeWrite";
import RefundList from "./page/Refund";
import PaymentList from "./page/Payment";
import { StyleSheetManager } from "styled-components";
import TestPage from "./page/TestPage";
import UserList from "./page/UserList";
import User from "./page/User";
import Signup from "./page/Signup";
import TesterVideoDebugging from "./page/Tester/TesterVideoDebugging";
import ToastPopup from "./atomic/organism/ToastPopup";
import { useRecoilValue } from "recoil";
import { toastMessage, toastOn, toastUpdate } from "./atom";
import customAxios, { AxiosInterceptor, baseURL } from "./api/handler";
import RefundSpecific from "./modal/RefundSpecific";
import NoticeDetail from "./page/NoticeList/NoticeDetail/NoticeDetail";
import PromotionDetail from "./page/Marketing/Promotion/PromotionDetail";
import Master from "./page/Master";
import SignupConfirm from "./modal/SignupConfirm";
import NoticeUpdate from "./page/NoticeList/NoticeDetail/NoticeUpdate";
import TesterReportData from "./page/Tester/TesterReportData";
import ServerStatus from "./page/ServerStatus";
import Question from "./page/Qna/QuestionBoards/Question";
import QuestionDetail from "./page/Qna/QuestionDetails/QuestionDetail";
import Member from "./page/Member";
import useToast from "./hook/useToast";
import QuestionAnswer from "./modal/QuestionAnswer";
import { OutletDataProvider } from "./atomic/organism/OutletDataContext";
import TestList from "./page/TestList";
import CreatePromotion from "./page/Marketing/Promotion/CreatePromotion";
import CorporationPermission from "./page/CorporationPermission";
import ConsultRequest from "./page/ConsultRequest";
import ConsultRequestDetail from "./page/ConsultRequestDetail";
import CorporationPermissionDetail from "./page/CorporationPermissionDetail";
import CreditRequest from "./page/Marketing/CreditProvide/CreditRequest";
import CreditRequestDetaiil from "./page/Marketing/CreditProvide/CreditRequestDetail";
import CreditDetail from "./page/Marketing/CreditDetail";

const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const showToast = useToast();
  const toastON = useRecoilValue<boolean>(toastOn);
  const toastMESSAGE = useRecoilValue<string>(toastMessage);
  const toastUPDATE = useRecoilValue<boolean>(toastUpdate);

  // 토큰이 없고 회원가입 페이지가 아닐 경우 로그인 페이지로 이동 시켜줌
  useEffect(() => {
    if (!getCookie("accessToken") && location.pathname !== "/signup") {
      navigate(`/login`);
    }
  }, [location.pathname]);

  // 엑세스 토큰이 없고 리프레쉬 토큰이 없을 경우 로그인 페이지로 이동 시켜줌
  useEffect(() => {
    if (!getCookie("accessToken") && !getCookie("refreshToken")) {
      navigate("/login");
    } else if (getCookie("refreshToken") && !getCookie("accessToken")) {
      customAxios
        .post(`${baseURL}admin/proc/auth/access-token`, {
          refreshToken: getCookie("refreshToken"),
        })
        .then(({ data }) => {
          setCookie("accessToken", data.accessToken, {
            expires: new Date(Date.now() + 2592000),
          });
          navigate(`/common/landing`);
        })
        .catch(() => {
          showToast({ message: "토큰을 불러오는데 에러가 났다!" });
        });
    }
  }, [getCookie("accessToken")]);

  useLayoutEffect(() => {
    if (getCookie("refreshToken")) {
      customAxios
        .post(`/admin/proc/auth/page-access`, {
          page: window.location.pathname.split("/")[2],
        })
        .then(() => {})
        .catch(() => {
          showToast({ message: "해당 페이지에 접근 권한이 없습니다!!!" });
          navigate(`/common/landing`);
        });
    }
  }, [window.location.pathname]);

  return (
    <>
      <AxiosInterceptor>
        <StyleSheetManager shouldForwardProp={(prop) => prop !== "clicked"}>
          <>
            <OutletDataProvider>
              <Layout>
                <Routes>
                  <Route path="/" element={<Landing />} />
                  <Route path="/common/landing" element={<Landing />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/common/qnaList" element={<Question />}></Route>
                  <Route
                    path="/common/qnaList/detail/:questionUuid"
                    element={<QuestionDetail />}
                  >
                    <Route
                      path=":questionComponentUuid/:hostUuid"
                      element={<QuestionAnswer />}
                    />
                    <Route
                      path=":questionComponentUuid/:hostUuid/update"
                      element={<QuestionAnswer />}
                    />
                  </Route>
                  <Route path="/common/noticeList" element={<NoticeList />} />
                  <Route path="/common/noticePost" element={<NoticePost />} />
                  <Route
                    path="/common/noticeList/update/:noticeId"
                    element={<NoticeUpdate />}
                  />
                  <Route
                    path="/common/noticeList/detail/:noticeId"
                    element={<NoticeDetail />}
                  />
                  <Route path="/common/mypage" element={<MyPage />} />
                  <Route
                    path="/common/paymentList/:nowPage"
                    element={<PaymentList />}
                  >
                    <Route path=":uid" element={<RefundSpecific />} />
                  </Route>
                  <Route
                    path="/common/refundList/:nowPage"
                    element={<RefundList />}
                  >
                    <Route
                      path=":hostUid/:groupUid/"
                      element={<RefundSpecific />}
                    />
                  </Route>
                  <Route
                    path="/develope/urgentAnalysis"
                    element={<UrgentAnalysis />}
                  />
                  <Route path="/develope/device" element={<Device />} />
                  <Route path="/develope/tester" element={<Tester />} />
                  <Route
                    path="/develope/testerVideoDebugging"
                    element={<TesterVideoDebugging />}
                  />
                  <Route
                    path="/develope/testerReportData"
                    element={<TesterReportData />}
                  />
                  <Route
                    path="/develope/serverStatus"
                    element={<ServerStatus />}
                  />

                  <Route
                    path="/common/userList/:nowPage"
                    element={<UserList />}
                  />
                  <Route path="/common/userList/user/:uid" element={<User />} />
                  <Route
                    path="/marketing/promotion"
                    element={<PromotionList />}
                  />
                  <Route
                    path="/marketing/promotionDetail"
                    element={<PromotionDetail />}
                  />
                  <Route
                    path="/marketing/createPromotion"
                    element={<CreatePromotion />}
                  />
                  <Route
                    path="/marketing/couponList"
                    element={<CouponList />}
                  />
                  <Route
                    path="/marketing/couponDetail"
                    element={<CouponDetail />}
                  />
                  <Route
                    path="/marketing/creditProvideList"
                    element={<CreditProvideList />}
                  />
                  <Route
                    path="/marketing/creditProvide"
                    element={<CreditProvide />}
                  />
                  <Route
                    path="/marketing/corporationPermission/:nowPage"
                    element={<CorporationPermission />}
                  />
                  <Route
                    path="/marketing/corporationPermission/:nowPage/:uid"
                    element={<CorporationPermissionDetail />}
                  />
                  <Route
                    path="/marketing/consultRequest/:nowPage"
                    element={<ConsultRequest />}
                  />
                  <Route
                    path="/marketing/consultRequest/:nowPage/:introId"
                    element={<ConsultRequestDetail />}
                  />
                  {/* 여기까지 */}

                  <Route
                    path="/marketing/creditRequest"
                    element={<CreditRequest />}
                  />
                  <Route
                    path="/marketing/creditRequestDetail"
                    element={<CreditRequestDetaiil />}
                  />
                  <Route
                    path="/marketing/creditDetail"
                    element={<CreditDetail />}
                  />
                  <Route path="/common/testList" element={<TestList />} />
                  <Route path="/common/master" element={<Master />}>
                    <Route path="confirm/:uid" element={<SignupConfirm />} />
                  </Route>
                  <Route
                    path="/common/master/member/:uid"
                    element={<Member />}
                  />
                  <Route path="/testPage" element={<TestPage />} />
                </Routes>
              </Layout>
            </OutletDataProvider>
          </>
          <ToastPopup
            toastAttribute={toastON}
            toastText={toastMESSAGE}
            update={toastUPDATE}
          />
        </StyleSheetManager>
      </AxiosInterceptor>
    </>
  );
};

export default App;

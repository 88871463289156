import React from "react";
import { styled } from "styled-components";
import PageHeader from "../organism/PageHeader";
import TextLine from "../atom/TextLine";
import MemberInfo from "../organism/MemberInfo";
import MemberAuthority from "../organism/MemberAuthority";

const MemberTemplate = () => {
  return (
    <StMemberTemplate>
      <PageHeader title="멤버페이지" search={false} />
      <div className="bucket">
        <MemberInfo />
        <MemberAuthority />
      </div>
    </StMemberTemplate>
  );
};

const StMemberTemplate = styled.div`
  display: grid;
  gap: 50px;
  padding: 48px;
  .bucket {
    display: grid;
    grid-template-columns: 500px 500px;
    gap: 150px;
  }
`;

export default MemberTemplate;

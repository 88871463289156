import React from "react";
import { styled } from "styled-components";

interface TextProps {
  text?: string | number;
  fontSize?: number;
  fontcolor?: string;
  fontFamily?: string;
  lineheight?: number;
  textAlign?: string;
  margin?: string;
  width?: number;
  height?: number;
  border?: string;
  borderradius?: number;
  backgroundcolor?: string;
  onclick?: () => void;
}

const TextLine: React.FC<TextProps> = (props) => {
  return (
    <StText
      fontSize={props.fontSize}
      fontFamily={props.fontFamily}
      fontcolor={props.fontcolor}
      textAlign={props.textAlign}
      margin={props.margin}
      lineheight={props.lineheight}
      width={props.width}
      height={props.height}
      border={props.border}
      borderradius={props.borderradius}
      backgroundcolor={props.backgroundcolor}
      onClick={props.onclick}
    >
      {props.text}
    </StText>
  );
};

const StText = styled.div<TextProps>`
  font-size: ${(props) => props.fontSize}px;
  font-family: ${(props) => props.fontFamily};
  color: ${(props) => props.fontcolor};
  line-height: ${(props) => props.lineheight}px;
  text-align: ${(props) => props.textAlign};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderradius}px;
  background-color: ${(props) => props.backgroundcolor};
`;

export default TextLine;

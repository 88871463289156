import axios from "axios";
import { getCookie, setCookie } from "../Cookie";
import useToast from "../hook/useToast";

export const baseURL = "https://prod.office-server.proctormatic.com/";
const customAxios = axios.create({
  baseURL: "https://prod.office-server.proctormatic.com/",
});

// export const baseURL = "http://localhost:65530/";
// const customAxios = axios.create({
//   baseURL: "http://localhost:65530/",
// });

const AxiosInterceptor = ({ children }) => {
  const showToast = useToast();
  customAxios.interceptors.request.use(
    (config) => {
      const accessToken = getCookie("accessToken");
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      return config;
    },
    ({ data }) => {
      return Promise.reject(data);
    }
  );

  customAxios.interceptors.response.use(
    (data) => {
      return data;
    },
    (error) => {
      const formattedError = error?.response || error;
      if (formattedError?.status === 520 && getCookie("refreshToken")) {
        axios
          .post(`${baseURL}admin/proc/auth/access-token`, {
            refreshToken: getCookie("refreshToken"),
          })
          .then(({ data }) => {
            setCookie("accessToken", data.accessToken, {
              expires: new Date(Date.now() + 1800000),
            });
          })
          .catch((data) => {
            console.log(data);
            showToast({ message: "토큰을 불러오는데 에러가 났다!" });
          });
      } else {
        return Promise.reject(formattedError);
      }
    }
  );

  return children;
};

export default customAxios;
export { AxiosInterceptor };

import React, { useRef, useState } from "react";
import Input from "../atom/Input";
import Button from "../atom/Button";
import { styled } from "styled-components";
import TextLine from "../atom/TextLine";
import DropDown from "./DropDown";
import customAxios from "../../api/handler";
import useToast from "../../hook/useToast";
import { useNavigate } from "react-router-dom";

interface SignupProps {
  email: string | null;
}

const SignupForm: React.FC<SignupProps> = ({ email }) => {
  const department = ["개발", "마케팅", "디자인"];
  const [departChoose, setDepartChoose] = useState<string | null>(null);
  const nameRef = useRef<HTMLInputElement | null>(null);
  const nickNameRef = useRef<HTMLInputElement | null>(null);
  const phoneNumberRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const passwordConfirmRef = useRef<HTMLInputElement | null>(null);
  const showToast = useToast();
  const navigate = useNavigate();
  const submitHandler = (event: React.FormEvent) => {
    customAxios
      .post(`/admin/proc/auth/sign-up`, {
        email: email,
        name: nameRef.current?.value,
        nickName: nickNameRef.current?.value,
        department: departChoose,
        phoneNumber: phoneNumberRef.current?.value,
        password: passwordRef.current?.value,
        checkPassword: passwordConfirmRef.current?.value,
      })
      .then(() => {
        showToast({
          message: "회원가입을 신청했습니다. 관리자분께 문의 남겨주세요.",
        });
        navigate(`/login`);
      })
      .catch(() => {
        showToast({ message: "안돼 돌아가" });
      });
    event.preventDefault();
  };

  return (
    <StForm onSubmit={submitHandler}>
      <div className="wrapper">
        <div className="inputBox">
          <TextLine text="개인 정보" />
          <Input
            width={360}
            height={48}
            fontSize={16}
            fontcolor=""
            placeholderText="본명"
            padding="12px 16px;"
            placeholdercolor="#888888"
            borderradius={5}
            ref={nameRef}
            border="1px solid black"
          />
          <Input
            width={360}
            height={48}
            fontSize={16}
            fontcolor=""
            placeholderText="닉네임"
            padding="12px 16px;"
            placeholdercolor="#888888"
            borderradius={5}
            ref={nickNameRef}
            border="1px solid black"
          />
          <DropDown
            list={department}
            placeholder="부서"
            choosed={departChoose}
            setChoose={setDepartChoose}
            border="1px solid black"
            borderradius={4}
            width={360}
            height={48}
            listwidth={360}
            listheight={48}
            listpadding="12px 16px"
            listboxborder="1px solid black"
            listboxborderradius={4}
            padding="12px 16px"
          />
          <Input
            width={360}
            height={48}
            fontSize={16}
            fontcolor=""
            placeholderText="핸드폰번호"
            padding="12px 16px;"
            placeholdercolor="#888888"
            borderradius={5}
            ref={phoneNumberRef}
            border="1px solid black"
          />{" "}
          <Input
            width={360}
            height={48}
            fontSize={16}
            fontcolor=""
            placeholderText="비밀번호"
            type="password"
            padding="12px 16px;"
            placeholdercolor="#888888"
            borderradius={5}
            ref={passwordRef}
            border="1px solid black"
          />{" "}
          <Input
            width={360}
            height={48}
            fontSize={16}
            fontcolor=""
            placeholderText="비밀번호 확인"
            type="password"
            padding="12px 16px;"
            placeholdercolor="#888888"
            borderradius={5}
            ref={passwordConfirmRef}
            border="1px solid black"
          />{" "}
        </div>
        <Button
          width={360}
          height={56}
          fontSize={18}
          fontcolor="white"
          text="회원가입 신청"
          padding="10px 16px"
          border="none"
          borderradius={8}
          backgroundcolor="#1A75FF"
        />
      </div>
    </StForm>
  );
};

const StForm = styled.form`
  display: grid;
  justify-content: center;
  .wrapper {
    .inputBox {
      display: grid;
      gap: 8px;
      margin-bottom: 24px;
    }
    .signupBox {
      text-align: end;
      margin-top: 12px;
      font-size: 16px;
      font-family: "regular";
      line-height: 20px;
      color: #888888;
      cursor: pointer;
    }
  }
`;

export default SignupForm;

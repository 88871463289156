import React, { useState } from "react";
import ChargeTable from "../organism/ChargeTable";
import { styled } from "styled-components";
import PageHeader from "../organism/PageHeader";
import { Filter } from "./RefundTemplate";
import { useNavigate, useParams } from "react-router-dom";
import ListTable from "../organism/ListTable";
import useGetUserSpecific from "../../hook/api/useGetUserSpecific";
import TextLine from "../atom/TextLine";

const UserTemplate = () => {
  const filter: Filter = {
    use: false,
  };

  const navigate = useNavigate();
  const uuid = useParams().uid;
  const { data } = useGetUserSpecific({ uuid });

  /** 개인 정보 */
  const keysInd: string[] = [
    "이름",
    "아이디",
    "요금제",
    "개인 포인트",
    "개인 환불 현금",
    "개인 환불 포인트",
    "언어",
    "회원가입 일자",
  ];

  /** 기업 정보 */
  const keysEnt: string[] = [
    "기업명",
    "기업 전화번호",
    "기업 적립금",
    "기업 포인트",
    "기업 환불 현금",
    "기업 환불 적립금",
    "기업 환불 포인트",
  ];

  /** 시험 정보 */
  const keysTest: string[] = ["예정 시험", "진행 시험", "완료 시험"];

  return (
    <StTemplate>
      <PageHeader
        title={"주최자 (" + data?.host?.name + ")"}
        search={false}
        filter={filter}
      />
      <div className="bucket">
        <div>
          <TextLine
            text="개인정보"
            fontSize={25}
            fontFamily="medium"
            margin="0 0 20px 0"
          />
          <ListTable
            keys={keysInd}
            items={{
              name: data?.host?.name,
              email: data?.host?.email,
              plan: data?.host?.plan,
              point: data?.host?.indPoint ? data?.host?.indPoint : "-",
              refundCash:
                typeof data?.host?.indRefundCash === "number"
                  ? data?.host?.indRefundCash
                  : "-",
              refundPoint:
                typeof data?.host?.indRefundPoint === "number"
                  ? data?.host?.indRefundPoint
                  : "-",
              lang: data?.host?.language,
              signupDate: data?.host?.signUpAt,
            }}
          />
        </div>
        <div>
          <TextLine
            text="기업 정보"
            fontSize={25}
            fontFamily="medium"
            margin="0 0 20px 0"
          />
          <ListTable
            keys={keysEnt}
            items={
              data?.host?.enterprise
                ? {
                    companyName: data?.host?.enterprise?.name,
                    phoneNumber: data?.host?.enterprise?.phoneNumber,
                    credit: data?.host?.enterprise?.credit,
                    point: data?.host?.enterprise?.point,
                    refundCash: data?.host?.enterprise?.refundCash,
                    refundCredit: data?.host?.enterprise?.refundCredit,
                    refundPoint: data?.host?.enterprise?.refundPoint,
                  }
                : {
                    companyName: "-",
                    phoneNumber: "-",
                    credit: "-",
                    point: "-",
                    refundCash: "-",
                    refundCredit: "-",
                    refundPoint: "-",
                  }
            }
          />
        </div>
        <div>
          <TextLine
            text="시험 정보"
            fontSize={25}
            fontFamily="medium"
            margin="0 0 20px 0"
          />
          <ListTable
            keys={keysTest}
            items={{
              scheduledTest: data?.expectedTestCount,
              progressTest: data?.inProgressTestCount,
              completeTest: data?.completedTestCount,
            }}
          />
        </div>
      </div>
    </StTemplate>
  );
};

const StTemplate = styled.div`
  display: grid;
  gap: 30px;
  .bucket {
    display: grid;
    grid-template-columns: 640px 640px;
    gap: 40px;
    margin-top: 40px;
  }
`;

export default UserTemplate;

import { UseQueryResult, useQuery } from "react-query";
import apis from "../../api/getApi";

interface Dataprop {
  uid: string | undefined;
}

interface ConsiderUserDetail {
  uuid: string;
  name: string;
  nickName: string;
  email: string;
  phoneNumber: string;
  department: string;
  signUpDate: string;
}

const useGetConsiderUserDetail = ({
  uid,
}: Dataprop): UseQueryResult<ConsiderUserDetail, unknown> => {
  const fetcher = async () => {
    const response = await apis.getMasterConsiderUserDetail({ uid });
    return response.data;
  };
  return useQuery(["mastserUserDetail"], fetcher);
};

export default useGetConsiderUserDetail;

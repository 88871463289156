import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import ModalBack from "../atomic/atom/ModalBack";
import customAxios from "../api/handler";
import useScrollLock from "../hook/useScrollLock";
import { useNavigate, useParams } from "react-router-dom";
import TextLine from "../atomic/atom/TextLine";
import DivButton from "../atomic/atom/DivButton";

interface Data {
  cancelCreditsTradeAt: string;
  cancelGroupId: string;
  cancelStatus: string;
  failedRefundCredits: number;
  notRefundableCredits: number;
  notRefundableCreditsDetail: {
    expiredCredits: number;
    eventCredits: number;
    rewardCredits: number;
  };
  successRefundCredits: number;
  targetCredits: number;
  totalCredits: number;
}

const RefundSpecific = () => {
  const navigate = useNavigate();
  const hostUid = useParams().hostUid;
  const targetGroupUuid = useParams().groupUid;
  const [reportSpecificData, setReportSpecificData] = useState<Data | null>(
    null
  );

  /** 환불 상세 정보 받아오기 */
  const getRefundInformation = () => {
    customAxios
      .post(`/admin/proc/pays/refund/detail`, {
        hostParentUuid: hostUid,
        targetGroupUuid: targetGroupUuid,
      })
      .then(({ data }) => {
        setReportSpecificData(data?.refundDetail);
      })
      .catch((response) => {
        console.log(response);
      });

    // console.log(reportSpecificData);
  };
  useScrollLock();

  useEffect(() => {
    getRefundInformation();
  }, []);

  return (
    <>
      <ModalBack backgroudcolor="#7270706a" />
      <StRefundSpecific error={reportSpecificData?.cancelStatus || undefined}>
        <TextLine text="환불 상세내역" fontSize={30} fontFamily="medium" />
        <div className="specificList">
          <div className="line">
            <TextLine text="총 적립금" fontSize={12} fontcolor="#818181" />
            &nbsp;
            <TextLine
              text={reportSpecificData?.totalCredits}
              fontSize={12}
              fontcolor="#818181"
            />
            &nbsp;
            <TextLine text="원" fontSize={12} fontcolor="#818181" />
          </div>
          <div className="line">
            <TextLine
              text={reportSpecificData?.targetCredits}
              fontSize={28}
              fontFamily="bold"
              fontcolor="#2276DC"
              lineheight={34}
            />
            <TextLine text="원" lineheight={41} margin="0 0 0 5px" />
          </div>
          <TextLine
            text={reportSpecificData?.cancelStatus}
            fontSize={16}
            fontcolor={
              reportSpecificData?.cancelStatus === "환불 오류"
                ? "#C83B38"
                : "#2276DC"
            }
            textAlign="center"
          />
          <TextLine
            text={reportSpecificData?.cancelCreditsTradeAt}
            fontSize={16}
            fontcolor="#818181"
            textAlign="center"
          />
          <div className="infoTable">
            <div className="columnUpBox">
              <div className="specificItemColumn">
                <TextLine
                  text="환불 신청을 완료한 금액"
                  fontSize={12}
                  fontcolor="#818181"
                  width={150}
                />
                <TextLine
                  text={reportSpecificData?.successRefundCredits}
                  fontSize={19}
                  fontFamily="bold"
                />
              </div>
              <div className="specificItemColumn">
                <TextLine
                  text="환불 신청을 실패한 금액"
                  fontSize={12}
                  fontcolor="#818181"
                  width={150}
                />
                <TextLine
                  text={reportSpecificData?.failedRefundCredits}
                  fontSize={19}
                  fontFamily="bold"
                  fontcolor="#C83B38"
                />
              </div>
            </div>
            <div className="specificItemColumn">
              <TextLine
                text="환불이 불가한 총 금액"
                fontSize={12}
                fontcolor="#818181"
                width={150}
              />
              <TextLine
                text={reportSpecificData?.notRefundableCredits}
                fontSize={19}
                fontFamily="bold"
              />
            </div>
          </div>
          <ul>
            <TextLine text="환불 불가 금액 내역" fontSize={13} />
            <div className="notRefundableList">
              <li className="specificLine">
                <TextLine
                  text="이벤트 적립금"
                  fontSize={14}
                  fontcolor="#55595f"
                />
                <TextLine
                  text={
                    reportSpecificData?.notRefundableCreditsDetail.eventCredits
                  }
                  textAlign="end"
                />
              </li>
              <li className="specificLine">
                <TextLine
                  text="사라진 적립금"
                  fontSize={14}
                  fontcolor="#55595f"
                />
                <TextLine
                  text={
                    reportSpecificData?.notRefundableCreditsDetail
                      .expiredCredits
                  }
                  textAlign="end"
                />
              </li>
              <li className="specificLine">
                <TextLine
                  text="추가 적립금"
                  fontSize={14}
                  fontcolor="#55595f"
                />
                <TextLine
                  text={
                    reportSpecificData?.notRefundableCreditsDetail.rewardCredits
                  }
                  textAlign="end"
                />
              </li>
            </div>
          </ul>
        </div>
        <DivButton
          text="확인"
          backgroundcolor="#15254e"
          fontcolor="white"
          width={140}
          height={40}
          borderradius={10}
          margin="auto auto 10px"
          onclick={() => window.history.back()}
        />
      </StRefundSpecific>
    </>
  );
};

const StRefundSpecific = styled.div<{ error: string | undefined }>`
  position: fixed;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 540px;
  height: 720px;
  padding: 48px;
  box-sizing: border-box;
  background-color: white;
  .specificList {
    display: grid;
    gap: 20px;
    margin-top: 50px;
    .line {
      display: flex;
      justify-content: center;
    }
    .specificLine {
      display: grid;
      grid-template-columns: auto 170px;
      gap: 20px;
    }
    .infoTable {
      display: flex;
      flex-direction: column;
      border: 1px solid
        ${(props) => (props.error === "환불 오류" ? "red" : "black")};
      border-radius: 12px;
      padding: 0 16px;
      .columnUpBox {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-bottom: 1px solid #d6d9dd;
      }

      .specificItemColumn {
        display: grid;
        justify-content: center;
        text-align: center;
        gap: 10px;
        padding: 16px 0;
      }
    }
    .notRefundableList {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin: 25px 0;
    }
  }
`;

export default RefundSpecific;

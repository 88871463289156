import React from "react";
import RefundTemplate from "../../atomic/template/RefundTemplate";
import { styled } from "styled-components";
import { Outlet } from "react-router-dom";

const RefundList: React.FC = () => {
  return (
    <>
      <StRefundList>
        <RefundTemplate />
      </StRefundList>
      <Outlet />
    </>
  );
};

const StRefundList = styled.div`
  height: 100%;
  overflow-x: hidden;
  flex-direction: column;
  padding: 48px;
  box-sizing: border-box;
`;

export default RefundList;

import React from "react";
import Header from "../CreditProvide/header";
import apis from "../../../api/getApi";
import { ListTable } from "../CreditProvide/CreditRequest";
import { useNavigate } from "react-router-dom";

type ApprovedHistoryType = {
  adminName: string;
  approvalDate: string;
  bonusCredits: number;
  comments: string;
  credits: number;
  enterpriseName: string;
  hostName: string;
  id: number;
  status: string;
  targetReqId: number;
};
const CreditDetail = () => {
  const [eleWidth, setEleWidth] = React.useState<number | undefined>(0);
  const [boxPosition, setboxPosition] = React.useState<string | number>(0);
  const [list, setList] = React.useState<ApprovedHistoryType[]>();
  const navigate = useNavigate();

  const keys = {
    adminName: "작업자",
    approvalDate: "처리일",
    bonusCredits: "추가적립금",
    comments: "비고",
    credits: "요청 적립금",
    enterpriseName: "기업명",
    hostName: "요청인",
    status: "처리상태",
    targetReqId: "요청 ID",
  };

  const getDetailList = async () => {
    try {
      const response = await apis.getCreditApprovalDetail();
      const data = response.data as {
        isSuccess: boolean;
        approvedHistory: ApprovedHistoryType[];
      };
      setList(data.approvedHistory);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getDetailList();
  }, []);
  return (
    <div
      style={{
        height: `calc(100vh - 75px)`,
        backgroundColor: "#e9ebf1",
        padding: 20,
        boxSizing: "border-box",
        overflow: "scroll",
        borderTop: "1px solid #e9ebf1",
        maxWidth: "calc(100vw - 300px)",
      }}
    >
      <Header title={"적립금 승인/회수 내역"} iconBgColor={"rgb(240 67 67)"} />
      <div
        ref={(e) => {
          setEleWidth(e?.clientWidth);
        }}
        style={{
          transform: `translateX(${boxPosition})`,
          // transform: `translateX(calc(-100% - 20px))`,

          transition: "transform 0.6s ease",
          display: "grid",
          gridTemplateColumns: "repeat(2, 100%)",
          gap: 20,
        }}
      >
        <div
          style={{
            height: "85%",
            backgroundColor: "#fff",
            borderRadius: 8,
            boxShadow: "1px 1px 3px #15254e3d",
            marginTop: 20,
            padding: 30,
            overflow: "scroll",
          }}
        >
          <ListTable num={5}>
            <div className="title">
              <p>기업명.</p>
            </div>
            <div className="title">
              <p>요청 지급액</p>
            </div>
            <div className="title">
              <p>보너스 지급액</p>
            </div>
            <div className="title">
              <p>처리 상태.</p>
            </div>
            <div className="title">
              <p>관리자.</p>
            </div>
          </ListTable>
          {list?.map((item, idx) => {
            return (
              <ListTable
                num={5}
                key={idx}
                onClick={() => {
                  navigate(
                    `/marketing/creditRequestDetail?requestId=${item.targetReqId}`
                  );
                }}
              >
                <div>
                  <p>{item.enterpriseName}</p>
                </div>
                <div>
                  <p>{item.credits}</p>
                </div>
                <div>
                  <p>{item.bonusCredits}</p>
                </div>
                <div>
                  <p>
                    {item.status === "charge"
                      ? "승인 완료"
                      : item.status === "pending"
                      ? "승인 대기"
                      : "회수 완료"}
                  </p>
                </div>
                <div>
                  <p>{item.adminName}</p>
                </div>
              </ListTable>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CreditDetail;

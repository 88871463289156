import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { PromotionBannerContentType } from "../CreatePromotion/index";

const BannerPrevidewContainer: React.FC<PromotionBannerContentType> = (
  promotionBannerContent
) => {
  const previewContentBoxRef = React.useRef<HTMLInputElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(850);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === previewContentBoxRef.current) {
          const newWidth = entry.contentRect.width;
          setContainerWidth(newWidth);
        }
      }
    });

    if (previewContentBoxRef.current) {
      resizeObserver.observe(previewContentBoxRef.current);
    }

    return () => {
      if (previewContentBoxRef.current) {
        resizeObserver.unobserve(previewContentBoxRef.current);
      }
    };
  }, []);

  const pattern = /<[^>]*>([^<]+)<[^>]*>/i;

  const [availableTextItem, setAvailableTextItem] = useState<boolean[]>([]);

  useEffect(() => {
    setAvailableTextItem([]);
    Object.keys(promotionBannerContent).map((item, idx) => {
      const contentItem =
        promotionBannerContent[item as keyof PromotionBannerContentType];

      setAvailableTextItem((prev) => [
        ...prev,
        contentItem.match(pattern) ? true : false,
      ]);
    });
  }, [promotionBannerContent]);
  return (
    <PreviewContainer
      containerwidth={containerWidth?.toString()}
      banner_content={promotionBannerContent}
      available_text_item={availableTextItem}
    >
      <div
        className="previewContentBox"
        id="previewContentBox"
        ref={previewContentBoxRef}
      >
        {Object.keys(promotionBannerContent).map((item, idx) => {
          const contentItem =
            promotionBannerContent[item as keyof PromotionBannerContentType];
          return contentItem.match(pattern) ? (
            <div
              key={idx}
              className="previewContent"
              dangerouslySetInnerHTML={{
                __html: contentItem,
              }}
            />
          ) : (
            <div key={idx}></div>
          );
        })}
      </div>
    </PreviewContainer>
  );
};

export default BannerPrevidewContainer;
const PreviewContainer = styled.div<{
  containerwidth: string;
  banner_content: PromotionBannerContentType;
  available_text_item: boolean[];
}>`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;

  .previewContentBox {
    display: flex;
    justify-content: center;
    height: 40px;
    background-color: #000;
    width: 100vw;
    max-width: 100vw;
    min-width: 360px;
    border: 1px solid;
    resize: horizontal;
    overflow: auto;
    display: flex;
    flex-direction: row;
    padding: 0 18px;
    box-sizing: border-box;

    div,
    div p {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      font-size: 13px;
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    /* :nth-child(1).previewContent:after,
    :nth-child(3).previewContent:after {
      content: "|";
      color: #fff;
      font-size: 10px;
      padding: 0 13px 2px 13px;
    } */
  }

  :nth-child(1) {
  }

  ${(props) =>
    Number(props.containerwidth) < 1140 && Number(props.containerwidth) >= 620
      ? `
        :nth-child(1).previewContent{
            display:none
        }
        .previewContentBox{
          justify-content: flex-start;
        }
    `
      : Number(props.containerwidth) < 620
      ? `
      :nth-child(1).previewContent,:nth-child(2).previewContent{
            display:none
        }
        .previewContentBox{
          justify-content: flex-start;
        }
        
      `
      : ``}

  ${(props) =>
    props.available_text_item[1] && props.available_text_item[2]
      ? `
      :nth-child(2).previewContent {
        padding-right: 5px;
     
      }
       `
      : ``}

  ${(props) =>
    props.available_text_item[0] &&
    (props.available_text_item[1] ||
      props.available_text_item[2] ||
      props.available_text_item[3])
      ? `
       :nth-child(1).previewContent:after{
      content: "|";
      color: #fff;
      font-size: 10px;
      padding: 0 13px 2px 13px;
    }
       `
      : ``}

      ${(props) =>
    (props.available_text_item[1] || props.available_text_item[2]) &&
    props.available_text_item[3]
      ? `
       :nth-child(3).previewContent:after{
      content: "|";
      color: #fff;
      font-size: 10px;
      padding: 0 13px 2px 13px;
    }
       `
      : ``}
      ${(props) =>
    props.available_text_item[1] &&
    !props.available_text_item[2] &&
    props.available_text_item[3]
      ? `
       :nth-child(2).previewContent:after{
      content: "|";
      color: #fff;
      font-size: 10px;
      padding: 0 13px 2px 13px;
    }
       `
      : ``}

${(props) =>
    !props.available_text_item[1] &&
    props.available_text_item[2] &&
    props.available_text_item[3]
      ? `
       :nth-child(3).previewContent:after{
      content: "|";
      color: #fff;
      font-size: 10px;
      padding: 0 13px 2px 13px;
    }
       `
      : ``}
`;

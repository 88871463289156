import React from "react";
import { text } from "stream/consumers";
import { styled } from "styled-components";

interface buttonProps {
  width: number;
  height: number;
  fontSize?: number;
  fontcolor?: string;
  text: string;
  placeHolderColor?: string;
  border?: string;
  borderradius?: number;
  padding?: string;
  margin?: string;
  backgroundcolor?: string;
  disabled?: boolean;
  onclick?: (event: React.FormEvent) => void;
}

const Button: React.FC<buttonProps> = (props) => {
  return (
    <StButton
      type="submit"
      width={props.width}
      height={props.height}
      fontSize={props.fontSize}
      fontcolor={props.fontcolor}
      border={props.border}
      borderradius={props.borderradius}
      padding={props.padding}
      margin={props.margin}
      text={props.text}
      backgroundcolor={props.backgroundcolor}
      onClick={props.onclick}
      disabled={props.disabled}
    >
      {props.text}
    </StButton>
  );
};

const StButton = styled.button<buttonProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) => props.fontcolor};
  ::placeholder {
    color: ${(props) => props.placeHolderColor};
  }
  border-radius: ${(props) => props.borderradius}px;
  border: ${(props) => props.border};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundcolor};
  cursor: pointer;
`;

export default Button;

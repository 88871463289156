import React from "react";
import { styled } from "styled-components";
import TextLine from "../atom/TextLine";

interface ItemProps {
  values: (string | number)[];
  index?: number;
}

const ListItem: React.FC<ItemProps> = ({ values, index = 1 }) => {
  return (
    <StListItem index={index}>
      <TextLine text={values[0]} fontSize={18} />
      <TextLine text={values[1]} fontSize={18} />
    </StListItem>
  );
};

const StListItem = styled.div<{ index: number }>`
  display: grid;
  grid-template-columns: 150px auto;
  width: 600px;
  gap: 150px;
  padding: 10px 20px;
  background-color: ${(props) => (props.index % 2 === 0 ? "#F9F9F9" : "")};
`;

export default ListItem;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PageHeader from "../../atomic/organism/PageHeader";
import Pagination from "../../atomic/organism/Pagination";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Filter } from "../../atomic/template/RefundTemplate";
import customAxios from "../../api/handler";
import PermissionTable from "../../atomic/organism/PermissionTable";
import useGetPermissionList from "../../hook/api/useGetPermissionList";

const CorporationPermission = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const emailRef = useRef<HTMLInputElement | null>(null);

  const filter: Filter = {
    use: true,
    filterList: ["통합", "기업", "개인"],
  };
  const pageCount = 18;
  const nowPage = Number(useParams().nowPage);

  const [chooseFilter, setChooseFilter] = useState<number>(0);
  const [hostType, setHostType] = useState<string>("all");
  const [pageCounting, setPageCounting] = useState<number>(1); //
  const [pageShowing, setPageShowing] = useState<number[]>([]);

  const { data: permissionList } = useGetPermissionList({ nowPage, pageCount });

  const [permissionCount, setPermissionCount] = useState<number>(pageCount);

  /** 개인별 검색하기 */
  const getPersonalRefundList = (event: React.FormEvent) => {
    event.preventDefault();
    navigate(`/common/permissionList/1?email=${emailRef.current?.value}`);
  };

  /** 결제 내역 카운트 해주기 */
  const getRefundCount = () => {
    customAxios
      .get(`/admin/proc/hosts/approved/count`)
      .then(({ data }) => {
        setPermissionCount(data.count);
      })
      .catch(() => {});
  };

  /** 가입승인 요청 리스트 받아오기 */
  // const getRefundList = () => {
  //   customAxios
  //     .get(
  //       `/admin/proc/hosts/approved/list?page=${nowPage}&pageCount=${pageCount}&offset=-540`
  //     )
  //     .then(({ data }) => {
  //       setPermissionList(data);
  //     })
  //     .catch(({ data }) => {
  //       if (data?.errorCode === "AUTH-003") {
  //         setPermissionCount(0);
  //         setPermissionList([]);
  //       }
  //     });
  // };

  useEffect(() => {
    // getRefundList();
    getRefundCount();
  }, [search, nowPage, hostType]);

  useEffect(() => {
    setPageCounting(Math.ceil(permissionCount / pageCount));
  }, [permissionCount]);

  useEffect(() => {
    if (chooseFilter === 0) {
      setHostType("all");
    } else if (chooseFilter === 1) {
      setHostType("ent");
    } else if (chooseFilter === 2) {
      setHostType("ind");
    }
  }, [chooseFilter]);

  // console.log(data);

  return (
    <StTemplate>
      <PageHeader
        title="가입신청 리스트"
        search={false}
        filter={filter}
        chooseFilter={chooseFilter}
        setChooseFilter={setChooseFilter}
        searchFunction={getPersonalRefundList}
        useref={emailRef}
      />
      <PermissionTable
        headerList={[
          "신청인",
          "소속기관",
          "직책",
          "이메일",
          "전화번호",
          "신청일자",
        ]}
        items={permissionList?.approvedList}
        nowPage={nowPage}
      />
      <Pagination
        nowpage={nowPage}
        pageCounting={pageCounting}
        pageShowing={pageShowing}
        setPageShowing={setPageShowing}
        type="corporationPermission"
        bigType="marketing"
      />
    </StTemplate>
  );
};

const StTemplate = styled.div`
  display: grid;
  gap: 30px;
  padding: 40px;
  box-sizing: border-box;
`;
export default CorporationPermission;

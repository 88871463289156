import { UseQueryResult, useQuery } from "react-query";
import apis from "../../api/getApi";

interface DataProp {
  hostType: string;
  payType: string;
}

export interface UserDataGet {
  uuid: string;
  name: string;
  email: string;
  signUpAt: string;
  type: "enterprise" | "individual";
  plan: "individualPlan" | "memberPlan" | "managerPlan";
  enterpriseName: string | null;
}

export interface UserListApiResponse {
  hosts: UserDataGet[];
}

const useGetUserList = (): UseQueryResult<UserListApiResponse, unknown> => {
  const fetcher = async () => {
    const response = await apis.getUserList();
    return response.data;
  };
  return useQuery(["userList"], fetcher);
};

export default useGetUserList;

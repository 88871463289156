import { useEffect, useState } from "react";

import customAxios from "../../../api/handler";
import { IQuestionList } from "../interface/i.question";
import QuestionBoardCategory from "./QuestionBoardCategory";
import QuestionBoardContents from "./QuestionBoardContents";
import styled from "styled-components";

const QuestionBoard = ({ questionList } : { questionList : IQuestionList[]}) => {
    
    return (
        <StyledBoard>
            <QuestionBoardCategory></QuestionBoardCategory>
            {questionList.map((question, idx) => {
                return <QuestionBoardContents key={idx} question={question}></QuestionBoardContents>
            })}
        </StyledBoard>
    )
};

export default QuestionBoard;

export const StyledBoard = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid var(--gray-light-gray, #d7d7d7);
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`;
import { styled } from "styled-components";
import TableItem from "../../../atomic/molecule/TableItem";
import NoticeBoardContents from "./NoticeBoardContents";
import { INotice, INoticeListProps } from "../interface/i.notice";

const NoticeBoardCategory = ({ notices }: { notices: INotice[] }) => {
  return (
    <StyledCategory>
      <StyledNoticeBoardCategory>
        <input type="checkbox" className="checkbox" />
        <div className="noticeId">번호</div>
        <div className="noticeTitle">제목</div>
        <div className="noticeRegion">지역</div>
        <div className="authorNickname">작성자</div>
        <div className="noticeCategory">유형</div>
        <div className="noticeCreatedAt">작성일</div>
      </StyledNoticeBoardCategory>
      {notices.map((notice) => {
        return (
          <NoticeBoardContents
            key={notice.noticeId}
            notices={notice}
          ></NoticeBoardContents>
        );
      })}
    </StyledCategory>
  );
};

/**
 * 1. noticeId
 * 2. 제목
 * 3. category
 * 4. 작성일
 * 5. 중요도에 따라
 */
export default NoticeBoardCategory;

export const StyledCategory = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  font-family: "semibold";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  color: #000;
  text-align: center;
  text-overflow: ellipsis;
`;

export const StyledNoticeBoardCategory = styled.div`
  display: flex;
  height: 10px;
  padding: 12px 24px;
  align-items: center;
  gap: 30px;
  white-space: nowrap;

  border-bottom: 1px solid var(--gray-light-gray, #d7d7d7);
  background: var(--gray-light-gray-1, #f9f9f9);

  .checkbox {
    display: flex;
    width: 32px;
    height: 15px;
    padding: 5.665px;
    justify-content: center;
    align-items: center;
  }

  .noticeCategory {
    width: 40px;
  }

  .authorNickname {
    width: 60px;
    display: flex;
    justify-content: center;
  }

  .noticeRegion {
    display: flex;
    width: 40px;
  }

  .noticeId {
    width: 15px;
  }

  .noticeTitle {
    display: flex;
    width: 870px;
  }

  .noticeContext {
    width: 1000px;
    display: flex;
    justify-content: flex-start;
  }

  .noticeCreatedAt {
    width: 105px;
  }
`;

import React, { useEffect, useState } from "react";
import Header from "../organism/Header";
import SideBar from "../organism/SideBar";
import { styled } from "styled-components";

interface Pages {
  children: JSX.Element;
}

const Layout: React.FC<Pages> = (props) => {
  const [part, setPart] = useState<number>(1);

  // 페이지 이동에 따라 사이드 바와 헤더를 업데이트 시켜준다.
  useEffect(() => {
    if (window.location.pathname.split("/")[1] === "common") {
      setPart(1);
    } else if (window.location.pathname.split("/")[1] === "develope") {
      setPart(2);
    } else if (window.location.pathname.split("/")[1] === "marketing") {
      setPart(3);
    }
  }, [window.location.pathname.split("/")[1]]);

  return (
    <StLayout>
      <Header setPart={setPart} part={part} />
      <div className="rowPart">
        <SideBar part={part} />
        <div className="bodyPart">{props.children}</div>
      </div>
    </StLayout>
  );
};

const StLayout = styled.div`
  .rowPart {
    display: flex;
    height: calc(100vh - 75px);
    .bodyPart {
      flex-grow: 1;
    }
  }
`;

export default Layout;

import React from "react";
import ListItem from "../molecule/ListItem";
import { styled } from "styled-components";

interface tableProps {
  items: object;
  keys: string[];
}

const ListTable: React.FC<tableProps> = ({ items, keys }) => {
  let arr: string[] = Object.values(items);

  return (
    <StLineTable>
      {keys.map((value, index) => {
        return (
          <ListItem
            key={index}
            index={index}
            values={[keys[index], arr[index]]}
          />
        );
      })}
    </StLineTable>
  );
};

const StLineTable = styled.div`
  .secondTable {
    padding-left: 130px;
    background-color: #f9f9f9;
  }
`;

export default ListTable;

import axios from "axios";
import { StyledNoticePost } from "../../../NoticeWrite";
import NoticeUpdateContents from "./NoticeUpdateContents";
import NoticeUpdateHeader from "./NoticeUpdateHeader";
import { useEffect, useState } from "react";
import {
  INoticeDetail,
  INoticeDetailProps,
  INoticeParentProps,
  TExposeServer,
} from "../../interface/i.notice";
import { useParams } from "react-router";
import customAxios from "../../../../api/handler";

const NoticeUpdate = () => {
  const { noticeId } = useParams() as { noticeId : string };

  const [getNotice, setGetNotice] = useState<INoticeDetail>({
    noticeId: +noticeId,
    noticeTitle: "SET NAME",
    noticeCategory: "일반",
    isDone: 0,
    isImportant: false,
    noticeContext: "",
    noticeViewCount: 0,
    createdAt: "not find notice detail",
    signedImageList: [],
    noticeRegion: ''
  });
  const [category, setCategory] = useState<string>('');
  const [isImportant, setIsImportant] = useState<number>(0);
  const [exposeServer, setExposeServer] = useState<TExposeServer | string>('');

  const getNoticeList = async () => {
    await customAxios
      .get(`/admin/proc/notices/update/${noticeId}`)
      .then(({ data }) => {
        const { noticeDetail } = data as INoticeDetailProps;
        setGetNotice(noticeDetail);
      })
      .catch((e) => {

        console.log("ERROR:", e);
        
      });
  };

  useEffect(() => {
    getNoticeList();
  }, []);

  const [noticeData, setNoticeData] = useState<INoticeParentProps>({
    notice: {
      noticeId: getNotice.noticeId,
      title: getNotice.noticeTitle,
      context: getNotice.noticeContext,
      category: getNotice.noticeCategory,
      isImportant: getNotice.isImportant,
      confirmImageS3KeyList: getNotice.signedImageList
    },
  });

  const saveCategory = (data: string) => {
    setCategory(data)
  };

  const saveIsImportant = (data: number) => {
    setIsImportant(data)
  }

  const saveExpose = (data: string) => {
    setExposeServer(data);
  }

  useEffect(() => {
    setNoticeData({ notice : {
      noticeId: getNotice.noticeId,
      title: getNotice.noticeTitle,
      context: getNotice.noticeContext,
      category: getNotice.noticeCategory,
      isImportant: getNotice.isImportant,
      confirmImageS3KeyList: getNotice.signedImageList
    }})
  }, []);

  const saveNoticeData = (data: INoticeParentProps) => {
    setNoticeData(data);
  };

  return (
    <StyledNoticePost>
      <NoticeUpdateHeader props={{ notice: noticeData.notice, isImportant, category, exposeServer }}></NoticeUpdateHeader>
      {getNotice.isDone ? (
        <NoticeUpdateContents
          props={{ saveNoticeData, notice: getNotice, saveCategory, saveIsImportant, saveExpose }}
        ></NoticeUpdateContents>
      ) : null}
    </StyledNoticePost>
  );
};

export default NoticeUpdate;

import React from "react";
import LoginForm from "../molecule/LoginForm";
import TextLine from "../atom/TextLine";
import { styled } from "styled-components";

const LoginInputField = () => {
  return (
    <StInputField>
      <div className="InputWrapper">
        <TextLine
          text="로그인"
          fontSize={40}
          fontFamily="bold"
          // textAlign="center"
        />
        <LoginForm />
      </div>
    </StInputField>
  );
};

const StInputField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: calc(100vh - 75px);
  .InputWrapper {
    display: grid;
    gap: 60px;
    justify-content: center;
  }
`;

export default LoginInputField;

import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import PageHeader from "../organism/PageHeader";
import RefundTable from "../organism/RefundTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useGetChargeCount from "../../hook/api/useGetChargeCount";
import customAxios from "../../api/handler";
import Pagination from "../organism/Pagination";

export interface Filter {
  use: boolean;
  filterList?: string[];
}

const RefundTemplate = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const emailRef = useRef<HTMLInputElement | null>(null);

  const filter: Filter = {
    use: true,
    filterList: ["통합", "기업", "개인"],
  };
  const pageCount = 18;
  const nowPage = Number(useParams().nowPage);

  const [chooseFilter, setChooseFilter] = useState<number>(0);
  const [hostType, setHostType] = useState<string>("all");
  const [pageCounting, setPageCounting] = useState<number>(1); //
  const [pageShowing, setPageShowing] = useState<number[]>([]);
  const [refundList, setRefundList] = useState<{ [key: string]: any }>({});
  const [refundCount, setRefundCount] = useState<number>(pageCount);

  /** 개인별 검색하기 */
  const getPersonalRefundList = (event: React.FormEvent) => {
    event.preventDefault();
    navigate(`/common/refundList/1?email=${emailRef.current?.value}`);
  };

  /** 결제 내역 카운트 해주기 */
  const getRefundCount = () => {
    customAxios
      .post(`/admin/proc/pays/count?hostType=${hostType}&payType=refund`, {
        hostEmail: search.substring(7) ? search.substring(7) : null,
      })
      .then(({ data }) => {
        setRefundCount(data.paysCount);
      })
      .catch(() => {});
  };

  /** 결제 내역 목록 받아오기 */
  const getRefundList = () => {
    customAxios
      .post(
        `/admin/proc/pays/refund/list?page=${nowPage}&pageCount=${pageCount}&hostType=${hostType}`,
        {
          hostEmail: search.substring(7) ? search.substring(7) : null,
        }
      )
      .then(({ data }) => {
        setRefundList(data);
      })
      .catch(({ data }) => {
        if (data?.errorCode === "AUTH-003") {
          setRefundCount(0);
          setRefundList([]);
        }
      });
  };

  useEffect(() => {
    getRefundList();
    getRefundCount();
  }, [search, nowPage, hostType]);

  useEffect(() => {
    setPageCounting(Math.ceil(refundCount / pageCount));
  }, [refundCount]);

  useEffect(() => {
    if (chooseFilter === 0) {
      setHostType("all");
    } else if (chooseFilter === 1) {
      setHostType("ent");
    } else if (chooseFilter === 2) {
      setHostType("ind");
    }
  }, [chooseFilter]);

  // console.log(data);
  return (
    <StTemplate>
      <PageHeader
        title="환불 리스트"
        search={true}
        filter={filter}
        chooseFilter={chooseFilter}
        setChooseFilter={setChooseFilter}
        searchFunction={getPersonalRefundList}
        useref={emailRef}
      />
      <RefundTable
        headerList={[
          "환불자",
          "이메일",
          "기업 이름",
          "성공여부",
          "환불 금액",
          "환불 일자",
        ]}
        items={refundList.refundList}
        nowPage={nowPage}
      />
      <Pagination
        nowpage={nowPage}
        pageCounting={pageCounting}
        pageShowing={pageShowing}
        setPageShowing={setPageShowing}
        type="refundList"
        bigType="common"
      />
    </StTemplate>
  );
};

const StTemplate = styled.div`
  display: grid;
  gap: 30px;
`;

export default RefundTemplate;

import axios from "axios";

const BASE_URL: string = "https://prod.office-cicd.proctormatic.com";
const config = {
  headers: {
    "API-KEY": "helloworld",
  },
};

const readComputerQuantity = async () => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/proctormatic/state/prod/summary`,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};

//////////////////// 상태 확인 ////////////////////
const checkApi = async () => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/proctormatic/health_check/prod/api_server`,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};
const checkApiSch = async () => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/proctormatic/health_check/prod/api_scheduler`,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};
const checkMobileAi = async () => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/proctormatic/health_check/prod/mobile_ai_server`,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};
const checkAiAnalysis = async () => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/proctormatic/health_check/prod/ai_analysis`,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};
const checkAiVideoMerger = async () => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/proctormatic/health_check/prod/video_merger`,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};

const serverStatusAllCheck = async () => {
  try {
    const res = await axios.all([
      checkApi(),
      checkApiSch(),
      checkMobileAi(),
      checkAiAnalysis(),
      checkAiVideoMerger(),
    ]);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

//////////////////// 재배포 ////////////////////

const deployApiServer = async () => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/proctormatic/deploy/prod/api_server`,
      {},
      config
    );
    console.log(data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const deplayApiScheduler = async () => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/proctormatic/deploy/prod/api_scheduler`,
      {},
      config
    );
    console.log(data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const deplayMobileAi = async () => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/proctormatic/deploy/prod/mobile_ai_server`,
      {},
      config
    );
    console.log(data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const deplayAiAnalysis = async () => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/proctormatic/deploy/prod/ai_analysis`,
      {},
      config
    );
    console.log(data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const deplayVideoMerger = async () => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/proctormatic/deploy/prod/video_merger`,
      {},
      config
    );
    console.log(data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export {
  readComputerQuantity,
  serverStatusAllCheck,
  deployApiServer,
  deplayApiScheduler,
  deplayMobileAi,
  deplayAiAnalysis,
  deplayVideoMerger,
};

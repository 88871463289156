import React from "react";
import UserTemplate from "../../atomic/template/UserTemplate";
import { styled } from "styled-components";

const User = () => {
  return (
    <StUser>
      <UserTemplate />
    </StUser>
  );
};

const StUser = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 48px;
`;

export default User;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";
import NoticeDetailHeader from "./NoticeDetailHeader";
import NoticeDetailContents from "./NoticeDetailContents";
import NoticeDetailImage from "./NoticeDetailImage";
import { INoticeDetail, INoticeDetailProps } from "../interface/i.notice";
import customAxios from "../../../api/handler";

const NoticeDetail = () => {
  const { noticeId } = useParams();
  
  const [notice, setNotice] = useState<INoticeDetail>({
    noticeId: 0,
    noticeTitle: "SET NAME",
    noticeCategory: "일반",
    isDone: 1,
    isImportant: false,
    noticeContext: "",
    noticeViewCount: 0,
    createdAt: "not find notice detail",
    signedImageList: [],
    noticeRegion: ""
  });

  const getNoticeList = async() => {
    await customAxios
      .get(`/admin/proc/notices/${noticeId}`)
      .then(({ data }) => {

        const { noticeDetail } = data as INoticeDetailProps;
        setNotice(noticeDetail);
        
      })
      .catch(() => {});
  };

  useEffect(() => { 
    getNoticeList() 
  }, [noticeId]);

  return (
    <StyledNoticeDetail>
      <NoticeDetailHeader notice={notice}/>
      <NoticeDetailContents notice={notice}/>
      <NoticeDetailImage noticeImage={notice.signedImageList}/>
    </StyledNoticeDetail>
  );
};

export default NoticeDetail;

const StyledNoticeDetail = styled.div`
  display: flex;
  width: 80%;
  height: 85%;
  padding: 60px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  margin-left: 150px;

  .line {
    display: flex;
    height: 1px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    color: #d7d7d7;
  }
`;
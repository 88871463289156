import React from "react";
import ChartView from "./components/chart";

const Device = () => {
  return (
    <div>
      <ChartView />
    </div>
  );
};

export default Device;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InformationBox from "./InformationBox";
import InformationTitle from "./InformationTitle";
import customAxios from "../../../api/handler";
import { landingCalenderMonth, landingCalenderYear } from "../../../atom";
import { useRecoilState } from "recoil";
import InformationBoxTest from "./InformationBoxTest";
import InformationBoxTester from "./InformationBoxTester";
import InformationBoxHost from "./InformationBoxHost";
import InformationBoxNavi from "./InformationBoxNavigation";
import InformationBoxNavigation from "./InformationBoxNavigation";
export interface IAllInformation {
  allHost: number;
  individualHost: number;
  enterpriseCount: number;
  enterpriseHost: number;
  allTest: number;
  finishTest: number;
  expectedTest: number;
  inProgressTest: number;
  allTester: number;
  finishTester: number;
  inProgressTester: number;
  allPaid: number;
  allEntPaid: number;
  allIndPaid: number;
  allRefund: number;
  allEntRefund: number;
  allIndRefund: number;
  allCreditProvide: number;
  inProgressCoupon: number;
}
export interface IMonthInformation {
  monthAllTest: number;
  monthFinishTest: number;
  monthInProgressTest: number;
  monthExpectedTest: number;
  monthAllTester: number;
  monthFinishTester: number;
  monthInProgressTester: number;
  monthAllPaid: number;
  monthAllRefund: number;
}

const Container = styled.div`
  width: 80%;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //border: 1px solid gray;
`;

const Container2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //border: 1px solid gray;
`;

const Information = () => {
  const [allInformation, setAllInformation] = useState<IAllInformation>({
    allHost: 0,
    individualHost: 0,
    enterpriseCount: 0,
    enterpriseHost: 0,
    allTest: 0,
    finishTest: 0,
    expectedTest: 0,
    inProgressTest: 0,
    allTester: 0,
    finishTester: 0,
    inProgressTester: 0,
    allPaid: 0,
    allEntPaid: 0,
    allIndPaid: 0,
    allRefund: 0,
    allEntRefund: 0,
    allIndRefund: 0,
    allCreditProvide: 0,
    inProgressCoupon: 0,
  });

  const [monthInformation, setMonthInformation] = useState<IMonthInformation>({
    monthAllTest: 0,
    monthFinishTest: 0,
    monthInProgressTest: 0,
    monthExpectedTest: 0,
    monthAllTester: 0,
    monthFinishTester: 0,
    monthInProgressTester: 0,
    monthAllPaid: 0,
    monthAllRefund: 0,
  });

  const [atomMonth, setAtomMonth] = useRecoilState(landingCalenderMonth);
  const [atomYear, setAtomYear] = useRecoilState(landingCalenderYear);

  useEffect(() => {
    customAxios
      .get(`/admin/proc/landing`)
      .then(({ data }) => {
        setAllInformation(data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    customAxios
      .get(`/admin/proc/landing/${atomYear}/${atomMonth}`)
      .then(({ data }) => {
        setMonthInformation(data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [atomMonth, atomYear, allInformation]);

  return (
    <Container>
      <Container2>
        <InformationTitle text={"총 누적"} />
        <InformationBox
          text={"개인 회원 수"}
          value={allInformation?.individualHost}
        />
        <InformationBoxHost
          text={"기업 회원 수"}
          enterpriseCount={allInformation?.enterpriseCount}
          enterpriseHost={allInformation?.enterpriseHost}
        />

        <InformationBoxTest
          text={"총 시험 수"}
          allTest={allInformation?.allTest}
          finishTest={allInformation?.finishTest}
          expectedTest={allInformation?.expectedTest}
          inProgressTest={allInformation?.inProgressTest}
        />
        <InformationBoxTester
          text={"총 응시자 수"}
          allTester={allInformation?.allTester}
          finishTester={allInformation?.finishTester}
          inProgressTester={allInformation?.inProgressTester}
        />

        <InformationBox text={"결제 금액"} value={allInformation?.allPaid} />
        <InformationBox text={"환불 금액"} value={allInformation?.allRefund} />
      </Container2>
      <Container2>
        <InformationTitle text={"월 누적"} />
        <InformationBoxTest
          text={"총 시험 수"}
          allTest={monthInformation?.monthAllTest}
          finishTest={monthInformation?.monthFinishTest}
          expectedTest={monthInformation?.monthExpectedTest}
          inProgressTest={monthInformation?.monthInProgressTest}
        />
        <InformationBoxTester
          text={"월별 총 응시자 수"}
          allTester={monthInformation?.monthAllTester}
          finishTester={monthInformation?.monthFinishTester}
          inProgressTester={monthInformation?.monthInProgressTester}
        />
        <InformationBox
          text={"결제 금액"}
          value={monthInformation?.monthAllPaid}
        />
        <InformationBox
          text={"환불 금액"}
          value={monthInformation?.monthAllRefund}
        />
        <InformationBoxNavigation
          text={"적립금 발급 금액"}
          value={allInformation?.allCreditProvide}
          navigation={"/marketing/creditProvideList"}
        />
        <InformationBoxNavigation
          text={"진행중인 쿠폰수"}
          value={allInformation?.inProgressCoupon}
          navigation={"/marketing/couponList"}
        />
      </Container2>
    </Container>
  );
};

export default Information;

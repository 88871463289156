import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

interface DeviderProps {
  text?: string;
  fontSize: number;
  clicked?: boolean;
  padding: string;
  location?: string;
  locatePath?: string;
  pathname?: string;
}

interface DeviderStyle extends DeviderProps {
  locatepath?: string;
}

const SideBarDevider: React.FC<DeviderProps> = (props) => {
  const navigate = useNavigate();
  const pathname = window.location.pathname.split("/")[2];

  return (
    <StDevider
      fontSize={props.fontSize}
      padding={props.padding}
      clicked={props.clicked}
      location={props.location}
      locatepath={props.locatePath}
      pathname={pathname}
      onClick={() => navigate(`${props.location}`)}
    >
      {props.text}
    </StDevider>
  );
};

const StDevider = styled.div<DeviderStyle>`
  font-size: ${(props) => props.fontSize}px;
  padding: ${(props) => props.padding};
  font-family: ${(props) => (props.clicked ? "medium" : "regular")};
  background-color: ${(props) =>
    props.pathname === props.locatepath ? "#031238" : "transparent"};
  cursor: pointer;
`;

export default SideBarDevider;

import React, {
  MutableRefObject,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import MarketingHeader from "../Marketing/CommonComponents/marketingHeader";
import { SearchBox } from "../Marketing/CreditProvide/CreditProvide";
import { styled } from "styled-components";
import { ButtonBox } from "../Tester/TesterVideoDebugging";
const data = [
  {
    testName: "제82회 Itzel자격증 시험",
    email: "930802qhfka@gmail.com",
    startTiem: "20221022 08:00:00",
    endTime: "20221022 10:00:00",
    uuid: "123456789",
  },
  {
    testName: "제84회 견주 자격증 시험",
    email: "b_o@edint.io",
    startTiem: "20221102 16:00:00",
    endTime: "20221112 18:00:00",
    uuid: "234567891",
  },
  {
    testName: "제82회 자전거 자격증 시험",
    email: "boram930802@gmail.com",
    startTiem: "20220926 02:00:00",
    endTime: "20220926 10:00:00",
    uuid: "2345678912",
  },
];

interface InputDataType {
  email: string;
  testName: string;
  testerName: string;
}

interface TestListItemDataType {
  testName: string;
  email: string;
  startTime: string;
  endTime: string;
  uuid: string;
}

const UrgentAnalysis = () => {
  const [inputData, setInputData] = useState<InputDataType>({
    email: "",
    testerName: "",
    testName: "",
  });
  let inputDataRef = useRef<InputDataType>({
    email: "",
    testerName: "",
    testName: "",
  }).current;
  const [testListItemData, setTestListItemData] =
    useState<TestListItemDataType>({
      testName: "",
      email: "",
      startTime: "",
      endTime: "",
      uuid: "",
    });
  const onChangeSearchData = (
    e: React.ChangeEvent<HTMLInputElement>,
    dataKey: keyof InputDataType
  ) => {
    const newValue = e.target.value;

    // Update the specific property in inputData
    setInputData((prevData) => ({
      ...prevData,
      [dataKey]: newValue,
    }));
  };

  const onClick = () => {};

  return (
    <div style={{ padding: "20px" }}>
      <MarketingHeader title="긴급 분석 모드" />
      <TitleContent>
        <div>
          <label>Email</label>
          <input
            type={"email"}
            value={inputData["email"]}
            onChange={(e) => onChangeSearchData(e, "email")}
          />
        </div>
        <div>
          <label>Test Name</label>
          <input
            type={"text"}
            value={inputData["testName"]}
            onChange={(e) => onChangeSearchData(e, "testName")}
          />
        </div>
        <div>
          <label>Email</label>
          <input
            type={"text"}
            value={inputData["testerName"]}
            onChange={(e) => onChangeSearchData(e, "testerName")}
          />
        </div>

        <ButtonBox>
          <button>시험 검색</button>
        </ButtonBox>
      </TitleContent>
      <ListItemRow>
        <p className="title"></p>
        <p className="title">시험 제목.</p>
        <p className="title">주최자 이메일.</p>
        <p className="title">시험 기간.</p>
      </ListItemRow>
      {data.map((item, idx) => (
        <ListItemRow key={idx}>
          <p onClick={() => console.log()}>선택</p>
          <p>{item.testName}</p>
          <p>{item.email}</p>
          <p>
            {item.startTiem} ~ {item.endTime}
          </p>
        </ListItemRow>
      ))}
    </div>
  );
};

export default UrgentAnalysis;
const TitleContent = styled.div`
  display: grid;
  grid-template-columns: 300px 550px 400px auto;
  div {
    padding: 15px;
    background-color: #f7f9ff;
    border-bottom: 1px solid #e4e4e5;
    display: flex;
    align-items: center;
    label {
      margin-right: 10px;
    }
    input {
      height: 25px;
      font-size: 16px;
      background-color: #fff;
      border: 1px solid #15254e;
      flex-grow: 1;
    }
  }
  div:last-child {
    button {
      padding: 0 40px;
    }
  }
`;

const ListItemRow = styled.div`
  display: grid;
  grid-template-columns: 80px 1.5fr 1fr 1fr;

  p:not(:last-child) {
    border-right: 1px solid #e4e4e5;
  }
  p.title {
    background-color: #f7f9ff;
  }

  p {
    padding: 15px;
    border-bottom: 1px solid #e4e4e5;
  }
  p:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #b8b8b8;
    cursor: pointer;
  }
`;

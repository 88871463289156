import React from "react";
import TableItem from "../molecule/TableItem";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import TextLine from "../atom/TextLine";
import { styled } from "styled-components";

interface TableProps {
  headerList?: string[];
  items: { [key: string]: any }[] | undefined;
  nowPage: number;
}

const RefundTable: React.FC<TableProps> = (props) => {
  const navigate = useNavigate();
  return (
    <StTable>
      {props.headerList && <TableItem item={props?.headerList} use="header" />}
      {props.items?.length && props.items?.length > 0 ? (
        props.items?.map((value, index) => {
          return (
            <TableItem
              key={index}
              // item={Object.keys(value).map((item) => value[item])}
              item={[
                value.hostName,
                value.hostEmail ? value.hostEmail : "-",
                value.hostGroupName ? value.hostGroupName : "-",
                value.type,
                value.paymentsAmount,
                dayjs(value.paymentsAt).format("YYYY MM-DD HH:mm:ss"),
              ]}
              ent={value.hostGroupName}
              onClick={() => {
                if (value.hostGroupName) {
                  navigate(
                    `/common/refundList/${props.nowPage}/${value.hostUuid}/${value.targetGroupUuid}`
                  );
                }
              }}
              use="item"
            />
          );
        })
      ) : (
        <div className="noList">
          <TextLine
            text="검색 결과가 없습니다... 그건 제 잔상입니다만...?"
            fontSize={25}
            fontcolor="#888888"
          />
        </div>
      )}
    </StTable>
  );
};

const StTable = styled.div`
  .noList {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
  }
`;

export default RefundTable;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import arrowLeft from "../../img/ArrowLeft.png";
import arrowRight from "../../img/ArrowRight.png";
import PaginationButton from "../molecule/PaginationButton";
import { styled } from "styled-components";

interface PaginationProp {
  nowpage: number;
  pageCounting: number;
  setPageShowing: React.Dispatch<React.SetStateAction<number[]>>;
  pageShowing: number[];
  type: string;
  bigType: string;
}

const Pagination: React.FC<PaginationProp> = (props) => {
  const navigate = useNavigate();

  // 페이지네이션
  useEffect(() => {
    let temp = [];
    if (props.nowpage <= 3 && props.pageCounting > 4) {
      props.setPageShowing([1, 2, 3, 4, 5]);
    } else if (props.pageCounting <= 4) {
      for (let i = 1; i <= props.pageCounting; i++) {
        temp.push(i);
      }
      props.setPageShowing(temp);
    } else {
      if (props.pageCounting <= 5) {
        for (let i = 1; i <= props.pageCounting; i++) {
          temp.push(i);
        }
        props.setPageShowing(temp);
      } else if (props.pageCounting > 5) {
        if (props.nowpage <= props.pageCounting - 2) {
          for (let i = props.nowpage - 2; i <= props.nowpage + 2; i++) {
            temp.push(i);
          }
          props.setPageShowing(temp);
        } else {
          for (let i = props.pageCounting - 4; i <= props.pageCounting; i++) {
            temp.push(i);
          }
          props.setPageShowing(temp);
        }
      }
    }
  }, [props.pageCounting, props.nowpage]);

  return (
    <StPagenation>
      {props.pageCounting > 1 && (
        <div className="pagination">
          {props.pageShowing?.map((value, index) => {
            return (
              <div key={index}>
                <PaginationButton
                  type={props.type}
                  bigType={props.bigType}
                  nowpage={props.nowpage}
                  page={value}
                />
              </div>
            );
          })}
          {props.nowpage !== 1 && (
            <img
              className="leftArrow"
              onClick={() => {
                navigate(
                  `/${props.bigType}/${props.type}/${props.nowpage - 1}`
                );
              }}
              src={arrowLeft}
              alt=""
            />
          )}
          {props.nowpage !== props.pageCounting && (
            <img
              className="rightArrow"
              onClick={() => {
                navigate(
                  `/${props.bigType}/${props.type}/${props.nowpage + 1}`
                );
              }}
              src={arrowRight}
              alt=""
            />
          )}
        </div>
      )}
    </StPagenation>
  );
};

const StPagenation = styled.div`
  display: flex;
  justify-content: center;
  .pagination {
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    .rightArrow {
      position: absolute;
      transform: translate(0%, -50%);
      top: 50%;
      right: -50px;
      cursor: pointer;
    }
    .leftArrow {
      position: absolute;
      transform: translate(0%, -50%);
      top: 50%;
      left: -50px;
      cursor: pointer;
    }
  }
`;

export default Pagination;

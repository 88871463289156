import React, { useEffect, useRef, useState } from "react";
import ChargeTable from "../organism/ChargeTable";
import { styled } from "styled-components";
import PageHeader from "../organism/PageHeader";
import { Filter } from "./RefundTemplate";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "../organism/Pagination";
import customAxios from "../../api/handler";

const PaymentTemplate = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const emailRef = useRef<HTMLInputElement | null>(null);

  const filter: Filter = {
    use: true,
    filterList: ["통합", "기업", "개인"],
  };
  const pageCount = 18;
  const nowPage = Number(useParams().nowPage);

  const [chooseFilter, setChooseFilter] = useState<number>(0);
  const [hostType, setHostType] = useState<string>("all");
  const [pageCounting, setPageCounting] = useState<number>(1); //
  const [pageShowing, setPageShowing] = useState<number[]>([]);
  const [paymentList, setPaymentList] = useState<{ [key: string]: any }>({});
  const [paymentCount, setPaymentCount] = useState<number>(pageCount);

  /** 개인별 검색하기 */
  const getPersonalChargeList = (event: React.FormEvent) => {
    event.preventDefault();
    navigate(`/common/paymentList/1?email=${emailRef.current?.value}`);
  };

  /** 결제 내역 카운트 해주기 */
  const getPaymentCount = () => {
    customAxios
      .post(`/admin/proc/pays/count?hostType=${hostType}&payType=charge`, {
        hostEmail: search.substring(7) ? search.substring(7) : null,
      })
      .then(({ data }) => {
        setPaymentCount(data.paysCount);
      })
      .catch(() => {});
  };

  /** 결제 내역 목록 받아오기 */
  const getPaymentList = () => {
    customAxios
      .post(
        `/admin/proc/pays/cash/list?page=${nowPage}&pageCount=${pageCount}&hostType=${hostType}`,
        {
          hostEmail: search.substring(7) ? search.substring(7) : null,
        }
      )
      .then(({ data }) => {
        setPaymentList(data);
      })
      .catch(({ data }) => {
        if (data?.errorCode === "AUTH-003") {
          setPaymentList([]);
          setPaymentCount(0);
        }
      });
  };

  useEffect(() => {
    getPaymentList();
    getPaymentCount();
  }, [search, nowPage, hostType]);

  useEffect(() => {
    setPageCounting(Math.ceil(paymentCount / pageCount));
  }, [paymentCount]);

  useEffect(() => {
    if (chooseFilter === 0) {
      setHostType("all");
    } else if (chooseFilter === 1) {
      setHostType("ent");
    } else if (chooseFilter === 2) {
      setHostType("ind");
    }
  }, [chooseFilter]);

  return (
    <StTemplate>
      <PageHeader
        title="결제 리스트"
        search={true}
        filter={filter}
        chooseFilter={chooseFilter}
        setChooseFilter={setChooseFilter}
        searchFunction={getPersonalChargeList}
        useref={emailRef}
      />
      <ChargeTable
        headerList={[
          "결제자",
          "이메일",
          "기업 이름",
          "결제 용도",
          "결제 금액",
          "결제 일자",
        ]}
        items={paymentList.paysList}
        nowPage={nowPage}
      />
      <Pagination
        nowpage={nowPage}
        pageCounting={pageCounting}
        pageShowing={pageShowing}
        setPageShowing={setPageShowing}
        type="paymentList"
        bigType="common"
      />
    </StTemplate>
  );
};

const StTemplate = styled.div`
  display: grid;
  gap: 30px;
`;

export default PaymentTemplate;

import React, { useRef, useState } from "react";
import Input from "../atom/Input";
import Button from "../atom/Button";
import { styled } from "styled-components";
import TextLine from "../atom/TextLine";
import DivButton from "../atom/DivButton";
import customAxios from "../../api/handler";
import useToast from "../../hook/useToast";

interface certificateProp {
  setCertificate: React.Dispatch<React.SetStateAction<boolean>>;
  setEmail: React.Dispatch<React.SetStateAction<string | null>>;
}

const EmailCertificate: React.FC<certificateProp> = ({
  setCertificate,
  setEmail,
}) => {
  const [authNumSend, setAuthNumSend] = useState<boolean>(false);
  const showToast = useToast();

  /** 인증버튼 클릭 시 인증번호를 서버에서 대조 후 다음 단계로 이동 */
  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    customAxios
      .post(`/admin/proc/auth/email-verification/verify`, {
        email: emailRef.current?.value + "@edint.io",
        authnum: authNumRef.current?.value,
      })
      .then(() => {
        setEmail(emailRef.current?.value + "@edint.io");
        setCertificate(true);
        showToast({ message: "인증이 완료되었습니다." });
      })
      .catch(({ response }) => {
        console.log(response);
      });
  };

  /** 인증번호 발송 버튼 */
  const getEmailCertificateNumber = () => {
    customAxios
      .post(`/admin/proc/auth/email-verification/request`, {
        email: emailRef.current?.value + "@edint.io",
      })
      .then(() => {
        showToast({ message: "인증번호가 발송 되었습니다." });
        setAuthNumSend(true);
      })
      .catch(({ response }) => {
        showToast({ message: "혹시,,, 가입한적이 있으신가요,,,??" });
      });
  };

  /** 이메일 변경 시 인증번호 재발송 */
  const authNumInitialization = () => {
    if (authNumSend === true) setAuthNumSend(false);
  };

  const emailRef = useRef<HTMLInputElement | null>(null);
  const authNumRef = useRef<HTMLInputElement | null>(null);

  return (
    <StForm onSubmit={submitHandler}>
      <div className="wrapper">
        <div className="inputCase">
          <div className="inputBox">
            <TextLine text="인증번호 발송" fontFamily="medium" />
            <div className="emailLine">
              <Input
                width={150}
                height={40}
                fontSize={16}
                ref={emailRef}
                fontcolor=""
                placeholderText="이메일"
                padding="8px 16px;"
                placeholdercolor="#888888"
                borderradius={5}
                border="1px solid black"
                onchange={authNumInitialization}
              />
              <TextLine text={"@ edint.io"} margin="0 0 0 5px" />
              {authNumSend ? (
                <DivButton
                  width={90}
                  height={40}
                  text="발송완료"
                  fontSize={12}
                  fontcolor="white"
                  border="none"
                  borderradius={8}
                  backgroundcolor="#8c8c8c"
                  margin="0 0 0 auto"
                  // onclick={getEmailCertificateNumber}
                />
              ) : (
                <DivButton
                  width={90}
                  height={40}
                  text="인증번호 발송"
                  fontSize={12}
                  fontcolor="white"
                  border="none"
                  borderradius={8}
                  backgroundcolor="#1A75FF"
                  margin="0 0 0 auto"
                  onclick={getEmailCertificateNumber}
                />
              )}
            </div>
          </div>
          <div className="inputBox">
            <TextLine text="인증번호 입력" fontFamily="medium" />
            <Input
              width={360}
              height={40}
              fontSize={16}
              fontcolor=""
              ref={authNumRef}
              placeholderText="인증번호"
              padding="8px 16px;"
              placeholdercolor="#888888"
              borderradius={5}
              border="1px solid black"
            />
          </div>
        </div>
        <Button
          width={360}
          height={50}
          fontSize={16}
          fontcolor="white"
          text="인증"
          padding="10px 16px"
          border="none"
          onclick={submitHandler}
          borderradius={8}
          backgroundcolor="#1A75FF"
          disabled={!authNumSend}
        />
      </div>
    </StForm>
  );
};

const StForm = styled.form`
  display: grid;
  justify-content: center;
  .wrapper {
    display: grid;
    gap: 20px;
    .inputCase {
      display: grid;
      gap: 10px;
      .inputBox {
        display: grid;
        gap: 8px;
        .emailLine {
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

export default EmailCertificate;

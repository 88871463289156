import { styled } from "styled-components";
import { INotice, INoticeDetail } from "../interface/i.notice";
import { useEffect } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router";
import customAxios from "../../../api/handler";

const NoticeDetailHeader = ({ notice }: { notice: INoticeDetail }) => {
  const { noticeId } = useParams();
  const navigate = useNavigate();

  const deleteNotice = async () => {
    await customAxios
      .delete(`/admin/proc/notices/${noticeId}`)
      .then(() => {
        noticeList(navigate);
      })
      .catch(() => {});
  };

  const getNoticeUpdate = () => {
    getNoticeUpdateData(navigate, noticeId);
  };

  const { noticeTitle, noticeCategory, createdAt, isImportant, noticeRegion } = notice;

  const important = isImportant ? "중요" : noticeCategory;

  return (
    <StyledNoticeDetailHeader>
      <div className="headers">
        <div className="headerInfo">
          <div className="important">{important}</div>
          <div className="region">{noticeRegion}</div>
        </div>
        <div className="noticeTitles">
          <div className="noticeTitle">{noticeTitle}</div>
          <div className="noticeAt">{createdAt}</div>
        </div>
      </div>
      <div className="noticeButton">
        <button className="updateButton" onClick={getNoticeUpdate}>
          수정
        </button>
        <button className="deleteButton" onClick={deleteNotice}>
          삭제
        </button>
      </div>
    </StyledNoticeDetailHeader>
  );
};

export default NoticeDetailHeader;

const noticeList = (navigate: NavigateFunction) => {
  navigate(`/common/noticeList`);
};

const getNoticeUpdateData = (
  navigate: NavigateFunction,
  noticeId: string | undefined
) => {
  navigate(`/common/noticeList/update/${noticeId}`);
};

const StyledNoticeDetailHeader = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;

  .headers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    margin-top: 10px;

    .noticeTitles {
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 10px;
      width: 600px;

      .noticeTitle {
        font-family: "semibold";
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
      }

      .noticeAt {
        font-family: "Light";
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #5c5c5c;
      }
    }

    .headerInfo {
      font-family: "Light";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      text-overflow: ellipsis;
      display: flex;
      gap: 10px;

      .important {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--app-system-error, #ef4444);
        border-radius: 4px;
        background: #fee0e0;
        width: 50px;
        height: 30px;
      }

      .region {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--app-system-error, #1a75ff);
        border-radius: 4px;
        border: 1px solid var(--main-blue, #1a75ff);
        background: var(--gray-white, #fff);
        width: 50px;
        height: 30px;
      }

      .answerInfo {
        border-radius: 4px;
        background: #444;
        color: #ffffff;
        padding: 4px 8px;
      }
    }
  }

  .noticeButton {
    width: 450px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 30px;

    .updateButton,
    .deleteButton {
      display: inline-block;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;

      font-family: "semibold";
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      line-height: 20px; /* 125% */
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 6px;
    }

    .updateButton {
      border: 1px solid var(--main-blue, #1a75ff);
      background: var(--gray-white, #fff);
      white-space: nowrap;

      color: var(--main-blue, #1a75ff);
      text-align: center;
    }

    .deleteButton {
      background-color: rgb(248, 13, 53);
      border: white;

      color: white;
      text-align: center;
    }
  }
`;

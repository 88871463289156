import React, { useState } from "react";
import SideBarButtons from "../molecule/SideBarButtons";
import { styled } from "styled-components";
import { useLocation } from "react-router-dom";

interface SidebarButton {
  header: string;
  location?: string;
  pageList?: { pageName: string; location: string }[];
}

interface SideBarProp {
  part: number;
  location?: string;
}

const SideBar: React.FC<SideBarProp> = (props) => {
  const nowPage = useLocation().pathname;

  const publicPart: SidebarButton[] = [
    {
      header: "랜딩페이지",
      location: "/common/landing",
    },
    {
      header: "1:1문의",
      location: "/common/qnaList",
    },
    {
      header: "공지사항",
      pageList: [
        {
          pageName: "목록보기",
          location: "/common/noticeList",
        },
        {
          pageName: "작성하기",
          location: "/common/noticePost",
        },
      ],
    },
    { header: "결제", location: "/common/paymentList/1" },
    { header: "환불", location: "/common/refundList/1" },
    { header: "주최자", location: "/common/userList/1" },
    { header: "마이페이지", location: "/common/mypage" },
    {
      header: "관리자",
      location: "/common/master",
    },
  ];
  const devPart: SidebarButton[] = [
    {
      header: "디바이스",
      location: "/develope/device",
    },
    {
      header: "응시자",
      pageList: [
        {
          pageName: "비디오 분석 결과",
          location: "/develope/testerVideoDebugging",
        },
        {
          pageName: "응시자 리포트 데이터",
          location: "/develope/testerReportData",
        },
      ],
    },
    { header: "시험리스트", location: "/develope/testList" },
    {
      header: "서버 상태 확인 및 복구",
      location: "/develope/serverStatus",
    },
  ];
  const marketingPart: SidebarButton[] = [
    {
      header: "프로모션",
      location: "/marketing/promotion",
    },
    {
      header: "쿠폰",
      location: "/marketing/couponList",
    },

    {
      header: "적립금",
      pageList: [
        {
          pageName: "요청 내역",
          location: "/marketing/creditRequest",
        },
        {
          pageName: "승인/회수 내역",
          location: "/marketing/creditDetail",
        },
        {
          pageName: "지급 내역",
          location: "/marketing/creditProvideList",
        },
        {
          pageName: "적립금 지급",
          location: "/marketing/creditProvide",
        },
      ],
    },
    {
      header: "기업 회원가입 승인",
      location: "/marketing/corporationPermission/1",
    },
    {
      header: "도입문의 리스트",
      location: "/marketing/consultRequest/1",
    },
  ];

  return (
    <>
      {nowPage !== "/login" && nowPage !== "/signup" && (
        <StSideBar>
          {props.part === 1 && (
            <div>
              {publicPart.map((value, index) => {
                return <SideBarButtons key={index} props={value} />;
              })}
            </div>
          )}
          {props.part === 2 && (
            <div>
              {devPart.map((value, index) => {
                return <SideBarButtons key={index} props={value} />;
              })}
            </div>
          )}
          {props.part === 3 && (
            <div>
              {marketingPart.map((value, index) => {
                return <SideBarButtons key={index} props={value} />;
              })}
            </div>
          )}
        </StSideBar>
      )}
    </>
  );
};

const StSideBar = styled.div`
  background-color: #15254e;
  color: white;
  width: 300px;
  font-family: "medium";
  padding: 16px 0;
`;

export default SideBar;

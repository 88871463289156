import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import customAxios from "../../../api/handler";
import {
  IPostQuestionComponentDto,
  IPostQuestionComponentResponse,
  IQuestionChildComponentHeaderProps,
} from "../interface/i.question";

const QuestionDetailComponentsHeader = ({
  props,
}: {
  props: IQuestionChildComponentHeaderProps;
}) => {
  const location = useLocation();
  const entPath = location.pathname.endsWith("/update");
  const navigate = useNavigate();
  const {
    questionUuid,
    questionComponentUuid,
    context,
    confirmImage,
    uploadList,
    hostUuid,
  } = props;

  const handleBackNavigateQuestionDetail = () => {
    navigate(`/common/qnaList/detail/${questionUuid}`);
  };

  console.log("PROPS:", props);

  let dto: IPostQuestionComponentDto = {
    questionComponentUuid,
    questionUuid,
    questionComponentType: "admin",
    questionContext: context,
    questionImageS3KeyList: confirmImage
      .filter((v, idx) => {
        return !v.url;
      })
      .sort((a, b) => {
        return b.id - a.id;
      }),
    hostEmail: "hiya@proctormatic.com",
    hostUuid,
  };
  const postHandler = async () => {
    await customAxios
      .post(`/admin/proc/questions/completion`, dto)
      .then(({ data }) => {
        const { uploadUrlList } = data as IPostQuestionComponentResponse;

        uploadUrlList.map(async (list, idx) => {
          await uploadS3(list.uploadUrl, uploadList[idx].file);
        });

        navigate(`/common/qnaList/detail/${questionUuid}`);

        props.setReloadQuestionDetail(!props.reloadQuestionDetail);

        props.updateQuestionDetail()
      })
      .catch((e) => {
        console.log("ERROR:", e);
      });
  };

  const updateHandler = async () => {
    dto.removeImageS3KeyList = confirmImage
      .filter((v, idx) => {
        return v.url;
      })
      .sort((a, b) => {
        return b.id - a.id;
      });

    await customAxios
      .patch(`/admin/proc/questions/update`, dto)
      .then(({ data }) => {
        const { uploadUrlList } = data as IPostQuestionComponentResponse;

        console.log("UPLOADLIST:", uploadUrlList);

        uploadUrlList.map(async (list, idx) => {
          await uploadS3(list.uploadUrl, uploadList[idx].file);
        });

        navigate(`/common/qnaList/detail/${questionUuid}`);

        props.setReloadQuestionDetail(!props.reloadQuestionDetail);

        props.updateQuestionDetail()
      });
  };

  return (
    <StyledComponentsHeader>
      <div
        className="saveButton"
        onClick={() => {
          entPath ? updateHandler() : postHandler();
        }}
      >
        답변 저장
      </div>
      <div className="closeButton" onClick={handleBackNavigateQuestionDetail}>
        답변 취소
      </div>
    </StyledComponentsHeader>
  );
};

export default QuestionDetailComponentsHeader;

const StyledComponentsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 7%;
  width: 100%;
  gap: 10px;
  font-family: "semibold";

  .saveButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 100%;
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;
    border: 1px solid var(--main-blue, #1a75ff);
    background: var(--gray-white, #fff);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 6px;
    color: #1a75ff;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 6px;
    border-radius: 12px;
    color: black;
  }
  .closeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 100%;
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;
    background-color: #1a75ff;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 6px;
    border-radius: 12px;
    color: whitesmoke;
  }
`;

const uploadS3 = async (url: string, image: any) => {
  await axios
    .put(`${url}`, image, {
      headers: {
        "Content-Type": "image/*",
      },
    })
    .catch((e) => {
      console.log("ERROR:", e);
    })
    .then((response) => {
      console.log(response);
    });
};

import { UseQueryResult, useQuery } from "react-query";
import apis from "../../api/getApi";

interface RegisteredAdmins {
  uuid: string;
  name: string;
  email: string;
  signUpAt: string;
  type: "enterprise" | "individual";
  plan: "individualPlan" | "memberPlan" | "managerPlan";
  enterpriseName: string | null;
}

export interface MasterUserListApiResponse {
  registeredAdmins: RegisteredAdmins[];
  pendingAdmins: RegisteredAdmins[];
}

const useGetMasterUserList = (): UseQueryResult<
  MasterUserListApiResponse,
  unknown
> => {
  const fetcher = async () => {
    const response = await apis.getMasterUserList();
    return response.data;
  };
  return useQuery(["mastserUserList"], fetcher);
};

export default useGetMasterUserList;

import { NavigateFunction, useNavigate } from "react-router-dom";
import { styled } from "styled-components";

/** 공지사항 헤더 */
const NoticeHeader = () => {
  const navigate = useNavigate();
  return (
    <>
      <StyledNoticeHeader>
        <div className="noticeName">공지사항</div>
        <div className="noticeButton">
          <button className="deleteButton">삭제</button>
          <button
            className="writeButton"
            onClick={() => {
              postNotice(navigate);
            }}
          >
            작성하기
          </button>
        </div>
      </StyledNoticeHeader>
    </>
  );
};

export default NoticeHeader;

/**
 * 1. 버튼은 StyledNoticeHeader의 끝에 배치
 * 2. 공지사항은 헤더의 왼쪽끝에 새로운 div
 */
export const StyledNoticeHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 40px;
  align-self: stretch;
  width: 100%;
  font-family: "semibold";

  .noticeName {
    width: 1000px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    color: #000;

    /* SemiBold/28 */
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 142.857% */
  }

  .noticeButton {
    width: 450px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    .deleteButton {
      display: inline-block;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid var(--main-blue, #1a75ff);
      background: var(--gray-white, #fff);
      white-space: nowrap;

      color: var(--main-blue, #1a75ff);
      text-align: center;
      /* Medium/16 */
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      line-height: 20px; /* 125% */
      cursor: pointer;
    }

    .writeButton {
      display: inline-block;
      white-space: nowrap;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background-color: #1a75ff;
      border: white;

      color: var(--gray-white, #fff);
      text-align: center;
      /* Medium/16 */
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      line-height: 20px; /* 125% */
      cursor: pointer;
    }
  }
`;

const postNotice = (navigate: NavigateFunction) => {
  navigate(`/common/noticePost`);
};

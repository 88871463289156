import styled from "styled-components";

const Container = styled.div`
  width: 40%;
  height: 45px;
  margin: 5px;
  /* padding-left: 5px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #d0d0d0;
  border: 1px solid gray;
  border-radius: 5px;

  p {
    font-size: 15px;
    margin: 5px;
  }
`;

interface Props {
  text: string;
}

const InformationTitle = ({ text }: Props) => {
  return (
    <Container>
      <p>{text}</p>
    </Container>
  );
};

export default InformationTitle;

import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const QuestionDetailComponentsContents = ({
  props,
}: {
  props: {
    saveContextInput: (data: string) => void;
    defaultContext?: string;
  };
}) => {
  
  const contextInput = useRef<HTMLTextAreaElement>(null);
  const [context, setContext] = useState<HTMLTextAreaElement | null>(null);
  const location = useLocation();
  const endPath = location.pathname.endsWith("/update");

  const handleContext = (event: React.ChangeEvent<any>) => {
    setContext(event.target.value);
    props.saveContextInput(event.target.value);
  };

  useEffect(() => {
    
    setContext(props.defaultContext as unknown as HTMLTextAreaElement | null);
  }, [props.defaultContext]);

  useEffect(() => {

    props.saveContextInput(contextInput.current?.value as string)
     
  }, [context]);

  return (
    <StyledComponentsContents onChange={handleContext}>
      <div className="answerContext">
        <div className="contextBox">
          <div className="context">제목</div>
        </div>
        {endPath ? (
          context !== null && (
            <textarea
              className="contextInput"
              value={
                context as unknown as
                  | string
                  | ReadonlyArray<string>
                  | number
                  | undefined
              }
              ref={
                contextInput as unknown as React.RefObject<HTMLTextAreaElement>
              }
            />
          )
        ) : (
          <textarea
            className="contextInput"
            ref={
              contextInput as unknown as React.RefObject<HTMLTextAreaElement>
            }
          />
        )}
      </div>
    </StyledComponentsContents>
  );
};

export default QuestionDetailComponentsContents;

const StyledComponentsContents = styled.div`
  width: 100%;
  height: 100%;

  .answerContext {
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: 100%;
    position: relative;

    .contextInput {
      border: 1px solid rgba(128, 128, 128, 0.5);
      border-radius: 12px;
      padding: 18px;
      width: 100%;
      display: flex;
      font-size: 14px;
      font-family: "semibold";
      resize: none;
    }

    .contextBox {
      border: 1px solid rgba(128, 128, 128, 0.5);
      border-radius: 12px;
      padding: 18px;
      width: 30px;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;

      .context {
        align-items: center;
      }
    }
  }
`;

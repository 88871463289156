import React from "react";
import { styled } from "styled-components";
import PageHeader from "../organism/PageHeader";
import TextLine from "../atom/TextLine";
import useGetMyPage from "../../hook/api/useGetMyPage";
import AuthorityCheck from "../molecule/AuthorityCheck";

const MypageTemplate = () => {
  const { data } = useGetMyPage();
  return (
    <StMyPage>
      <PageHeader title="마이 페이지" search={false} />
      <div className="infoBox">
        <div className="lineForInfo">
          <TextLine text="이름" />
          <TextLine text={data?.admin.name} />
        </div>
        <div className="lineForInfo">
          <TextLine text="사원명" />
          <TextLine text={data?.admin.nickName} />
        </div>
        <div className="lineForInfo">
          <TextLine text="전화번호" />
          <TextLine text={data?.admin.phoneNumber} />
        </div>
        <div className="lineForInfo">
          <TextLine text="이메일" />
          <TextLine text={data?.admin.email} />
        </div>
        <div className="lineForInfo">
          <TextLine text="부서" />
          <TextLine text={data?.admin.department} />
        </div>
        <div className="lineForInfo">
          <TextLine text="회원가입일자" />
          <TextLine text={data?.admin.createdAt} />
        </div>
        <div className="lineForInfo">
          <TextLine text="마지막 로그인 일자" />
          <TextLine text={data?.admin.lastLoginDate} />
        </div>
        <div className="lineForInfoColumn">
          <TextLine text="접근 권한" margin="0 0 20px 0" />
          <div className="permissionBox">
            <div className="permissionList">
              <TextLine text="허용됨" fontSize={18} margin="0 0 10px 0" />
              {data?.admin?.permission.map((value, index) => {
                if (value.permission)
                  return (
                    <AuthorityCheck
                      key={index}
                      accessName={value.accessName}
                      permission={value.permission}
                    />
                  );
              })}
            </div>
            <div className="permissionList">
              <TextLine
                text="허용되지 않음"
                fontSize={18}
                margin="0 0 10px 0"
              />
              {data?.admin?.permission.map((value, index) => {
                if (!value.permission)
                  return (
                    <AuthorityCheck
                      key={index}
                      accessName={value.accessName}
                      permission={value.permission}
                    />
                  );
              })}
            </div>
          </div>
        </div>
      </div>
    </StMyPage>
  );
};

const StMyPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  height: calc(100vh - 75px);
  padding: 48px;
  box-sizing: border-box;
  overflow-x: hidden;
  .infoBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .lineForInfo {
      display: flex;
      justify-content: space-between;
      width: 400px;
    }
    .lineForInfoColumn {
      display: flex;
      flex-direction: column;
      .permissionBox {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 600px;
        .permissionList {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
`;

export default MypageTemplate;

import React, { forwardRef } from "react";
import { styled } from "styled-components";

interface InputProps {
  inputRef?: React.RefObject<HTMLInputElement>;
  width: number;
  height: number;
  placeholderText?: string;
  fontSize?: number;
  fontcolor?: string;
  placeholdercolor?: string;
  padding?: string;
  borderradius?: number;
  border?: string;
  type?: string;
  onblur?: () => void;
  onchange?: () => void;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props: InputProps) => {
  return (
    <StInput
      ref={props.inputRef}
      width={props.width}
      height={props.height}
      fontSize={props.fontSize}
      placeholdercolor={props.placeholdercolor}
      placeholder={props.placeholderText}
      padding={props.padding}
      borderradius={props.borderradius}
      border={props.border}
      type={props.type}
      onChange={props.onchange}
      onBlur={props.onblur}
    />
  );
});

const StInput = styled.input<InputProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  font-size: ${(props) => props.fontSize}px;
  font-family: "regular";
  padding: ${(props) => props.padding};
  ::placeholder {
    color: ${(props) => props.placeholdercolor};
  }
  outline: none;
  box-sizing: border-box;
  border-radius: ${(props) => props.borderradius}px;
  border: ${(props) => props.border};
`;

export default Input;

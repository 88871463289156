import { styled } from "styled-components";
import NoticePostContentsOptions from "./NoticePostContentsOptions";
import { Editor } from "@toast-ui/react-editor";
import { useEffect, useRef, useState } from "react";
import "@toast-ui/editor/dist/toastui-editor.css";
import {
  INoticeContentsChildProps,
  INoticeId,
  INoticeParentsCategoryOptionsProps,
  INoticePresignedUrl,
  INoticeUpload,
} from "../NoticeList/interface/i.notice";
import customAxios from "../../api/handler";
import axios from "axios";
import { confirmKeyList } from "../../atom";
import { useRecoilState } from "recoil";

const NoticePostContents = ({
  props,
}: {
  props: INoticeContentsChildProps;
}) => {
  const titleInput = useRef<HTMLInputElement | null>(null); /** 공지사항 제목 */
  const contextInput = useRef<Editor>(null); /** 공지사항 내용 */
  const [contentsOptionsInput, setContentsOptionsInput] = useState<
    string | null
  >(null); /** 공지사항 카테고리 */
  const [isImportantOptionsInput, setIsImportantOptionsInput] = useState<
    boolean | null
  >(null);
  const [exposeServerOptionsInput, setExposeServerOptionsInput] = useState<
    string | null
  >(null);
  const [confirmImageS3KeyList, setConfirmImageS3KeyList] = useRecoilState(confirmKeyList);
  const [image, setImage] = useState<
    Array<{
      image: File | string | null | ArrayBuffer | undefined;
    }>
  >([]);
  const [noticeId, setNoticeId] = useState<number>(0);

  let id = 1;
  let isUploading = false;

  const saveNoticeCategoryOptionsHandler = (
    data: INoticeParentsCategoryOptionsProps
  ) => {
    setContentsOptionsInput(data.categoryOptions);
    props.saveCategory(data.categoryOptions);
  };

  const saveNoticeIsImportantOptionsHandler = (
    data: INoticeParentsCategoryOptionsProps
  ) => {
    setIsImportantOptionsInput(data.isImportant);
    props.saveIsImportant(data.isImportant);
  };

  const saveNoticeExposeOptionsHandler = (
    data: INoticeParentsCategoryOptionsProps
  ) => {
    setExposeServerOptionsInput(data.exposeServer);
    props.saveExpose(data.exposeServer);
  };

  useEffect(() => {
    const fetchNoticeId = async () => {
      await customAxios.post(`/admin/proc/notices/pre-id`).then(({ data }) => {
        const { noticeId } = data as any;

        setNoticeId(noticeId);
      });
    };

    fetchNoticeId();
  }, []);

  useEffect(() => {
    handleImageButton
  }, [confirmKeyList])
  
  useEffect(() => {
    setConfirmImageS3KeyList([])
  }, []);
  
  const handleImageButton = async (event: React.BaseSyntheticEvent) => {
    const file = event.target.files?.[0];

    isUploading = true;

    const allowedExtensions = [".jpg", ".png", ".jpeg"];
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    if (!allowedExtensions.includes(`.${fileExtension}`)) {
      alert("허용되지 않는 파일 유형입니다.");
      return;
    }

    if (file.size > 10 * 1024 * 1024) {
      alert("파일 크기가 너무 큽니다.");
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        setImage(
          (
            preState: Array<{
              image: File | string | null | ArrayBuffer | undefined;
            }>
          ) => {
            return [...preState, { image: e.target?.result }];
          }
        );

        const formData = new FormData();
        formData.append("file", file);

        await customAxios
          .post(`/admin/proc/notices/pre-signed-url`, {
            noticeId,
          })
          .then(async ({ data }) => {
            const { preSignedUrl } = data as INoticePresignedUrl;

            const { imageUploadUrl, imageS3Key } = preSignedUrl;

            await uploadS3(imageUploadUrl, file);

            setConfirmImageS3KeyList((preState) => {
              const newArray = [...preState];

              newArray.push({
                id,
                key: imageS3Key,
              });

              id = id + 1;

              return newArray;
            });
          })
          .then(() => {
            isUploading = false;

            handleNoticeSaveData();
          });
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    handleImageButton;
  }, [confirmImageS3KeyList]);

  const handleImageRemoveButton = (idx: number) => {
    const imageList = [...image];

    imageList.filter((img, i) => i !== idx);
    setImage((prevState) => prevState.filter((_, i) => i !== idx));

    confirmImageS3KeyList.filter((img, i) => img.id !== idx + 1);
  };

  const handleNoticeSaveData = () => {
    if (!isUploading) {
      setTimeout(() => {
        const data = {
          noticeId,
          title: titleInput.current?.value,
          context: contextInput.current?.getInstance().getHTML(),
          category: contentsOptionsInput,
          isImportant: isImportantOptionsInput,
          confirmImageS3KeyList: confirmImageS3KeyList,
          exposeServer: exposeServerOptionsInput
        };
        props.saveNoticeData({
          notice: data,
        });
      }, 1200);
    } else {
      const data = {
        noticeId,
        title: titleInput.current?.value,
        context: contextInput.current?.getInstance().getHTML(),
        category: contentsOptionsInput,
        isImportant: isImportantOptionsInput,
        confirmImageS3KeyList: confirmImageS3KeyList,
        exposeServer: exposeServerOptionsInput
      };
      props.saveNoticeData({
        notice: data,
      });
    }
  };

  return (
    <StyledNoticePostContents>
      <div className="titleSection">
        <div className="titleBox">
          <div className="title"></div>
          제목
        </div>
        <input
          type="text"
          className="inputTitle"
          ref={titleInput}
          onChange={handleNoticeSaveData}
        />
      </div>
      <NoticePostContentsOptions
        props={{
          saveNoticeCategoryOptionsHandler,
          saveNoticeIsImportantOptionsHandler,
          saveNoticeExposeOptionsHandler,
          handleNoticeSaveData
        }}
      ></NoticePostContentsOptions>
      <div className="contextSection">
        <div className="contextBox">
          <div className="context">내용</div>
        </div>
        <Editor
          height="380px"
          placeholder="공지사항 내용을 입력해 주세요"
          ref={contextInput}
          onChange={handleNoticeSaveData}
          initialEditType="wysiwyg"
        ></Editor>
      </div>
      <div className="imageSection">
        <div className="imageBox">
          <div className="image">이미지</div>
          <label htmlFor="imageUpload" className="uploadButton">
            <div className="uploadText">Upload</div>
          </label>
          <input
            type="file"
            id="imageUpload"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageButton}
          />
        </div>

        <div className="imageList">
          {image.length > 0 &&
            image.map((img, idx) => {
              return (
                <div className="imageBox" key={idx + 1}>
                  <div
                    className="remove"
                    onClick={() => handleImageRemoveButton(idx)}
                  ></div>
                  <img
                    className="image"
                    src={img.image as string | undefined}
                    alt=""
                  />
                </div>
              );
            })}
        </div>

        <div></div>
      </div>
    </StyledNoticePostContents>
  );
};

export default NoticePostContents;

export const StyledNoticePostContents = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 6px;
  border-radius: 12px;
  flex-direction: column;
  font-family: "semibold";
  font-style: normal;

  .titleSection {
    display: flex;
    justify-content: flex-start;
    width: 900px;
    height: 10px;
    padding: 20px;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
    font-family: "semibold";

    .titleBox {
      border: 1px solid rgba(128, 128, 128, 0.5);
      border-radius: 12px;
      padding: 18px;
      width: 30px;

      .title {
        align-items: center;
      }
    }

    .inputTitle {
      width: 70%;
      border-radius: 8px;
      border: 1px solid rgba(128, 128, 128, 0.5);
      background: var(--gray-white, #fff);
      height: 300%;
      padding: 10px;

      font-size: 16px;
    }
  }

  .contextSection {
    display: flex;
    justify-content: flex-start;
    width: 1237px;
    height: 50%;
    padding: 20px;
    align-items: center;
    gap: 15px;
    margin-bottom: 40px;

    .contextBox {
      border: 1px solid rgba(128, 128, 128, 0.5);
      border-radius: 12px;
      padding: 18px;
      width: 30px;
      height: 350px;
      display: flex;
      align-content: center;
      align-items: center;

      .context {
        height: 10px;
      }
    }

    .toastui-editor-defaultUI {
      display: flex;
      width: 121.5%;
      border-radius: 8px;
      border: 1px solid rgba(128, 128, 128, 0.5);
      background: var(--gray-white, #fff);
      height: 100%;

      font-size: 22px;
    }
  }

  .imageSection {
    display: flex;
    justify-content: flex-start;
    width: 100%px;
    height: 40px;
    padding: 20px;
    align-items: center;
    gap: 15px;

    .imageBox {
      border: 1px solid rgba(128, 128, 128, 0.5);
      border-radius: 12px;
      padding: 18px;
      width: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;

      .image {
        height: 40px;
        white-space: nowrap;
        align-items: center;
      }

      .uploadButton {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        border: 1px solid var(--main-blue, #1a75ff);
        background-color: #1a75ff;
        border-radius: 12px;
        height: 40px;
        width: 170%;

        .uploadText {
          font-size: 14px;
          color: white;
        }
      }
    }

    .imageList {
      width: 80%;
      height: 280%;
      background-color: white;
      border: 1px solid var(--main-blue, #1a75ff);
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      position: relative;

      .imageBox {
        display: flex;
        gap: 20px;
        margin: 0px 10px;
        width: 5%;
        height: 50%;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 6px;
        border-radius: 12px;
        position: relative;

        .image {
          margin-bottom: 20px;
          max-width: 100%; /* 이미지의 최대 너비를 부모 요소인 .image-box의 너비에 맞춥니다. */
          max-height: 100%; /* 이미지의 최대 높이를 부모 요소인 .image-box의 높이에 맞춥니다. */
        }

        .remove {
          cursor: pointer;
        }
        .remove:before,
        .remove:after {
          position: absolute;
          right: 7px;
          top: 6px;
          content: "";
          height: 8px;
          width: 1px;
          background-color: #222;
          margin-right: 5px;
        }
        .remove:before {
          transform: rotate(45deg);
        }
        .remove:after {
          transform: rotate(-45deg);
        }
      }
    }
  }
`;

const uploadS3 = async (url: string, image: any) => {
  await axios
    .put(`${url}`, image, {
      headers: {
        "Content-Type": "image/jpg",
      },
    })
    .catch((e) => {
      console.log("ERROR:", e);
    })
    .then((response) => {
      console.log(response);
    });
};

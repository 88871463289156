import React, { useRef, useState } from "react";
import Input from "../atom/Input";
import Button from "../atom/Button";
import { styled } from "styled-components";
import { setCookie } from "../../Cookie";
import { useNavigate } from "react-router-dom";
import customAxios from "../../api/handler";
import useToast from "../../hook/useToast";
import TextLine from "../atom/TextLine";

const LoginForm: React.FC = () => {
  const showToast = useToast();
  const navigate = useNavigate();
  const loginIdInputRef = useRef<HTMLInputElement | null>(null);
  const loginPasswordInputRef = useRef<HTMLInputElement | null>(null);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    customAxios
      .post(`/admin/proc/auth/sign-in`, {
        email: loginIdInputRef.current?.value + "@edint.io",
        password: loginPasswordInputRef.current?.value,
      })
      .then(({ data }) => {
        setCookie("accessToken", data.accessToken, {
          expires: new Date(Date.now() + 1800000),
        });
        setCookie("refreshToken", data.refreshToken, {
          expires: new Date(Date.now() + 1209600033),
        });
        navigate(`/common/landing`);
      })
      .catch(() => {
        showToast({
          message: "뭔가 착오가 있는것 같습니다. 다시 입력해주세요",
        });
      });
  };

  console.log(loginIdInputRef.current?.value);

  return (
    <StForm onSubmit={submitHandler}>
      <div className="wrapper">
        <div className="inputBox">
          <div className="emailPart">
            <Input
              width={180}
              height={48}
              fontSize={16}
              fontcolor=""
              placeholderText="아이디"
              padding="12px 16px;"
              placeholdercolor="#888888"
              inputRef={loginIdInputRef}
              borderradius={5}
              border="1px solid black"
            />
            <TextLine text="@ edint.io" fontSize={16} margin="0 0 0 10px" />
          </div>
          <Input
            width={360}
            height={48}
            fontSize={16}
            placeholderText="비밀번호"
            padding="12px 16px;"
            placeholdercolor="#888888"
            inputRef={loginPasswordInputRef}
            borderradius={5}
            type="password"
            border="1px solid black"
          />
        </div>
        <Button
          width={360}
          height={56}
          fontSize={18}
          fontcolor="white"
          text="로그인"
          padding="10px 16px"
          border="none"
          borderradius={8}
          backgroundcolor="#1A75FF"
        />
        <div className="signupBox" onClick={() => navigate(`/signup`)}>
          회원가입
        </div>
      </div>
    </StForm>
  );
};

const StForm = styled.form`
  display: grid;
  justify-content: center;
  .wrapper {
    .inputBox {
      display: grid;
      gap: 8px;
      margin-bottom: 24px;
      .emailPart {
        display: flex;
        align-items: center;
      }
    }
    .signupBox {
      width: 60px;
      text-align: end;
      margin-top: 12px;
      margin-left: auto;
      font-size: 16px;
      font-family: "regular";
      line-height: 20px;
      color: #888888;
      cursor: pointer;
    }
  }
`;

export default LoginForm;

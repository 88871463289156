import { UseQueryResult, useQuery } from "react-query";
import apis from "../../api/getApi";

interface DataProp {
  uid: string | undefined;
}

interface ApiResponse {
  name: string;
  nickName: string;
  department: string;
  email: string;
  phoneNumber: string;
  signUpDate: string;
  lastLoginDate: string;
  accessPages: string[];
}

const useGetMemberSpecific = ({
  uid,
}: DataProp): UseQueryResult<ApiResponse, unknown> => {
  const fetcher = async () => {
    const response = await apis.getMember({ uid });
    return response.data;
  };
  return useQuery(["memberSpecific"], fetcher);
};

export default useGetMemberSpecific;

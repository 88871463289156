import React, { Children, ReactNode } from "react";
import styled from "styled-components";
import { device } from "../../device";

interface FlexDivProp {
  divRef?: React.MutableRefObject<any>;
  children: ReactNode;
  onclick?: () => void;
  font_color?: string;
  pc_font_family?: string;
  pc_font_size?: number;
  background_color?: string;
  z_index?: number;
  position?: string;
  top?: number;
  left?: number;
  width?: string;
  height?: number;
  pc_padding?: string;
  pc_display?: string;
  grid_template_column?: string;
  grid_template_rows?: string;
  pc_justify?: string;
  pc_align_items?: string;
  pc_lineheight?: number;
  pc_margin?: string;
  pc_gap?: number;
  border?: string;
  border_top?: string;
  border_bottom?: string;
  border_left?: string;
  border_right?: string;
  border_radius?: number;
  pc_text_align?: string;
  display?: string;
  justify?: string;
  align_items?: string;
  padding?: string;
  font_size?: number;
  font_family?: string;
  lineheight?: number;
  margin?: string;
  gap?: number;
  text_align?: string;
  box_shadow?: string;
}

const DivTwoPart: React.FC<FlexDivProp> = (props) => {
  return (
    <StDiv
      ref={props.divRef}
      onClick={props.onclick}
      width={props.width}
      height={props.height}
      pc_font_family={props.pc_font_family}
      font_color={props.font_color}
      pc_font_size={props.pc_font_size}
      position={props.position}
      z_index={props.z_index}
      top={props.top}
      left={props.left}
      border={props.border}
      border_radius={props.border_radius}
      border_bottom={props.border_bottom}
      border_top={props.border_top}
      border_left={props.border_left}
      border_right={props.border_right}
      box_shadow={props.box_shadow}
      pc_padding={props.pc_padding}
      padding={props.padding}
      pc_display={props.pc_display}
      grid_template_column={props.grid_template_column}
      grid_template_rows={props.grid_template_rows}
      pc_align_items={props.pc_align_items}
      background_color={props.background_color}
      pc_lineheight={props.pc_lineheight}
      pc_margin={props.pc_margin}
      pc_gap={props.pc_gap}
      pc_text_align={props.pc_text_align}
      pc_justify={props.pc_justify}
      display={props.display}
      align_items={props.align_items}
      font_size={props.font_size}
      font_family={props.font_family}
      lineheight={props.lineheight}
      margin={props.margin}
      gap={props.gap}
      text_align={props.text_align}
      justify={props.justify}
    >
      {props.children}
    </StDiv>
  );
};

const StDiv = styled.div<FlexDivProp>`
  font-family: ${(props) => props.font_family};
  color: ${(props) => props.font_color};
  font-size: ${(props) => props.font_size}px;
  position: ${(props) => props.position};
  z-index: ${(props) => props.z_index};
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  width: ${(props) => props.width};
  height: ${(props) => props.height}px;
  padding: ${(props) => props.padding};
  display: ${(props) => props.display};
  grid-template-columns: ${(props) => props.grid_template_column};
  grid-template-rows: ${(props) => props.grid_template_rows};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align_items};
  gap: ${(props) => props.gap}px;
  line-height: ${(props) => props.lineheight}px;
  margin: ${(props) => props.margin};
  text-align: ${(props) => props.text_align};
  background-color: ${(props) => props.background_color};
  border: ${(props) => props.border};
  border-top: ${(props) => props.border_top};
  border-bottom: ${(props) => props.border_bottom};
  border-left: ${(props) => props.border_left};
  border-right: ${(props) => props.border_right};
  border-radius: ${(props) => props.border_radius}px;
  box-shadow: ${(props) => props.box_shadow};
  box-sizing: border-box;
  @media ${device.pc} {
    display: ${(props) => props.pc_display};
    justify-content: ${(props) => props.pc_justify};
    align-items: ${(props) => props.pc_align_items};
    gap: ${(props) => props.pc_gap}px;
    font-size: ${(props) => props.pc_font_size}px;
    font-family: ${(props) => props.pc_font_family};
    line-height: ${(props) => props.pc_lineheight}px;
    padding: ${(props) => props.pc_padding};
    margin: ${(props) => props.pc_margin};
    text-align: ${(props) => props.pc_text_align};
  }
`;

export default DivTwoPart;

import React, { useLayoutEffect } from "react";
import LoginInputField from "../../atomic/organism/LoginInputField";
import loginLeftImg from "../../img/loginBackground.png";
import { styled } from "styled-components";

const Login: React.FC = () => {
  const ImgPreload = () => {
    let img = new Image();
    img.src = loginLeftImg;
  };

  useLayoutEffect(() => {
    ImgPreload();
  }, []);

  return (
    <StLogin>
      <div className="imgBox">
        <img src={loginLeftImg} alt="" />
      </div>
      <LoginInputField />
    </StLogin>
  );
};

const StLogin = styled.div`
  display: flex;
  .imgBox {
    width: 70%;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;

export default Login;

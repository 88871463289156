import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 40%;
  height: 45px;
  margin: 5px;
  /* padding-left: 5px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border: 1px solid gray;
  border-radius: 5px;

  p {
    font-size: 15px;
    margin: 5px;
  }
`;

interface Props {
  text: string;
  value: number;
}

function formatNumberWithIntl(number: number) {
  return new Intl.NumberFormat().format(number);
}

const InformationBox = ({ text, value }: Props) => {
  return (
    <Container>
      <p>{text}</p>
      <p>{formatNumberWithIntl(value)}</p>
    </Container>
  );
};

export default InformationBox;

import styled from "styled-components";
import PaymentImage from "../../../img/payment.png";

const Header: React.FC<{
  title: string;
  searchContent?: boolean;
  searchWord?: string;
  setSearchWord?: React.Dispatch<React.SetStateAction<string>>;
  onClickSearchButton?: () => void;
  iconBgColor: string;
}> = ({
  title,
  searchContent,
  searchWord,
  setSearchWord,
  onClickSearchButton,
  iconBgColor,
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setSearchWord) setSearchWord(e.target.value);
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        boxSizing: "border-box",
        fontSize: 24,
        padding: "25px 40px",
        borderRadius: 8,
        color: "#464a53",
        boxShadow: "1px 1px 3px #15254e3d",
        fontFamily: "semiBold",
        overflow: "scroll",
        scrollbarWidth: "none",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
            backgroundColor: iconBgColor,
            borderRadius: 8,
            padding: 10,
            marginRight: 20,
          }}
        >
          <img src={PaymentImage} alt="" style={{ width: 30 }} />
        </div>
        {title}
      </div>
      {searchContent ? (
        <SearchBox>
          <input
            type="text"
            placeholder="기업명으로 검색해주세요."
            value={searchWord}
            onChange={onChange}
          />
          <button onClick={onClickSearchButton}>검색</button>
        </SearchBox>
      ) : null}
    </div>
  );
};

export default Header;

const SearchBox = styled.div`
  input {
    outline: none;
    border: none;
    background-color: rgb(227, 232, 245);
    font-size: 18px;
    font-family: "regular";
    padding: 10px 20px;
    border-radius: 5px;
    width: 300px;
    box-shadow: inset 1px 1px 2px #15254e28;
  }
  button {
    outline: none;
    border: none;
    color: #fff;
    border-radius: 5px;
    font-size: 18px;
    font-family: "regular";
    padding: 9px 19px;
    background-color: #2c448f;
    box-shadow: 1px 1px 1px #15254e28;
    border: 1px solid #f9f9f9;
    box-sizing: border-box;
    margin-left: 10px;
  }
`;

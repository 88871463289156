import React, { Dispatch, SetStateAction } from "react";
import { InputType } from "../../Coupon/CouponList/couponInput";

import "../../marketingCss.css";
import { PromotionDataType } from "../CreatePromotion";

const PromotionInput: React.FC<InputType<PromotionDataType>> = ({
  type,
  value,
  dataKey,
  setValue,
  defaultValue,
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue;
    if (type === "number") {
      newValue = Number(e.target.value).toString().replaceAll("^0+", "");
    } else {
      newValue = e.target.value;
    }
    setValue({ ...value, [dataKey]: newValue });
  };
  return (
    <input
      type={type}
      value={value[dataKey]}
      onChange={onChange}
      defaultValue={defaultValue}
      className="marketing_input"
    />
  );
};

export default PromotionInput;

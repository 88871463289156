import React from "react";

//img
import logo from "../../img/headerLogo.svg";
import { useNavigate } from "react-router-dom";

interface imgStyle {
  width: number;
  height: number;
  onclick: React.Dispatch<React.SetStateAction<number>>;
}

const LogoImg: React.FC<imgStyle> = (props) => {
  const navigate = useNavigate();

  return (
    <img
      alt=""
      src={logo}
      width={props.width}
      height={props.height}
      onClick={() => {
        navigate("/common/landing");
        props.onclick(1);
      }}
      style={{ cursor: "pointer" }}
    />
  );
};

export default LogoImg;

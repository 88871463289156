import { useState } from "react";
import styled from "styled-components";

const QuestionBoardCategory = () => {
  return (
    <StyledCategory>
      <input type="checkbox" className="checkbox" />
      <div className="writer">작성자</div>
      <div className="title">제목</div>
      <div className="category">유형</div>
      <div className="createdAt">작성일</div>
    </StyledCategory>
  );
};

export default QuestionBoardCategory;

const StyledCategory = styled.div`
  display: flex;
  height: 10px;
  padding: 12px 24px;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  white-space: nowrap;

  border-bottom: 1px solid var(--gray-light-gray, #d7d7d7);
  background: var(--gray-light-gray-1, #f9f9f9);

  .checkbox {
    display: flex;
    width: 32px;
    height: 15px;
    padding: 5.665px;
    align-content: flex-start;
  }

  .category {
    display: flex;
    width: 70px;
  }

  .writer {
    width: 170px;
  }

  .title {
    display: flex;
    width: 840px;
  }

  .createdAt {
    width: 100px;
  }
`;

import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  IQuestionCHildComponentImageProps,
  IUploadImageList,
} from "../interface/i.question";
import { v4 } from "uuid";
import { useLocation } from "react-router-dom";

const QuestionDetailComponentsImage = ({
  props,
}: {
  props: IQuestionCHildComponentImageProps;
}) => {
  const { questionComponentUuid, questionUuid, signedImageList } = props;
  const location = useLocation();
  const entPath = location.pathname.endsWith("/update");

  const defaultUploadList = !signedImageList ? [] : signedImageList;
  const defaultImageList = !signedImageList
    ? []
    : signedImageList?.map((image, idx) => {
        return {
          image: image.url,
          key: image.key,
          id: idx + 1,
        };
      });
  const [image, setImage] = useState<
    Array<{
      image: File | string | null | ArrayBuffer | undefined;
    }>
  >([]);

  if (entPath) {
    useEffect(() => {
      setImage(defaultImageList);
      setConfirmImageS3KeyList(defaultUploadList);
      setUploadList(defaultUploadList);
    }, [signedImageList]);

    useEffect(() => {
      props.saveSetConfirmImage(confirmImageS3KeyList);
      props.saveSetUploadList(uploadList);
    }, [image]);
  }

  const [confirmImageS3KeyList, setConfirmImageS3KeyList] =
    useState<Array<IUploadImageList>>(defaultUploadList); /** 서버 전송용 */
  const [uploadList, setUploadList] =
    useState<Array<IUploadImageList>>(defaultUploadList); /** S3 업로드용 */
  const [id, setId] = useState(1);

  useEffect(() => {
    props.saveSetConfirmImage(confirmImageS3KeyList);
  }, [confirmImageS3KeyList]);

  useEffect(() => {
    props.saveSetUploadList(uploadList);
    props.saveSetConfirmImage(confirmImageS3KeyList);
  }, [uploadList, confirmImageS3KeyList]);

  useEffect(() => {}, []);

  const handleImageButton = async (event: React.BaseSyntheticEvent) => {
    const file = event.target.files?.[0];
    const s3Key = `question/${questionUuid}/${questionComponentUuid}/${v4()}`;
    setUploadList((preState) => {
      const newArray = [...preState];

      newArray.push({
        id,
        key: s3Key,
        file,
      });

      return newArray;
    });

    const allowedExtensions = [".jpg", ".png", ".jpeg"];
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    if (!allowedExtensions.includes(`.${fileExtension}`)) {
      alert("허용되지 않는 파일 유형입니다.");
      return;
    }

    if (file.size > 10 * 1024 * 1024) {
      alert("파일 크기가 너무 큽니다.");
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        setImage(
          (
            preState: Array<{
              image: File | string | null | ArrayBuffer | undefined;
            }>
          ) => {
            return [...preState, { image: e.target?.result }];
          }
        );

        const formData = new FormData();
        formData.append("file", file);

        setConfirmImageS3KeyList((preState) => {
          const newArray = [...preState];

          newArray.push({
            id,
            key: s3Key,
          });

          return newArray;
        });
      };
      reader.readAsDataURL(file);
    }
    setId(id + 1);
  };

  const handleImageRemoveButton = (idx: number) => {
    const imageList = [...image];

    imageList.filter((img, i) => i !== idx);
    setImage((prevState) => prevState.filter((_, i) => i !== idx));

    confirmImageS3KeyList.filter((img, i) => img.id !== idx + 1);
    setConfirmImageS3KeyList((prevState) =>
      prevState.filter((_, i) => i !== idx)
    );
    uploadList.filter((img, i) => img.id !== idx + 1);
    setUploadList((prevState) => prevState.filter((_, i) => i !== idx));
  };
  return (
    <StyledComponentsImage>
      <div className="answerUpload">
        <div className="imageBox">
          <div className="image">이미지</div>
          <label htmlFor="imageUpload" className="uploadButton">
            <div className="uploadText">Upload</div>
          </label>
          <input
            type="file"
            id="imageUpload"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageButton}
          />
        </div>

        <div className="imageList">
          {image.length > 0
            ? image.map((img, idx) => {
                return (
                  <div className="imageBox" key={idx + 1}>
                    <div
                      className="remove"
                      onClick={() => handleImageRemoveButton(idx)}
                    ></div>
                    <img
                      className="image"
                      src={img.image as string | undefined}
                      alt=""
                    />
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </StyledComponentsImage>
  );
};

export default QuestionDetailComponentsImage;

const StyledComponentsImage = styled.div`
  .answerUpload {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;

    .imageBox {
      border: 1px solid rgba(128, 128, 128, 0.5);
      border-radius: 12px;
      padding: 18px;
      width: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;

      .image {
        height: 40px;
        white-space: nowrap;
        align-items: center;
      }

      .uploadButton {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        border: 1px solid var(--main-blue, #1a75ff);
        background-color: #1a75ff;
        border-radius: 12px;
        height: 40px;
        width: 170%;

        .uploadText {
          font-size: 14px;
          color: white;
        }
      }
    }

    .imageList {
      width: 90%;
      max-height: 95%;
      background-color: white;
      border: 1px solid var(--main-blue, #1a75ff);
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      position: relative;

      .imageBox {
        display: flex;
        gap: 20px;
        margin: 0px 10px;
        width: 5%;
        height: 50%;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 6px;
        border-radius: 12px;
        position: relative;

        .image {
          margin-bottom: 20px;
          max-width: 100%; /* 이미지의 최대 너비를 부모 요소인 .image-box의 너비에 맞춥니다. */
          max-height: 100%; /* 이미지의 최대 높이를 부모 요소인 .image-box의 높이에 맞춥니다. */
        }

        .remove {
          cursor: pointer;
        }
        .remove:before,
        .remove:after {
          position: absolute;
          right: 7px;
          top: 6px;
          content: "";
          height: 8px;
          width: 1px;
          background-color: #222;
          margin-right: 5px;
        }
        .remove:before {
          transform: rotate(45deg);
        }
        .remove:after {
          transform: rotate(-45deg);
        }
      }
    }
  }
`;

import axios from "axios";
import { StyledNoticePostContents } from "../../../NoticeWrite/NoticePostContents";
import { Editor, Viewer } from "@toast-ui/react-editor";
import { useRef, useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import customAxios from "../../../../api/handler";
import { confirmKeyList } from "../../../../atom";
import NoticePostContentsOptions from "../../../NoticeWrite/NoticePostContentsOptions";
import {
  INoticeContentsChildProps,
  INoticeDetail,
  INoticeParentsCategoryOptionsProps,
  INoticePresignedUrl,
} from "../../interface/i.notice";
import "@toast-ui/editor/dist/toastui-editor.css";

const NoticeUpdateContents = ({
  props,
}: {
  props: INoticeContentsChildProps;
}) => {
  const {
    noticeId,
    noticeTitle,
    noticeContext,
    noticeCategory,
    noticeViewCount,
    isDone,
    isImportant,
    createdAt,
    signedImageList,
  } = props?.notice as INoticeDetail;

  const imgList = signedImageList.map((img, idx) => {
    return {
      id: idx + 1,
      image: img?.noticeImageUrl,
      key: img?.noticeImageS3Key
    };
  });

  const titleInput = useRef<any>(noticeTitle); /** 공지사항 제목 */

  const contextInput = useRef<Editor>(null); /** 공지사항 내용 */
  const [contentsOptionsInput, setContentsOptionsInput] = useState<
    string | null
  >(null); /** 공지사항 카테고리 */
  const [isImportantOptionsInput, setIsImportantOptionsInput] = useState<
    boolean | null
  >(null); /** 중요 공지사항인지 아닌지 */
  const [exposeServerOptionsInput, setExposeServerOptionsInput] = useState<
    string | null
  >(null);
  const [confirmImageS3KeyList, setConfirmImageS3KeyList] =
    useRecoilState(confirmKeyList); /** 이미지 키 리스트 */
  const [image, setImage] = useState<
    Array<{
      image: File | string | null | ArrayBuffer | undefined;
      key: string
    }>
  >(
    imgList as unknown as Array<{
      image: File | string | null | ArrayBuffer | undefined;
      key: string
    }>
  ); /** 이미지 리스트에 보여줄 view 리스트 */

  let id = imgList.length + 1;
  let isUploading = false;

  const saveNoticeCategoryOptionsHandler = (
    data: INoticeParentsCategoryOptionsProps
  ) => {
    setContentsOptionsInput(data.categoryOptions);
    props.saveCategory(data.categoryOptions);
  };

  const saveNoticeIsImportantOptionsHandler = (
    data: INoticeParentsCategoryOptionsProps
  ) => {
    setIsImportantOptionsInput(data.isImportant);
    props.saveIsImportant(data.isImportant);
  };

  const saveNoticeExposeOptionsHandler = (
    data: INoticeParentsCategoryOptionsProps
  ) => {
    setExposeServerOptionsInput(data.exposeServer);
    props.saveIsImportant(data.exposeServer);
  };

  useEffect(() => {
    saveNoticeIsImportantOptionsHandler;
  }, [isImportantOptionsInput]);

  useEffect(() => {
    handleImageButton;
  }, [confirmKeyList]);

  const handleImageButton = async (event: React.BaseSyntheticEvent) => {
    const file = event.target.files?.[0];

    isUploading = true;

    if (file) {
      const reader = new FileReader();
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        const allowedExtensions = [".jpg"];
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        if (!allowedExtensions.includes(`.${fileExtension}`)) {
          alert("허용되지 않는 파일 유형입니다.");
          return;
        }

        if (file.size > 10 * 1024 * 1024) {
          alert("파일 크기가 너무 큽니다.");
          return;
        }

        const formData = new FormData();
        formData.append("file", file);

        await customAxios
          .post(`/admin/proc/notices/pre-signed-url`, {
            noticeId,
          })
          .then(async ({ data }) => {
            const { preSignedUrl } = data as INoticePresignedUrl;

            const { imageUploadUrl, imageS3Key } = preSignedUrl;

            await uploadS3(imageUploadUrl, file);

            setImage(
              (
                preState: Array<{
                  image: File | string | null | ArrayBuffer | undefined;
                  key: string
                }>
              ) => {
                return [
                  ...preState,
                  { image: e.target?.result, key: imageS3Key },
                ];
              }
            );

            setConfirmImageS3KeyList((preState) => {
              const newArray = [...preState];

              newArray.push({
                id,
                key: imageS3Key,
              });

              id = id + 1;

              return newArray;
            });
          })
          .then(() => {
            isUploading = false;

            handleNoticeSaveData();
          });
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    setConfirmImageS3KeyList(imgList);   
  }, []);

  useEffect(() => {
    handleImageButton;
  }, [confirmImageS3KeyList]);

  useEffect(() => {
    handleImageRemoveButton;
  }, [confirmImageS3KeyList]);

  const handleImageRemoveButton = async(idx: number) => {
    const imageList = [...image];

    imageList.filter((img, i) => i !== idx);
    setImage((preState) => preState.filter((_, i) => i !== idx));

    const preArray = [...confirmImageS3KeyList];
    const deleteKey = preArray.filter(async (_, i) => {
        console.log(_, idx);
        
      if (_.id === idx + 1) {
        await customAxios
          .delete(`/admin/proc/notices/update/${+noticeId}?s3Key=${_.key}`)
          .then((res) => {
            console.log(res);
            
          })
          .catch(() => {});
      } else {
        return _;
      }
    }); 

    setConfirmImageS3KeyList(deleteKey);

  };

  const handleNoticeSaveData = () => {
    
    if (!isUploading) {
      setTimeout(() => {
        const data = {
          noticeId,
          title: titleInput.current?.value,
          context: contextInput.current?.getInstance().getHTML(),
          category: contentsOptionsInput,
          isImportant: isImportantOptionsInput,
          confirmImageS3KeyList: confirmImageS3KeyList,
        };
        props.saveNoticeData({
          notice: data,
        });
      }, 1200);
    } else {
      const data = {
        noticeId,
        title: titleInput.current?.value,
        context: contextInput.current?.getInstance().getHTML(),
        category: contentsOptionsInput,
        isImportant: isImportantOptionsInput,
        confirmImageS3KeyList: confirmImageS3KeyList,
      };
      props.saveNoticeData({
        notice: data,
      });
    }
  };

  return (
    <StyledNoticePostContents onChange={handleNoticeSaveData}>
      <div className="titleSection">
        <div className="titleBox">
          <div className="title"></div>
          제목
        </div>
        <input
          type="text"
          className="inputTitle"
          ref={titleInput}
          onChange={handleNoticeSaveData}
          defaultValue={noticeTitle}
        />
      </div>
      <NoticePostContentsOptions
        props={{
          saveNoticeCategoryOptionsHandler,
          saveNoticeIsImportantOptionsHandler,
          saveNoticeExposeOptionsHandler,
          handleNoticeSaveData,
          noticeCategory,
          isImportant,
        }}
      ></NoticePostContentsOptions>
      <div className="contextSection">
        <div className="contextBox">
          <div className="context">내용</div>
        </div>{
            !contentsOptionsInput ?
        <Editor
          height="380px"
          placeholder="공지사항 내용을 입력해 주세요"
          ref={contextInput}
          onChange={handleNoticeSaveData}
          initialValue={noticeContext}
          initialEditType="wysiwyg"
        ></Editor>
        : <Editor
        height="380px"
        placeholder="유형을 먼저 골라 주세요"
        ref={contextInput}
        onChange={handleNoticeSaveData}
        initialValue={noticeContext}
      ></Editor>
}
      </div>
      <div className="imageSection">
        <div className="imageBox">
          <div className="image">이미지</div>
          <label htmlFor="imageUpload" className="uploadButton">
            <div className="uploadText">Upload</div>
          </label>
          <input
            type="file"
            id="imageUpload"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageButton}
          />
        </div>

        <div className="imageList">
          {image.length > 0
            ? image.map((img, idx) => {
                return (
                  <div className="imageBox" key={idx + 1}>
                    <div
                      className="remove"
                      onClick={() => handleImageRemoveButton(idx)}
                    ></div>
                    <img
                      className="image"
                      src={img.image as string | undefined}
                      alt=""
                    />
                  </div>
                );
              })
            : null}
        </div>

        <div></div>
      </div>
    </StyledNoticePostContents>
  );
};

export default NoticeUpdateContents;

const uploadS3 = async (url: string, image: any) => {
  await axios
    .put(`${url}`, image, {
      headers: {
        "Content-Type": "image/jpg",
      },
    })
    .catch((e) => {
      console.log("ERROR:", e);
    })
    .then((response) => {
      console.log(response);
    });
};
